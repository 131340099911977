<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onBeforeMount, ref, watch, nextTick } from 'vue'
import axios from 'axios'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { DamageReportPreferences } from '@/types/damage-report'

import Loader from '@/components/loaders/Loader.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'

let mounted = false

const authStore = useAuthStore()
const { t } = useI18n()

const preferences = ref<DamageReportPreferences>({
    sendReportsToOwners: false,
    requireCustomerCompany: false,
    tourSelectable: false,
})
const saving = ref(false)

async function savePreferences() {
    if (!mounted) return

    saving.value = true
    await axios.put(
        window.route('dmr.company.preferences.update', { company: authStore.companyId }),
        preferences.value,
    )
    authStore.licenses[LicenseType.DamageReportModule] = {
        ...authStore.licenses[LicenseType.DamageReportModule],
        ...preferences.value,
    }
    saving.value = false
}

watch(preferences, savePreferences, { deep: true })

onBeforeMount(async () => {
    const prefs: Partial<DamageReportPreferences> =
        authStore.licenses[LicenseType.DamageReportModule] ?? {}

    preferences.value = { ...preferences.value, ...prefs }
    await nextTick()
    mounted = true
})
</script>

<template>
    <MyPanel panel-class="w-96" padded shadow>
        <Loader
            class="absolute right-6 top-6 opacity-0 transition-opacity duration-300"
            :class="{ 'opacity-100': saving }"
        />

        <div class="flex flex-col space-y-4">
            <div
                v-if="authStore.hasLicense(LicenseType.TransportObjectOwnerCompany)"
                class="flex items-center space-x-2"
            >
                <MyCheckbox
                    v-model="preferences.sendReportsToOwners"
                    :label="t('sendReportsToOwner')"
                />
                <mdi:information v-tooltip="t('sendReportsToOwnerExplanation')" />
            </div>

            <div
                v-if="authStore.hasLicense(LicenseType.FjordlineTrailerInformation)"
                class="flex items-center space-x-2"
            >
                <MyCheckbox
                    v-model="preferences.requireCustomerCompany"
                    :label="t('requireCustomerCompany')"
                />
                <mdi:information v-tooltip="t('requireCustomerCompanyExplanation')" />
            </div>

            <div
                v-if="authStore.hasLicense(LicenseType.DeliveryManagementModule)"
                class="flex items-center space-x-2"
            >
                <MyCheckbox
                    v-model="preferences.tourSelectable"
                    :label="t('selectTourForDamageReport')"
                />
                <mdi:information v-tooltip="t('selectTourExplanation')" />
            </div>

            <div v-else>{{ t('noSettingsAvailable') }}</div>
        </div>
    </MyPanel>
</template>

<script lang="ts" setup>
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { MinimalLocation } from '@/types/company'
import { ManageTransaction, TransactionImageType, TransactionState } from '@/types/transaction'
import { image as imageHelper } from '@/utils/assets'
import { localeDate } from '@/utils/dates'

import MyFormWizardStep from '@/components/my-components/form/MyFormWizardStep.vue'

export interface Props {
    active: boolean
}

const props = defineProps<Props>()
const transactionStore = useTransactionStore()
const companyStore = useCompanyStore()
const transaction = transactionStore.$state.transaction! as ManageTransaction

const { t } = useI18n()
const authStore = useAuthStore()
const haulierLocation = ref<MinimalLocation | null>(null)
const receiverLocation = ref<MinimalLocation | null>(null)
const shipperLocation = ref<MinimalLocation | null>(null)

const senderLocation = computed(
    () => companyStore.locations.find((l) => l.id === transaction.sendingLocationId)!,
)
const receivedImages = computed(() =>
    transaction.images.filter(
        (image) => image.type === TransactionImageType.Received && image.path,
    ),
)
const deliveredImages = computed(() =>
    transaction.images.filter(
        (image) => image.type === TransactionImageType.Delivered && image.path,
    ),
)

async function fetchHaulierLocation() {
    if (transaction.haulierLocationId) {
        haulierLocation.value = await fetchLocation(transaction.haulierLocationId)
    }
}
async function fetchReceivingLocation() {
    if (transaction.receivingLocationId) {
        receiverLocation.value = await fetchLocation(transaction.receivingLocationId)
    }
}
async function fetchLocation(locationId: string): Promise<MinimalLocation> {
    const response = await axios.get<MinimalLocation>(
        window.route('minimal.locations.show', { location: locationId }),
    )

    return response.data
}

watch(
    () => transaction.receivingLocationId,
    () => fetchReceivingLocation(),
)

watch(
    () => transaction.haulierLocationId,
    () => fetchHaulierLocation(),
)

watch(
    () => transaction.shipperLocationId,
    async () => {
        if (transaction.shipperLocationId) {
            shipperLocation.value = await fetchLocation(transaction.shipperLocationId)
        }
    },
)

onMounted(async () => {
    fetchHaulierLocation()
    fetchReceivingLocation()
})
</script>

<template>
    <MyFormWizardStep id="confirm" :active="props.active" :title="t('confirm')">
        <div class="grid grid-cols-2 gap-6">
            <div
                v-if="senderLocation"
                class="rounded-xl border-2 bg-primary-50 p-4 dark:border-dark-700 dark:bg-dark-500"
            >
                <h4
                    class="mb-3 text-xs font-semibold uppercase text-gray-600 dark:text-dark-300"
                    v-text="t('sender')"
                />
                <p class="mb-1 font-semibold text-primary-400" v-text="authStore.company.name" />
                <div class="text-sm">
                    <p class="font-semibold" v-text="senderLocation.name" />
                    <p v-text="senderLocation.address" />
                    <p>
                        {{ senderLocation.zipcode }},
                        {{ senderLocation.city }}
                    </p>
                    <p v-text="senderLocation.country" />
                </div>
            </div>

            <div
                v-if="receiverLocation"
                class="rounded-xl border-2 bg-primary-50 p-4 dark:border-dark-700 dark:bg-dark-500"
            >
                <h4
                    class="mb-3 text-xs font-semibold uppercase text-gray-600 dark:text-dark-300"
                    v-text="t('receiver')"
                />

                <p
                    class="mb-1 font-semibold text-primary-400"
                    v-text="receiverLocation.companyName"
                />

                <div v-if="receiverLocation" class="text-sm">
                    <p class="font-semibold" v-text="receiverLocation.name" />
                    <p v-text="receiverLocation.address" />
                    <p>
                        {{ receiverLocation.zipcode }},
                        {{ receiverLocation.city }}
                    </p>
                    <p v-text="receiverLocation.country" />
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-6 mt-6">
            <div
                v-if="haulierLocation"
                class="rounded-xl border-2 bg-primary-50 p-4 dark:border-dark-700 dark:bg-dark-500"
            >
                <h4
                    class="mb-3 text-xs font-semibold uppercase text-gray-600 dark:text-dark-300"
                    v-text="t('haulier')"
                />

                <p
                    class="mb-1 font-semibold text-primary-400"
                    v-text="haulierLocation.companyName"
                />

                <div v-if="haulierLocation" class="text-sm">
                    <p class="font-semibold" v-text="haulierLocation.name" />
                    <p v-text="haulierLocation.address" />
                    <p>
                        {{ haulierLocation.zipcode }},
                        {{ haulierLocation.city }}
                    </p>
                    <p v-text="haulierLocation.country" />
                </div>
            </div>
        </div>

        <div class="mt-10">
            <div class="mb-3 flex justify-end text-center text-xs uppercase">
                <p class="ml-3 grow pl-[100px] text-left font-semibold" v-text="t('products')" />
                <p class="w-[80px] font-semibold" v-text="t('delivered')" />
                <p class="w-[80px] font-semibold" v-text="t('received')" />
            </div>

            <div>
                <div
                    v-for="product in transaction.products"
                    :key="product.id"
                    class="mb-1 grid grid-cols-[100px_1fr_80px_80px] items-center overflow-hidden bg-primary-50 first:rounded-t-xl last:rounded-b-xl dark:border-dark-600 dark:bg-dark-600"
                >
                    <div class="flex h-16 w-[100px] items-center bg-primary-200 dark:bg-dark-700">
                        <img
                            :alt="product.name"
                            :src="imageHelper(product.image, 'thumbnail')"
                            class="w-full"
                        />
                    </div>
                    <h2 class="px-3 text-sm font-semibold" v-text="product.name" />

                    <div
                        class="text-center font-semibold text-primary-400"
                        v-text="product.delivered"
                    />
                    <div
                        class="text-center font-semibold text-primary-400"
                        v-text="product.received"
                    />
                </div>
            </div>
        </div>

        <div class="mt-10 grid grid-cols-2 gap-6 rounded-xl border-2 p-6">
            <div v-if="shipperLocation">
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('subContractor')"
                />
                <p v-text="shipperLocation.companyName"></p>
            </div>

            <div v-if="transaction.details?.trailerNumber">
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('trailerNumber')"
                />
                <p v-text="transaction.details?.trailerNumber" />
            </div>

            <div v-if="transaction.details?.truckNumber">
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('truckNumber')"
                />
                <p v-text="transaction.details?.truckNumber" />
            </div>

            <div v-if="transaction.details?.shippingCode">
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('shipmentNumber')"
                />
                <span v-text="transaction.details?.shippingCode" />
            </div>

            <div v-if="transaction.details?.palletReceiptNumber">
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('palletReceiptNumber')"
                />
                <p v-text="transaction.details?.palletReceiptNumber" />
            </div>

            <div
                v-if="
                    transaction?.state !== TransactionState.IncomingDraft &&
                    transaction.transpiredAt
                "
            >
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('createdAt')"
                />
                <p v-text="localeDate(transaction.transpiredAt)" />
            </div>

            <div v-else-if="transaction.confirmedAt">
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('confirmedAt')"
                />
                <p v-text="localeDate(transaction.confirmedAt)" />
            </div>

            <div
                v-if="transaction.details?.note"
                class="col-span-2 mt-3 rounded-xl border p-4 dark:border-dark-700 whitespace-pre-line"
            >
                <p
                    class="text-xs font-semibold uppercase dark:text-dark-200"
                    v-text="t('remarks')"
                />
                <p class="col-span-2" v-text="transaction.details?.note" />
            </div>
        </div>

        <div
            v-if="deliveredImages.length !== 0 || receivedImages.length !== 0"
            class="mt-6 grid grid-cols-2 gap-6"
        >
            <div>
                <template v-if="deliveredImages.length !== 0">
                    <h2 class="my-3 font-semibold uppercase" v-text="t('deliveredImages')" />
                    <div class="mt-3 grid grid-cols-3 gap-3">
                        <div
                            v-for="img in deliveredImages"
                            :key="img.path"
                            class="relative flex justify-center overflow-hidden rounded-lg bg-primary-300 text-center"
                        >
                            <img
                                class="w-auto min-w-full object-cover"
                                :src="imageHelper(img.path!, 'thumbnail')"
                            />
                        </div>
                    </div>
                </template>
            </div>
            <div>
                <template v-if="receivedImages.length !== 0">
                    <h2 class="my-3 font-semibold uppercase" v-text="t('receivedImages')" />
                    <div class="mt-3 grid grid-cols-3 gap-3">
                        <div
                            v-for="img in receivedImages"
                            :key="img.path"
                            class="relative flex justify-center overflow-hidden rounded-lg bg-primary-300 text-center"
                        >
                            <img
                                class="w-auto min-w-full object-cover"
                                :src="imageHelper(img.path!, 'thumbnail')"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </MyFormWizardStep>
</template>

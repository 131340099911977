<script lang="ts">
interface Props {
    modelValue: boolean
    unchangeable?: boolean
    route: string
    location?: Location
    forCustomer?: boolean
}
</script>

<script lang="ts" setup>
import type { Except } from 'type-fest'

import { Method } from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useGoogleMaps from '@/hooks/use-google-maps'
import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Location, LocationPoint, LicenseType } from '@/types/company'
import { Address } from '@/types/form'
import { ResourceResponse, uuid } from '@/types/general'
import { LocationType } from '@/types/transaction'
import { addressString } from '@/utils/string'
import { useCompanyStore } from '@/stores/company-store'
import { DropdownStringOption } from '@/types/inputs'

import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyAddressInput from '@/components/my-components/form/MyAddressInput.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'

interface Form
    extends Except<Location, 'id' | 'companyId' | 'location' | 'agentLocationId'>,
        Record<string, unknown> {
    location: LocationPoint | null
    agentLocationId: uuid | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close', locationId?: string): void
}>()

const authStore = useAuthStore()
const companyStore = useCompanyStore()
const { t } = useI18n()

const mapWrapper = ref<HTMLElement>()
const mapLocation = ref<LocationPoint>({
    latitude: props.location?.location.latitude || 0,
    longitude: props.location?.location.longitude || 0,
})

const { map, marker } = useGoogleMaps(mapWrapper, mapLocation, {}, onGoogleMapsLoaded)

const { data, errors, submit, loading, reset } = useForm<Form>({
    name: '',
    location: null,
    address: '',
    city: '',
    zipcode: '',
    country: '',
    email: '',
    phoneNumber: '',
    pNumber: '',
    note: '',
    agentNumber: '',
    agentLocationId: null,
    type: LocationType.Default,
    active: true,
    activeTrade: true,
})
const address = ref('')
const agentLocations = computed<DropdownStringOption[]>(() => {
    let locations = companyStore.locations
    if (authStore.company.locations.length > 0) {
        locations = locations.filter((location) => {
            if (data.agentLocationId === location.id) return true

            return authStore.company.locations.includes(location.id)
        })
    }

    return locations.map((location) => {
        let label = location.name
        if (location.agentNumber) {
            label += ` (${location.agentNumber})`
        }

        return { label, value: location.id }
    })
})

function addressChanged(address: Address) {
    data.address = address.address
    data.city = address.city
    data.zipcode = address.zipcode + ''
    data.country = address.country
    data.location = { latitude: address.latitude, longitude: address.longitude }
    mapLocation.value = data.location
}

async function onSubmit() {
    const method: Method = props.location ? 'PUT' : 'POST'
    const response = await submit<ResourceResponse<Location>>(method, props.route)

    if (response) emit('close', response.data.id)
}

function onGoogleMapsLoaded() {
    map.value?.addListener('click', (e: google.maps.MapMouseEvent) => {
        if (!e.latLng) return
        data.location = { latitude: e.latLng.lat(), longitude: e.latLng.lng() }
        marker.value?.setPosition(e.latLng)
        map.value?.panTo(e.latLng)
    })
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue && props.location) {
            data.name = props.location.name
            data.location = props.location.location
            data.address = props.location.address
            data.city = props.location.city
            data.zipcode = props.location.zipcode
            data.country = props.location.country
            data.email = props.location.email ?? ''
            data.phoneNumber = props.location.phoneNumber ?? ''
            data.pNumber = props.location.pNumber ?? ''
            data.agentNumber = props.location.agentNumber ?? ''
            data.note = props.location.note ?? ''
            data.type = props.location.type
            data.active = props.location.active
                ? props.location.customerLocationActive ?? true
                : props.location.active
            data.activeTrade = props.location.activeTrade
            address.value = addressString(props.location)
            mapLocation.value = data.location
            if (authStore.hasLicense(LicenseType.AgentLocationFiltering)) {
                data.agentLocationId =
                    props.location?.agentLocationId ?? agentLocations.value[0].value ?? null
            }
        } else if (props.modelValue) {
            let agentLocationId = null
            if (authStore.hasLicense(LicenseType.AgentLocationFiltering)) {
                agentLocationId = agentLocations.value[0].value ?? null
            }
            reset({ agentLocationId })
            address.value = ''
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <template #title>
            {{ location ? t('updateLocation') : t('createLocation') }}
        </template>

        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <MyInput
                v-model="data.name"
                :disabled="unchangeable"
                name="name"
                :label="t('name')"
                autofocus
            />

            <MyAddressInput
                v-model="address"
                :disabled="unchangeable"
                name="address"
                :label="t('address')"
                class="rounded-b-none border-b-0"
                @change="addressChanged"
            />

            <div class="flex space-x-2">
                <div
                    ref="mapWrapper"
                    class="w-full rounded-b-xl border border-gray-300 dark:border-transparent border-t-0 overflow-hidden"
                    style="height: 400px"
                />
            </div>

            <div class="flex space-x-2">
                <MyInput
                    v-model="data.email"
                    :disabled="unchangeable"
                    name="email"
                    :label="t('email')"
                />

                <MyPhoneInput
                    v-model="data.phoneNumber"
                    :disabled="unchangeable"
                    name="phoneNumber"
                    :label="t('phoneNumber')"
                />
            </div>

            <MyInput v-model="data.pNumber" name="pNumber" :label="t('pNumber')" />

            <MyInput
                v-if="!forCustomer && authStore.hasLicense(LicenseType.AgentLocationFiltering)"
                v-model="data.agentNumber"
                name="agentNumber"
                :label="t('agentNumber')"
            />

            <MySelect
                v-if="forCustomer && authStore.hasLicense(LicenseType.AgentLocationFiltering)"
                v-model="data.agentLocationId"
                :options="agentLocations"
                :label="t('agentLocation')"
                name="agentLocationId"
                searchable
                :disabled="agentLocations.length === 1"
            />

            <MyInput v-model="data.note" name="note" :label="t('note')" />
            <div v-if="props.location" class="mt-4 flex">
                <MyCheckbox
                    v-model="data.active"
                    v-tooltip="{
                        content: t('customerDisabledLocation'),
                        disabled: props.location.active,
                    }"
                    :disabled="!props.location.active"
                    class="mr-2"
                    name="active"
                    :label="t('active')"
                />

                <mdi:information v-tooltip="t('disableLocationExplanation')" />
            </div>

            <div
                v-if="
                    !props.forCustomer &&
                    authStore.hasLicense(LicenseType.PackagingModule) &&
                    authStore.hasLicense(LicenseType.LocationTrade)
                "
                class="mt-4 flex"
            >
                <MyCheckbox
                    v-model="data.activeTrade"
                    class="mr-2"
                    name="activeTrade"
                    :label="t('activeLocationTrades')"
                />
                <mdi:information v-tooltip="t('activeLocationTradesExplanation')" />
            </div>

            <div class="mt-3 flex justify-end">
                <MyButton :disabled="loading" scheme="primary">
                    <span v-text="t('save')" />
                </MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>

import type { Except } from 'type-fest'

import { RemovableRef } from '@vueuse/core'
import { InjectionKey, Ref } from 'vue'

import { Customer, Location, CustomerCompany, Company } from '@/types/company'
import { FieldPreference, MinimalResource, Model, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'

export interface Product extends Model {
    id: string
    image: string
    name: string
    description?: string
    dimensions?: string
    default: boolean
    category: string
}

export interface CompanyProduct extends Product {
    order: number
    favored: boolean
    price: number
}

export enum DisplayMode {
    List = 'list',
    Card = 'card',
}

export interface ProductBalanceRow {
    productId: string
    owed: number
    owes: number
    balance: number
}

export interface LocationBalanceRow {
    locationId: string
    locationName: string
    locationDeleted: boolean
    owed: number
    owes: number
    balance: number
}

export interface Statement {
    id: uuid
    company: Except<Company, 'locations'>
    receiverCompany: Except<Company, 'locations'>
    user?: MinimalUser
    companyLocations: Location[]
    companyLocationIds: uuid[]
    receiverLocations: Location[]
    receiverLocationIds: uuid[]
    startDate: string
    endDate: string
    transactionCount: number
    products: StatementProduct[]
    dispatches: StatementDispatch[]
}

export interface StatementDispatch {
    id: uuid
    user?: MinimalUser
    emails: string
    createdAt: string
}

export interface MinimalStatement extends Model {
    id: uuid
    user: MinimalUser
    company: Except<Company, 'locations'>
    receiverCompany: Except<Company, 'locations'>
    companyLocations: MinimalResource[]
    receiverLocations: MinimalResource[]
    startDate: string
    endDate: string
    transactionCount: number
    productCount: number
}

export interface StatementProduct {
    id: uuid
    name: string
    image: string
    primoDelivered: number
    primoReceived: number
    periodDelivered: number
    periodReceived: number
}

export enum BalanceLimitType {
    Amount = 0,
    Price = 1,
}

export interface CustomerBalanceLimit {
    productId: uuid | null
    type: BalanceLimitType
    limit: number
    reachedAt: string | null
}

export enum ScheduleFrequency {
    Monthly = 'monthly',
    Weekly = 'weekly',
    every14days = 'every14days',
}

export interface ScheduledStatementOptions {
    frequency: ScheduleFrequency
    emails: string[]
    dayOfMonth: number
    packagingBalancePrice?: number
}

export interface PackagingPreferences {
    signature: FieldPreference
    signatureScanner: FieldPreference
    consignmentNote: FieldPreference
    shipmentNumber: FieldPreference
    note: FieldPreference
    truckNumber: FieldPreference
    trailerNumber: FieldPreference
    defaultProductSort: DefaultProductSort
    productPageStartTab: ProductPageStartTab
    transitLocationTrades: boolean
}

export enum DefaultProductSort {
    Custom = 0,
    ByUsage = 1,
}

export enum ProductPageStartTab {
    Delivered = 0,
    Received = 1,
}

export const displayModeKey: InjectionKey<RemovableRef<DisplayMode>> = Symbol('displayMode')
export const productsOpenKey: InjectionKey<Ref<boolean>> = Symbol('productsOpen')
export const currentCustomerKey: InjectionKey<Ref<Customer<CustomerCompany> | undefined>> =
    Symbol('currentCustomer')
export const currentCustomerLocationKey: InjectionKey<Ref<Location | undefined>> =
    Symbol('currentCustomerLocation')

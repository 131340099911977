<script lang="ts">
interface Props {
    modelValue: boolean
    alarmNotification?: AlarmNotification
}
</script>

<script lang="ts" setup>
import type { Except } from 'type-fest'

import { useI18n } from 'vue-i18n'
import { nextTick, ref, watch } from 'vue'
import { Method } from 'axios'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { AlarmNotification, AlarmType } from '@/types/alarm'
import { LicenseType } from '@/types/company'
import { DropdownOption } from '@/types/inputs'

import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyEmailsInput from '@/components/my-components/form/MyEmailsInput.vue'

type AlarmNotificationForm = Except<AlarmNotification, 'id' | 'companyId' | 'createdAt'>

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'saved'): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()

const alarms: Partial<Record<LicenseType, DropdownOption[]>> = {
    [LicenseType.PackagingModule]: [
        { label: t('customerBalanceLimitAlarm'), value: AlarmType.PackagingCustomerBalanceLimit },
    ],
    [LicenseType.DamageReportModule]: [
        { label: t('fieldAlarm'), value: AlarmType.DamageReportField },
        { label: t('incidentAlarm'), value: AlarmType.DamageReportIncident },
        { label: t('tireThreadAlarm'), value: AlarmType.DamageReportTireThread },
    ],
    [LicenseType.DeliveryManagementModule]: [
        { label: t('deviationAlarm'), value: AlarmType.DeliveryDeviationCreated },
        { label: t('ticketCreatedAlarm'), value: AlarmType.DeliveryTicketCreated },
        { label: t('ticketUpdatedAlarm'), value: AlarmType.DeliveryTicketUpdated },
    ],
}
const packagingChecked = ref(false)
const damageReportChecked = ref(false)
const deliveryManagementChecked = ref(false)
const form = useForm<AlarmNotificationForm>({
    name: '',
    recipients: [],
    alarms: {},
})

async function onSubmit() {
    let method: Method = 'POST'
    let route = window.route('company.alarm-notifications.store', [authStore.companyId])
    if (props.alarmNotification) {
        method = 'PUT'
        route = window.route('company.alarm-notifications.update', [
            authStore.companyId,
            props.alarmNotification.id,
        ])
    }

    const response = await form.submit(method, route)
    if (response) {
        emit('update:modelValue', false)
        emit('saved')
    }
}

watch(packagingChecked, (checked) => {
    if (checked) {
        form.data.alarms[LicenseType.PackagingModule] = []
    } else {
        delete form.data.alarms[LicenseType.PackagingModule]
    }
})

watch(damageReportChecked, (checked) => {
    if (checked) {
        form.data.alarms[LicenseType.DamageReportModule] = []
    } else {
        delete form.data.alarms[LicenseType.DamageReportModule]
    }
})

watch(deliveryManagementChecked, (checked) => {
    if (checked) {
        form.data.alarms[LicenseType.DeliveryManagementModule] = []
    } else {
        delete form.data.alarms[LicenseType.DeliveryManagementModule]
    }
})

watch(
    () => props.modelValue,
    async () => {
        const alarms = { ...(props.alarmNotification?.alarms ?? {}) }
        packagingChecked.value = !!alarms[LicenseType.PackagingModule]
        damageReportChecked.value = !!alarms[LicenseType.DamageReportModule]
        deliveryManagementChecked.value = !!alarms[LicenseType.DeliveryManagementModule]

        // We'll wait for nextTick to ensure watchers are fired
        await nextTick()

        form.reset({
            name: props.alarmNotification?.name ?? '',
            recipients: props.alarmNotification?.recipients ?? [],
            alarms: { ...(props.alarmNotification?.alarms ?? {}) },
        })
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ props.alarmNotification?.id ? t('editConfiguration') : t('addConfiguration') }}
        </template>

        <LoaderWrapper :visible="form.loading.value" />

        <MyForm class="space-y-4" :errors="form.errors.value" @submit.prevent="onSubmit">
            <MyInput v-model="form.data.name" name="name" :label="t('name')" />

            <MyEmailsInput v-model="form.data.recipients" required />

            <h4
                class="text-md relative font-semibold uppercase text-primary-400"
                v-text="t('alarms')"
            />

            <div v-if="authStore.hasLicense(LicenseType.PackagingModule)">
                <MyCheckbox
                    v-model="packagingChecked"
                    :label="`${t('packaging')} ${t('alarms').toLowerCase()}`"
                />

                <MySelect
                    v-if="packagingChecked"
                    v-model="form.data.alarms[LicenseType.PackagingModule]"
                    :options="alarms[LicenseType.PackagingModule]!"
                    multiple
                    clear-button
                    :placeholder="`${t('all')} ${t('packaging')} ${t('alarms').toLowerCase()}`"
                />
            </div>

            <div v-if="authStore.hasLicense(LicenseType.DamageReportModule)">
                <MyCheckbox
                    v-model="damageReportChecked"
                    :label="`${t('damageReport')} ${t('alarms').toLowerCase()}`"
                />

                <MySelect
                    v-if="damageReportChecked"
                    v-model="form.data.alarms[LicenseType.DamageReportModule.toString()]"
                    :options="alarms[LicenseType.DamageReportModule]!"
                    multiple
                    clear-button
                    :placeholder="`${t('all')} ${t('damageReport')} ${t('alarms').toLowerCase()}`"
                />
            </div>

            <div
                v-if="authStore.hasLicense(LicenseType.DeliveryManagementModule)"
                class="space-y-3"
            >
                <MyCheckbox
                    v-model="deliveryManagementChecked"
                    :label="`${t('deliveryManagement')} ${t('alarms').toLowerCase()}`"
                />

                <MySelect
                    v-if="deliveryManagementChecked"
                    v-model="form.data.alarms[LicenseType.DeliveryManagementModule.toString()]"
                    :options="alarms[LicenseType.DeliveryManagementModule]!"
                    multiple
                    clear-button
                    :placeholder="`${t('all')} ${t('deliveryManagement')} ${t(
                        'alarms',
                    ).toLowerCase()}`"
                />
            </div>

            <div class="flex flex-row-reverse justify-start space-x-3">
                <MyButton :disabled="form.loading.value" scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>

<script lang="ts" setup>
import { computed, inject, useSlots, VNode } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFilterQuery } from '@/hooks/use-filter-query'
import { refetchKey } from '@/types/table'

import MyButton from '@/components/my-components/MyButton.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'

interface FilterButton {
    name: string
    value: string
    default: boolean
    defaultValue: string | null
}

interface FilterChild {
    name: string
    default: string | null
}

const { t } = useI18n()
const slots = useSlots()
const { filters } = useFilterQuery()
const refetch = inject(refetchKey)

const filterButtons = computed<FilterButton[]>(() => {
    if (!slots.default || !slots.default()[0].children) return []

    const children = slots.default()[0].children as VNode[]
    return children
        .filter((child) => !!child.props)
        .map((child) => {
            const defaultActive = ['', true].includes(child.props!.default) || false

            return {
                name: child.props!['filter-name'],
                value: child.props!.value,
                defaultValue: defaultActive ? child.props!.value : child.props!.default,
                default: defaultActive,
            }
        })
})

const uniqueFilters = computed(() => {
    const filters: Record<string, FilterChild> = {}

    for (const button of filterButtons.value) {
        if (button.name in filters && !button.default) continue

        filters[button.name] = { name: button.name, default: button.defaultValue }
    }

    return Object.values(filters)
})

const hasFiltersSelected = computed(() => {
    for (const filter of uniqueFilters.value) {
        if (!filter.default && !filters.value[filter.name]) continue
        if (filters.value[filter.name] !== filter.default) {
            return true
        }
    }

    return false
})

async function resetFilters() {
    const newFilters = { ...filters.value }

    for (const filter of uniqueFilters.value) {
        if (filter.default) newFilters[filter.name] = filter.default
        else delete newFilters![filter.name]
    }

    if (refetch) await refetch({ filter: newFilters, page: 1 })
}
</script>

<template>
    <div
        class="relative z-10 flex h-12 items-center space-x-1 px-2.5 text-gray-500 dark:text-primary-50"
    >
        <slot />
        <template v-if="hasFiltersSelected">
            <MyFilterDivider />

            <MyButton plain scheme="light" size="small" @click="resetFilters">
                <mdi:close />
                {{ t('clearFilters') }}
            </MyButton>
        </template>
    </div>
</template>

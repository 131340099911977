<script lang="ts">
interface Props {
    modelValue: boolean
    tourId?: uuid
    isHubHaulier?: boolean
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import axios, { Method } from 'axios'
import { watch, computed, ref, nextTick, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import Datepicker from '@vuepic/vue-datepicker'
import { promiseTimeout } from '@vueuse/core'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { FormTourEvent, Tour } from '@/types/delivery-management'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { useCompanyStore } from '@/stores/company-store'
import { TourType } from '@/types/driver-report'
import { tourTypes } from '@/utils/type-translations'
import { emitter } from '@/utils/mitt'

import ShipmentSelection from '@/components/delivery-management/manageTour/ShipmentSelection.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

enum TourCompanyType {
    Owner = 0,
    Shipper = 1,
}

interface Form {
    name: string
    hubId: uuid | null
    companyId: uuid | null
    shipperCompanyId: uuid | null
    haulierCompanyId: uuid | null
    driverUserId: uuid | null
    transportObjectId: uuid | null
    plannedAt: string
    type: TourType
    events: FormTourEvent[]
    transportObjectIds: uuid[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const companyStore = useCompanyStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    name: '',
    hubId: null,
    companyId: null,
    shipperCompanyId: null,
    haulierCompanyId: null,
    driverUserId: null,
    transportObjectId: null,
    plannedAt: '',
    type: TourType.Domestic,
    events: [],
    transportObjectIds: [],
})

const tour = ref<Tour>()
const companyType = ref<TourCompanyType>(TourCompanyType.Owner)

const hubs = useLazyLoadedList<DropdownStringOption>(fetchHubs)
const drivers = useLazyLoadedList<DropdownStringOption>(fetchDrivers)
const transportObjects = useLazyLoadedList<DropdownStringOption>(fetchTransportObjects)
const hauliers = useLazyLoadedList<DropdownStringOption>(fetchHauliers)
const shippers = useLazyLoadedList<DropdownStringOption>(fetchShippers)

const clients = computed(() => {
    return companyStore.clients.map((client) => ({
        value: client.id,
        label: client.name,
    }))
})

const title = computed(() => {
    if (props.tourId) {
        return t('updateEntity', { entity: t('tour') })
    } else {
        return t('createEntity', { entity: t('tour') })
    }
})

const isTourOwner = computed(() => {
    return (
        tour.value?.company.id === authStore.companyId ||
        tour.value?.shipperCompany?.id === authStore.companyId
    )
})

const noClient = computed(() => {
    return companyType.value === TourCompanyType.Shipper && data.companyId === null
})

async function fetchHubs(): Promise<DropdownStringOption[]> {
    let companyId: string | null = authStore.companyId
    if (companyType.value === TourCompanyType.Shipper) {
        companyId = data.companyId
    }
    if (!companyId) return []
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.hubs', { company: companyId }),
    )

    return response.data.map((hub) => ({
        value: hub.id,
        label: hub.name,
    }))
}

async function fetchDrivers(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId }),
    )

    return response.data.map((user) => ({ value: user.id, label: user.name }))
}
async function fetchTransportObjects(): Promise<DropdownStringOption[]> {
    const response = await axios.get<{ id: uuid; registrationNumber: string }[]>(
        window.route('minimal.companies.transport-objects', { company: authStore.companyId }),
    )

    return response.data.map((transportObject) => ({
        value: transportObject.id,
        label: transportObject.registrationNumber,
    }))
}

async function fetchShippers(): Promise<DropdownStringOption[]> {
    if (companyType.value === TourCompanyType.Shipper) {
        return [{ value: authStore.companyId, label: authStore.company.name }]
    }

    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            haulier: true,
        }),
    )

    return response.data.map((shipper) => ({
        value: shipper.id,
        label: shipper.name,
    }))
}

async function fetchHauliers(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            haulier: true,
        }),
    )

    return response.data.map((haulier) => ({
        value: haulier.id,
        label: haulier.name,
    }))
}

async function fetchTour() {
    const response = await axios.get<ResourceResponse<Tour>>(
        window.route('dm.company.tours.show', {
            company: authStore.companyId,
            tour: props.tourId ?? '',
        }),
    )
    tour.value = response.data.data

    if (tour.value.transportObjects.length !== 0) {
        transportObjects.fetch()
    }

    data.name = tour.value.name
    data.hubId = tour.value.hub?.id ?? null
    data.companyId = tour.value.company.id ?? null
    data.shipperCompanyId = tour.value.shipperCompany?.id ?? null
    data.haulierCompanyId = tour.value.haulierCompany?.id ?? null
    data.driverUserId = tour.value.driver?.id ?? null
    data.plannedAt = tour.value.plannedAt
    data.type = tour.value.type
    data.events = tour.value.events.map((event) => ({
        id: event.id,
        shipment: event.shipment,
        type: event.type,
        order: event.order,
        startLocation: event.startLocation,
    }))
    data.transportObjectIds = tour.value.transportObjects.map(
        (transportObject) => transportObject.id,
    )
    if (tour.value.shipperCompany?.id === authStore.companyId) {
        companyType.value = TourCompanyType.Shipper
    } else if (tour.value.companyId === authStore.companyId) {
        companyType.value = TourCompanyType.Owner
    }

    await nextTick()

    if (data.hubId) {
        hubs.items.value = [{ value: data.hubId, label: tour.value.hub?.name ?? '' }]
    }
    if (data.haulierCompanyId) {
        hauliers.items.value = [
            { value: data.haulierCompanyId, label: tour.value?.haulierCompany?.name ?? '' },
        ]
    }
    if (data.driverUserId) {
        drivers.items.value = [{ value: data.driverUserId, label: tour.value.driver?.name ?? '' }]
    }
    if (data.shipperCompanyId) {
        shippers.items.value = [
            { value: data.shipperCompanyId, label: tour.value.shipperCompany?.name ?? '' },
        ]
    }
}

async function onSubmit() {
    const method: Method = props.tourId ? 'PUT' : 'POST'
    const createRoute = window.route('dm.company.tours.store', {
        company: authStore.companyId,
    })
    const updateRoute = window.route('dm.company.tours.update', {
        company: authStore.companyId,
        tour: props.tourId ?? '',
    })
    const response = await submit<ResourceResponse<Tour>>(
        method,
        props.tourId ? updateRoute : createRoute,
    )

    if (response !== undefined) {
        emitter.emit('fetchTours')
        emit('close')
        emit('saved')
    }
}

async function tourTypeChanged() {
    await promiseTimeout(50)
    data.hubId = null

    if (companyType.value === TourCompanyType.Shipper) {
        // Forcing the fetch since we are only returning the current company
        shippers.fetch(true)
        data.shipperCompanyId = authStore.companyId
        data.companyId = null
    } else {
        shippers.items.value = []
        data.shipperCompanyId = null
        data.companyId = authStore.companyId
    }
}

function onOpen() {
    if (!props.modelValue) return
    companyStore.fetchCompany()

    reset()
    companyType.value = TourCompanyType.Owner
    tourTypeChanged()

    if (props.tourId) {
        fetchTour()
    }
}

onBeforeMount(() => {
    if (props.modelValue) onOpen()
})

watch(
    () => data.companyId,
    () => hubs.reset(),
)

watch(
    () => props.modelValue,
    () => onOpen(),
)
</script>

<template>
    <MyModal
        :value="props.modelValue"
        :max-width="isTourOwner || !props.tourId ? 1600 : 1200"
        @close="emit('close')"
    >
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="flex space-y-2 space-x-4" :class="{ 'mt-2': !isTourOwner }">
                <div
                    v-if="isTourOwner || !props.tourId"
                    class="flex w-1/3 flex-col items-end space-x-3 space-y-2 pr-3"
                >
                    <MyRadioButtonGroup
                        v-if="clients"
                        v-model="companyType"
                        class="w-full"
                        name="type"
                        :label="t('createTourAs')"
                        @input="tourTypeChanged"
                    >
                        <MyRadioButton :label="t('owner')" :value="TourCompanyType.Owner" />
                        <MyRadioButton
                            v-tooltip="t('createTourAsShipper')"
                            :label="t('shipper')"
                            :value="TourCompanyType.Shipper"
                        />
                    </MyRadioButtonGroup>

                    <MySelect
                        v-if="clients && companyType === TourCompanyType.Shipper"
                        v-model="data.companyId"
                        name="companyId"
                        group-class="w-full"
                        :label="t('client')"
                        searchable
                        clear-button
                        :options="clients"
                    />
                    <MyInput
                        v-model="data.name"
                        name="name"
                        type="text"
                        group-class="w-full"
                        placeholder="name"
                        :label="t('name')"
                    />
                    <MySelect
                        v-model="data.hubId"
                        name="hubId"
                        group-class="w-full"
                        :label="t('hub')"
                        searchable
                        clear-button
                        :disabled="
                            companyType === TourCompanyType.Shipper && data.companyId === null
                        "
                        :options="hubs.items.value"
                        :loading="hubs.fetching.value"
                        @focus="hubs.fetch()"
                    />

                    <div class="flex w-full space-x-3">
                        <div class="w-1/2">
                            <MyInputLabel v-text="t('date')" />
                            <Datepicker
                                v-model="data.plannedAt"
                                class="input-field w-full rounded-xl border border-gray-300"
                                name="plannedAt"
                                close-on-scroll
                                :enable-time-picker="false"
                                auto-apply
                                :clearable="false"
                                auto-position
                                position="left"
                                format="yyyy-MM-dd"
                                :transitions="false"
                                :placeholder="t('selectDate')"
                            />
                            <MyErrorMessage input-name="plannedAt" :label="t('date')" />
                        </div>
                    </div>

                    <div class="flex w-full space-x-3">
                        <MySelect
                            v-model="data.driverUserId"
                            name="driverUserId"
                            group-class="w-1/2"
                            searchable
                            clear-button
                            :label="t('driver')"
                            :options="drivers.items.value"
                            :loading="drivers.fetching.value"
                            @focus="drivers.fetch()"
                        />
                        <MySelect
                            v-model="data.transportObjectIds"
                            name="transportObjectId"
                            group-class="w-1/2"
                            :label="t('transportObject')"
                            searchable
                            clearable
                            multiple
                            :options="transportObjects.items.value"
                            :loading="transportObjects.fetching.value"
                            @focus="transportObjects.fetch()"
                        />
                    </div>

                    <div class="flex w-full space-x-3">
                        <MySelect
                            v-model="data.shipperCompanyId"
                            :disabled="companyType === TourCompanyType.Shipper"
                            name="shipperCompanyId"
                            group-class="w-1/2"
                            :class="{
                                'opacity-30': companyType === TourCompanyType.Shipper,
                            }"
                            :label="t('shipper')"
                            searchable
                            :clear-button="companyType !== TourCompanyType.Shipper"
                            :options="shippers.items.value"
                            :loading="shippers.fetching.value"
                            @focus="shippers.fetch()"
                        />
                        <MySelect
                            v-model="data.haulierCompanyId"
                            name="haulierCompanyId"
                            group-class="w-1/2"
                            :label="t('haulier')"
                            searchable
                            clear-button
                            :options="hauliers.items.value"
                            :loading="hauliers.fetching.value"
                            @focus="hauliers.fetch()"
                        />
                    </div>

                    <div class="flex w-full pt-2">
                        <MyRadioButtonGroup
                            v-model="data.type"
                            class="w-full"
                            name="type"
                            :label="t('type')"
                        >
                            <MyRadioButton
                                v-for="(translation, value) in tourTypes"
                                :key="value"
                                :label="t(translation)"
                                :value="parseInt(value as unknown as string, 10)"
                            />
                        </MyRadioButtonGroup>
                    </div>
                </div>

                <ShipmentSelection
                    :tour-id="props.tourId"
                    :tour-shipper-company-id="data.shipperCompanyId"
                    :tour-company-id="data.companyId ?? authStore.companyId"
                    :is-hub-haulier="props.isHubHaulier && !isTourOwner"
                    :events="data.events"
                    @events-changed="data.events = $event"
                />
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <div
                    v-tooltip="{
                        disabled: !noClient,
                        content: t('selectClient'),
                    }"
                >
                    <MyButton
                        :disabled="noClient || loading"
                        scheme="primary"
                        v-text="props.tourId ? t('update') : t('create')"
                    />
                </div>
            </div>
        </MyForm>
    </MyModal>
</template>

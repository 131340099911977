<script lang="ts" setup>
import axios from 'axios'
import { inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { DeliveryDescription } from '@/types/delivery-management'
import { image as imageHelper } from '@/utils/assets'
import { ResourceResponse, uuid } from '@/types/general'
import { imageViewerOpenKey } from '@/types/global-injection-keys'

import MyModal from '@/components/my-components/MyModal.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

export interface Props {
    modelValue: boolean
    deliveryDescriptionId: uuid
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()

const imageViewerOpen = inject(imageViewerOpenKey)!

const loading = ref(false)
const deliveryDescription = ref<DeliveryDescription>()

async function fetchDeliveryDescription() {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<DeliveryDescription>>(
            window.route('company.delivery-descriptions.show', {
                company: authStore.companyId,
                delivery_description: props.deliveryDescriptionId,
            }),
        )
        deliveryDescription.value = response.data.data
    } finally {
        loading.value = false
    }
}

function onClose() {
    if (imageViewerOpen.value) return

    emit('update:modelValue', false)
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return
        fetchDeliveryDescription()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="1000" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <template #title>
            <div class="flex justify-between items-start">
                <span
                    class="text-lg font-semibold uppercase text-primary-400"
                    v-text="t('deliveryDescription')"
                />
                <mdi:close
                    class="self-start text-primary-400 cursor-pointer dark:text-primary-200"
                    @click="emit('update:modelValue', false)"
                />
            </div>
        </template>
        <div v-if="deliveryDescription">
            <div class="flex justify-between space-x-2">
                <div class="flex w-full space-x-4 align-top">
                    <div class="flex flex-col w-1/4 space-y-4">
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('entrance')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.entrance ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('itemLocation')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.itemLocation ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('palletLocation')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.palletLocation ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('returnItemLocation')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.returnItemLocation ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('note')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.note ?? '-'"
                            />
                        </div>
                    </div>

                    <!-- Second Column -->
                    <div class="flex flex-col w-1/4 space-y-4">
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('alarm')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.alarm ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('timeDemands')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.timeDemands ?? '-'"
                            />
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('keyBox')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.keyBox ?? '-'"
                            />
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('tourNumber')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100 mb-4 break-all"
                                v-text="deliveryDescription.fields.tourNumber ?? '-'"
                            />
                        </div>
                    </div>

                    <div class="flex w-1/2 flex-col">
                        <img
                            v-if="deliveryDescription.drawingPath"
                            :src="imageHelper(deliveryDescription?.drawingPath!, 'product')"
                            :alt="t('deliveryDescription')"
                            class="w-[400px] h-[400px] object-contain"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex-col space-y-1.5">
                <span
                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                    v-text="t('images')"
                />
                <div class="flex flex-nowrap w-full justify-around">
                    <MyImageViewer class="grid grid-cols-4 gap-3">
                        <div
                            v-for="file in deliveryDescription.images"
                            :key="file.id"
                            class="relative aspect-square flex cursor-pointer justify-items-center overflow-hidden rounded-xl bg-primary-300"
                        >
                            <div
                                v-if="file.note"
                                v-tooltip="file.note"
                                class="absolute top-0 right-0 flex h-6 w-6 items-center justify-center rounded-bl-lg bg-primary-300 text-xs text-primary-50 shadow-lg"
                            >
                                <mdi:comment />
                            </div>
                            <img
                                class="w-auto object-fit"
                                :src="imageHelper(file.path!, 'thumbnail')"
                                :alt="file.note || ' '"
                                :data-src="imageHelper(file.path!, 'public')"
                            />
                        </div>
                    </MyImageViewer>
                </div>
            </div>
        </div>
    </MyModal>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { ref } from 'vue'

import { useAuthStore } from '@/stores/auth-store'
import { uuid, PaginatedResponse } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { DeliveryDescription } from '@/types/delivery-management'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import ManageDeliveryDescriptionModal from '@/components/customers/ManageDeliveryDescriptionModal.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const confirm = useConfirm()

const { data, paginationData, loading, error, refetch } = usePaginatedTable<DeliveryDescription>(
    async (params) => {
        const response = await axios.get<PaginatedResponse<DeliveryDescription>>(
            window.route('company.delivery-descriptions.index', {
                company: authStore.companyId,
            }),
            { params: params },
        )

        return response.data
    },
)

const modalOpen = ref(false)
const selectedDeliveryDescriptionId = ref<uuid>()

function closeModal() {
    modalOpen.value = false
    selectedDeliveryDescriptionId.value = undefined
}

function closeModalAndRefetch() {
    closeModal()
    refetch()
}

function openModal(deliveryDescription: DeliveryDescription | undefined = undefined) {
    selectedDeliveryDescriptionId.value = deliveryDescription?.id
    modalOpen.value = true
}

async function deleteRow(deliveryDescription: DeliveryDescription) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('deliveryDescription') }),
            t('deleteEntityDescription', { entity: t('deliveryDescription') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }
    await axios.delete(
        window.route('company.delivery-descriptions.destroy', {
            company: authStore.companyId,
            delivery_description: deliveryDescription.id,
        }),
    )
    await refetch()
}
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('deliveryDescriptions')" />

        <template #actions>
            <MyButton plain scheme="primary" size="small" @click="openModal()">
                <mdi:plus-thick class="mr-1" />
                {{ t('addDeliveryDescription') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <MyTable
            table-id="delivery-descriptions"
            :rows="data"
            :pagination-data="paginationData"
            :loading="loading"
            :error="error"
            :get-data="refetch"
            :entity-name="t('deliveryDescriptions')"
        >
            <template #actionRow="{ row }">
                <ActionRowItem @click="openModal(row)">
                    <mdi:pencil />
                </ActionRowItem>
            </template>

            <template #actionMenu="{ row }">
                <ActionMenuItem @click="deleteRow(row)">
                    <span class="mr-2"><mdi:trash-can /></span>
                    {{ t('remove') }}
                </ActionMenuItem>
            </template>

            <MyTableColumn :name="t('dropNumber')" property="dropNumber" />
            <template #dropNumber="{ row }">
                {{ row.dropNumber || '-' }}
            </template>

            <MyTableColumn :name="t('address')" property="address" />
            <template #address="{ row }">
                {{ row.address || '-' }}
            </template>
        </MyTable>
    </MyPanel>

    <ManageDeliveryDescriptionModal
        v-model="modalOpen"
        :delivery-description-id="selectedDeliveryDescriptionId"
        @close="closeModal"
        @save="closeModalAndRefetch"
    />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute } from 'vue-router'

import {
    ActivityLog,
    ActivityLogIcon,
    logConfig,
    ActivityLogType,
    translationsForFields,
} from '@/types/activity-log'
import { datetime } from '@/utils/dates'

import MyButton from '@/components/my-components/MyButton.vue'

const { t } = useI18n()
const route = useRoute()

const props = defineProps<{
    log: ActivityLog
    hideAdvancedDetails?: boolean
}>()

const showAdvancedDetails = reactive<Record<string, boolean>>({})

const details = computed(() => logConfig[props.log.type])

const fieldsUpdated = computed(() => {
    if (!props.log.data) {
        return []
    }

    return Object.keys(props.log.data.fields)
        .map((field) => {
            if (field in translationsForFields) return t(translationsForFields[field])!

            const camelCaseField = field.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
            return t(camelCaseField)
        })
        .join(', ')
})

function toggleAdvancedDetails(logId: string) {
    showAdvancedDetails[logId] = !showAdvancedDetails[logId]
}

function showEntity(id: string, type: ActivityLogType): RouteLocationRaw {
    const routeName = logConfig[type]?.showRoute
    const entityId = logConfig[type]?.entity + 'Id'

    // For customer, we only need id
    if (logConfig[type]?.entity.includes('customer')) {
        return {
            name: routeName,
            params: { id: id },
        }
    }

    return {
        name: routeName,
        params: { [entityId]: id },
    }
}
</script>

<template>
    <div v-if="details" class="flex justify-between items-center mt-3 mx-4">
        <div class="flex">
            <div class="mr-2 self-center">
                <mdi:plus-thick v-if="details.icon === ActivityLogIcon.Created" />
                <mdi:clipboard-edit-outline v-if="details.icon === ActivityLogIcon.Updated" />
                <mdi:shuffle-variant v-if="details.icon === ActivityLogIcon.Reorder" />
                <mdi:trash-can v-if="details.icon === ActivityLogIcon.Removed" />
                <mdi:exclamation-thick v-if="details.icon === ActivityLogIcon.Warning" />
            </div>
            <div class="flex space-x-2 items-center">
                <h3
                    class="text-lg font-bold uppercase"
                    :class="`text-${details.color}`"
                    v-text="t(details.name, { entity: t(details.entity) })"
                />

                <div
                    v-if="
                        logConfig[log.type]?.showRoute &&
                        !route.name?.toString().includes(logConfig[log.type]!.entity)
                    "
                    class="self-center"
                >
                    <RouterLink :to="showEntity(log.loggableId, log.type)" class="self-center">
                        <MyButton
                            v-tooltip="t('showEntity', { entity: t(details.entity) })"
                            plain
                            scheme="primary"
                            size="micro"
                            icon
                        >
                            <div class="flex items-center">
                                <mdi:eye class="m-2" />
                            </div>
                        </MyButton>
                    </RouterLink>
                </div>
            </div>
        </div>
        <div class="flex items-center mb-1">
            <span class="text-green-400 text-lg uppercase font-bold" v-text="t('fieldsUpdated')" />
        </div>
    </div>
    <div
        v-if="details"
        class="w-full flex border-b-4 border-dashed border-primary-100 pb-3 px-4 last:border-b-0 dark:border-dark-600"
    >
        <div class="flex flex-col w-1/3 mt-1.5">
            <div>
                <div class="flex flex-col space-y-2">
                    <div class="flex text-xs items-center">
                        <mdi:clock-outline class="mr-1 self-center h-4 w-4" />
                        <span class="font-semibold capitalize" v-text="datetime(log.createdAt)" />
                    </div>

                    <div class="text-xs flex items-center">
                        <mdi:office-building class="mr-1 self-center h-4 w-4" />

                        <span class="capitalize font-semibold" v-text="log.company.name" />
                    </div>

                    <div class="text-xs flex items-center">
                        <mdi:account class="mr-1 self-center h-4 w-4" />
                        <span class="capitalize font-semibold" v-text="log.user.name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-2/3">
            <!-- Fields Updated: -->
            <div v-if="!showAdvancedDetails[log.id]" class="flex flex-col justify-center items-end">
                <code class="block w-fit rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600">
                    <div class="text-xs">
                        <p class="font-semibold capitalize" v-text="fieldsUpdated" />
                    </div>
                </code>
            </div>
            <section v-if="log.data && showAdvancedDetails[log.id]" class="font-mono text-sm">
                <div class="flex space-x-4">
                    <div class="flex flex-col w-1/2">
                        <span class="text-red-400 font-semibold" v-text="t('previousValues')" />
                        <code
                            class="block w-full break-words rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600"
                        >
                            <div v-if="log.previousData !== null">
                                <div
                                    v-for="(field, key) in log.previousData?.fields"
                                    :key="key"
                                    class="mb-1"
                                >
                                    <div>
                                        <span
                                            class="font-semibold capitalize"
                                            v-text="key.split('_').join(' ')"
                                        />
                                        <span v-text="':'" />
                                        <span class="ml-1" v-text="field" />
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <span v-text="t('noPreviousValues')" />
                            </div>
                        </code>
                    </div>

                    <mdi:arrow-right-bold class="self-center mt-4" />

                    <div class="flex flex-col w-1/2">
                        <span class="text-green-400 font-semibold" v-text="t('newValues')" />
                        <code
                            class="block w-full break-words rounded-lg bg-primary-100 p-4 font-mono dark:bg-dark-600"
                        >
                            <div>
                                <div
                                    v-for="(field, key) in log.data.fields"
                                    :key="key"
                                    class="mb-1"
                                >
                                    <div v-if="field !== null">
                                        <span
                                            class="font-semibold capitalize"
                                            v-text="key.split('_').join(' ')"
                                        />
                                        <span v-text="':'" />
                                        <span class="ml-1" v-text="field" />
                                    </div>
                                </div>
                            </div>
                        </code>
                    </div>
                </div>
            </section>
            <div v-if="!hideAdvancedDetails" class="w-full my-3 flex justify-end">
                <MyButton
                    scheme="action"
                    type="button"
                    class="dark:text-primary-50 dark:bg-dark-400 dark:hover:bg-dark-300 bg-primary-200"
                    size="small"
                    @click="toggleAdvancedDetails(log.id)"
                >
                    <mdi:cog-transfer class="h-5 w-5" />
                    <span
                        class="font-semibold"
                        v-text="
                            showAdvancedDetails[log.id]
                                ? t('hideAdvancedDetails')
                                : t('showAdvancedDetails')
                        "
                    />
                </MyButton>
            </div>
        </div>
    </div>
</template>

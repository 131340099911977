<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useGoogleMaps from '@/hooks/use-google-maps'
import { useCompanyStore } from '@/stores/company-store'
import { image as imageHelper } from '@/utils/assets'
import { useAuthStore } from '@/stores/auth-store'
import { PermissionType } from '@/types/general'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import EditCompanyModal from '@/components/settings/EditCompanyModal.vue'

const { t } = useI18n()
const companyStore = useCompanyStore()
const authStore = useAuthStore()

const mapWrapper = ref<HTMLElement>()
const { loading, company } = storeToRefs(companyStore)
const location = computed(() =>
    company.value ? company.value.location : { latitude: 0, longitude: 0 },
)

useGoogleMaps(mapWrapper, location)

onMounted(() => companyStore.fetchCompany())
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('metadata')" />

        <template #actions>
            <EditCompanyModal v-if="authStore.hasPermission(PermissionType.ManageCompany)" />
        </template>
    </CrumbsAndActions>

    <section class="grid gap-6 lg:grid-cols-[4fr_3fr]">
        <MyPanel class="relative flex justify-between p-6">
            <LoaderWrapper :visible="loading" class="rounded-xl" />

            <div v-if="company" class="flex min-h-[275px] grow flex-col gap-6">
                <div>
                    <h4
                        class="text-xs font-semibold uppercase text-primary-300"
                        v-text="t('companyMetadata')"
                    />
                    <h1 class="text-3xl font-bold uppercase text-primary-400">
                        {{ company.name }}
                    </h1>
                    <p><span class="font-bold" v-text="t('vat')" /> {{ company.vat }}</p>
                    <div class="mt-6">
                        <p
                            class="text-xs font-semibold uppercase text-primary-300"
                            v-text="t('address')"
                        />
                        <p v-text="company.address" />
                        <p>{{ company.zipcode }}, {{ company.city }}</p>
                        <p v-text="company.country" />
                    </div>
                </div>
                <div>
                    <h4
                        class="text-xs font-semibold uppercase text-primary-300"
                        v-text="t('contactPerson')"
                    />
                    <p v-text="company.contactPerson" />
                    <p v-if="company.email" class="flex items-center">
                        <mdi:email class="mr-1" /> {{ company.email }}
                    </p>
                    <p v-if="company.phoneNumber" class="flex items-center">
                        <mdi:cellphone class="mr-1" /> {{ company.phoneNumber }}
                    </p>
                </div>
            </div>

            <div class="flex w-80 items-start">
                <img v-if="company?.logo" :src="imageHelper(company.logo)" class="w-full" />
            </div>
        </MyPanel>
        <section ref="mapWrapper" class="h-full overflow-hidden rounded-xl shadow-xl" />
    </section>
</template>

<script lang="ts">
export interface Props {
    id?: uuid
    open?: boolean
}
</script>

<script setup lang="ts">
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'

import { ResourceResponse, uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { transportObjectTypes } from '@/utils/type-translations'
import { datetime } from '@/utils/dates'
import { Report, TemplateFieldType } from '@/types/damage-report'
import { image as imageHelper, localAsset } from '@/utils/assets'
import useGoogleMaps from '@/hooks/use-google-maps'
import { reportTypes } from '@/utils/damage-report'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { printElement } from '@/utils/print-element'
import { LicenseType } from '@/types/company'
import { imageViewerOpenKey } from '@/types/global-injection-keys'

import MyDispatchLog from '@/components/my-components/MyDispatchLog.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import UpdateDepartureModal from '@/components/damage-reports/UpdateDepartureModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import IncidentView from '@/components/damage-reports/IncidentView.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'

const tankLevels: Record<string, string> = { '.25': '1/4', '.50': '2/4', '.75': '3/4' }

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const imageViewerOpen = inject(imageViewerOpenKey)!

const loading = ref(false)
const report = ref<Report>()
const mapWrapper = ref<HTMLElement>()
const shareReportOpen = ref(false)
const departureModalOpen = ref(false)
const printMode = ref(false)
const modalContent = ref<HTMLDivElement>()

const props = withDefaults(defineProps<Props>(), { open: true })
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()

const location = computed(() =>
    report.value ? report.value.location : { latitude: 0, longitude: 0 },
)

const hasFields = computed(() => {
    if (!report.value || !report.value.fields) return false
    return report.value.fields.length > 0
})

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('dmr.company.reports.share', {
        company: authStore.companyId,
        report: report.value?.id ?? '',
    })
})

function getAutomaticDropOffTranslation(): string {
    const type = transportObjectTypes[report.value?.transportObject?.type ?? 0]

    return t('automaticDropOffExplanation', { type: t(type).toLowerCase() })
}

async function fetchReport() {
    const reportId = (route.params.reportId as uuid | undefined) || props.id
    if (!reportId) return
    loading.value = true

    try {
        let url = window.route('dmr.reports.show', { report: reportId })
        if (authStore.hasCompany) {
            url = window.route('dmr.company.reports.show', {
                company: authStore.companyId,
                report: reportId,
            })
        }
        const response = await axios.get<ResourceResponse<Report>>(url)
        report.value = response.data.data
    } finally {
        loading.value = false
    }
}

useGoogleMaps(mapWrapper, location)

function formatDate(date: string | undefined) {
    if (!date) return t('notSpecified')
    return datetime(date)
}

async function onClose() {
    if (imageViewerOpen.value) return

    if (authStore.user == null) {
        router.push({ name: 'login' })
    } else if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dmr.reports', query: route.query })
    }
}

async function print() {
    printMode.value = true
    await printElement(modalContent.value!, true)
    printMode.value = false
}

useMittListener('fetchDamageReports', fetchReport)
useMittListener('incidentFixed', fetchReport)
// We listen for this in case an alarm is fixed
useMittListener('fetchAlarms', fetchReport)
onBeforeMount(fetchReport)
watch(() => props.id, fetchReport)
watch(() => route.params.reportId, fetchReport)
</script>

<template>
    <MyModal :value="props.open" :max-width="1600" :no-padding="!loading" @close="onClose()">
        <LoaderWrapper :visible="loading" />
        <div v-if="report" ref="modalContent">
            <div class="absolute right-0 space-x-2 p-6">
                <MyButton
                    v-if="authStore.companyId"
                    v-tooltip="t('shareEntity', { entity: t('report') })"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="shareReportOpen = true"
                >
                    <mdi:share />
                </MyButton>

                <MyButton icon plain scheme="light" size="small" @click="print()">
                    <mdi:printer />
                </MyButton>
            </div>

            <div class="flex">
                <!-- First Section -->
                <div class="flex w-full flex-col space-y-6 p-6">
                    <div>
                        <h3
                            class="text-lg font-semibold uppercase text-primary-400"
                            v-text="t('damageReport')"
                        />
                        <ContentHeading>
                            <div class="mt-1 flex space-x-2 text-xs">
                                <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                                    {{ t('referenceNumber') }}:
                                </h4>
                                <h4 class="font-medium dark:text-primary-400">
                                    {{ t('notSpecified') }}
                                </h4>
                            </div>
                        </ContentHeading>
                    </div>

                    <img
                        class="qrcode mx-auto hidden print:block"
                        style="width: 150px; height: 150px"
                    />

                    <div class="flex justify-between space-x-3">
                        <div class="flex w-1/2 justify-between items-center">
                            <div class="flex flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('registrationNumberShort')"
                                />
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="
                                        report.transportObject.registrationNumber ??
                                        t('notSpecified')
                                    "
                                />
                            </div>

                            <img
                                v-if="report.transportObject.companyLogo"
                                v-tooltip="report.transportObject.companyName"
                                :src="imageHelper(report.transportObject.companyLogo)"
                                class="ml-2 object-contain h-full !max-w-[30px] self-center m-1"
                            />
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('type')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="t(transportObjectTypes[report?.transportObject?.type ?? 0])"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-3">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('company')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="report?.company.name ?? t('notSpecified')"
                            />
                            <span
                                class="text-xs leading-3 text-primary-500 dark:text-primary-100"
                                v-text="report?.companyLocation?.name"
                            />

                            <img
                                v-if="report?.company?.logo"
                                class="mt-2 w-24 rounded-xl bg-primary-200"
                                :src="imageHelper(report?.company.logo, 'thumbnail')"
                            />
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('haulier')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="report?.haulierCompany?.name ?? t('notSpecified')"
                            />
                            <span
                                class="text-xs leading-3 text-primary-500 dark:text-primary-100"
                                v-text="report?.haulierLocation?.name"
                            />
                            <img
                                v-if="report?.haulierCompany?.logo"
                                class="mt-2 w-20 rounded"
                                :src="imageHelper(report?.haulierCompany.logo, 'thumbnail')"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-3">
                        <div
                            v-if="
                                report.customerCompany &&
                                authStore.hasLicense(LicenseType.TransportObjectOwnerCompany)
                            "
                            class="flex w-1/2 flex-col"
                        >
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('customer')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="report?.customerCompany?.name ?? t('notSpecified')"
                            />

                            <img
                                v-if="report?.customerCompany?.logo"
                                class="mt-2 w-24 rounded-xl bg-primary-200"
                                :src="imageHelper(report?.customerCompany.logo, 'thumbnail')"
                            />
                        </div>

                        <div
                            v-if="
                                report.departure ||
                                authStore.hasLicense(LicenseType.FjordlineTrailerInformation)
                            "
                            class="flex w-1/2 flex-col"
                        >
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('departure')"
                            />
                            <div class="group/item flex items-center">
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="report?.departure ?? t('notSpecified')"
                                />

                                <span
                                    v-if="
                                        authStore.hasLicense(
                                            LicenseType.FjordlineTrailerInformation,
                                        )
                                    "
                                    class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                                    @click="departureModalOpen = true"
                                >
                                    <mdi:pencil />
                                </span>
                            </div>

                            <div v-if="report.reason" class="flex w-1/2 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('reason')"
                                />
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="t(report.reason)"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex flex-col justify-start !mt-3 justify-between w-1/2 text-sm print:hidden"
                    >
                        <h3 class="font-semibold uppercase text-primary-400">
                            {{ report.tour ? t('tour') : t('notAssignedToTour') }}
                        </h3>
                        <RouterLink
                            v-if="report.tour"
                            :to="{
                                name: 'dm.tours.show',
                                params: { tourId: report.tour?.id },
                                query: route.query,
                            }"
                            class="text-sm font-semibold uppercase text-primary-500 hover:text-primary-400 dark:text-primary-100"
                            v-text="t('view') + ' ' + report.tour?.name"
                        />
                    </div>

                    <div class="flex justify-between">
                        <div
                            v-if="
                                report.transportObject.companyId === authStore.companyId &&
                                report.lastReportId
                            "
                            class="flex w-1/2 flex-col"
                        >
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('lastReport')"
                            />
                            <RouterLink
                                :to="{
                                    name: 'dmr.reports.show',
                                    params: { reportId: report.lastReportId },
                                    query: route.query,
                                }"
                                class="text-sm font-semibold uppercase"
                                v-text="t('view')"
                            />
                        </div>
                    </div>

                    <div class="space-y-6 print:flex print:flex-col-reverse">
                        <div class="mx-auto flex w-full break-inside-avoid flex-col justify-center">
                            <div
                                class="flex items-center justify-between space-y-1 rounded-t-xl bg-primary-50 px-4 py-3 dark:bg-dark-500 print:px-0"
                            >
                                <div class="flex flex-col">
                                    <div class="flex space-x-1 item-center">
                                        <span
                                            class="text-sm font-semibold text-primary-500 dark:text-primary-300"
                                            v-text="t(reportTypes[report?.type ?? 0])"
                                        />
                                        <mdi:refresh-auto
                                            v-if="report.isAutomaticDropOff"
                                            v-tooltip="getAutomaticDropOffTranslation()"
                                            class="h-4 w-4 text-red-500 font-bold"
                                        />
                                    </div>
                                    <span
                                        class="text-xs text-primary-400 dark:text-primary-100"
                                        v-text="formatDate(report?.transpiredAt)"
                                    />
                                </div>

                                <div class="flex items-center space-x-3">
                                    <span
                                        class="text-sm font-semibold text-primary-500 dark:text-primary-300"
                                        v-text="report.user.name"
                                    />

                                    <img
                                        v-if="report.user.profileImage"
                                        :alt="report.user.name"
                                        class="mr-2.5 w-10 rounded-full object-cover"
                                        :src="imageHelper(report.user.profileImage)"
                                    />
                                </div>
                            </div>

                            <div
                                class="relative h-[200px] rounded-b-xl shadow-xl print:shadow-none"
                            >
                                <article v-if="report?.location" ref="mapWrapper" class="h-full" />

                                <span
                                    class="absolute bottom-4 right-1 z-50 text-xs text-primary-500 drop-shadow-lg"
                                    v-text="report?.address"
                                />
                            </div>
                        </div>

                        <div v-if="report?.note" class="flex break-inside-avoid flex-col">
                            <span class="text-sm font-semibold" v-text="t('note') + ': '" />
                            <span class="text-xs" v-text="report?.note" />
                        </div>
                    </div>
                </div>

                <!-- Second Section -->
                <div
                    v-if="report?.wheels || hasFields"
                    class="flex w-full flex-col bg-primary-50 p-6 dark:bg-dark-500 print:pt-6"
                >
                    <h3
                        v-if="report?.wheels"
                        class="mb-16 text-center text-lg font-semibold uppercase text-primary-400"
                        v-text="t('tireThreads')"
                    />

                    <div
                        v-for="(wheelSet, index) in report?.wheels"
                        :key="index"
                        class="grid grid-cols-12"
                    >
                        <div
                            class="col-span-2 col-start-2 -mt-5 flex items-center justify-end text-sm"
                        >
                            <span class="pr-1 font-semibold" v-text="wheelSet[0]" />
                            mm
                        </div>

                        <img
                            :src="
                                index !== (report?.wheels || []).length - 1
                                    ? localAsset('assets/wheels.png')
                                    : localAsset('assets/wheelsEnd.png')
                            "
                            alt="wheels"
                            class="col-span-6 col-start-4 -mt-10 mb-5 w-full"
                        />
                        <div class="col-span-2 col-start-10 -mt-5 flex items-center text-sm">
                            <span class="pr-1 font-semibold" v-text="wheelSet[1]" />
                            mm
                        </div>
                    </div>

                    <div v-if="hasFields">
                        <h3
                            class="mb-0.5 text-lg font-semibold uppercase text-primary-400"
                            v-text="t('fields')"
                        />

                        <MyPanel panel-class="w-full overflow-auto" bordered>
                            <table class="table-fixed w-full">
                                <thead>
                                    <tr
                                        class="border-b border-primary-200 bg-primary-100 text-left text-xs dark:border-dark-600 dark:bg-dark-500"
                                    >
                                        <th class="w-2/5 p-2.5 font-semibold" v-text="t('name')" />
                                        <th
                                            class="w-2/4 border-x border-primary-200 p-2.5 font-semibold"
                                            v-text="t('previousReport')"
                                        />
                                        <th
                                            class="w-2/4 border-x border-primary-200 p-2.5 font-semibold"
                                            v-text="t('currentReport')"
                                        />

                                        <th
                                            class="w-1/4 p-2.5 font-semibold"
                                            v-text="t('images')"
                                        />
                                    </tr>
                                </thead>

                                <tbody v-if="report?.fields">
                                    <tr
                                        v-for="field in report.fields"
                                        :key="field.id"
                                        class="break-inside-avoid border-t border-primary-200 first:border-t-0 odd:bg-primary-100 dark:odd:bg-dark-400"
                                    >
                                        <td
                                            v-truncate-tooltip="field.templateField.name"
                                            class="truncate border-r border-primary-200 p-2.5 text-sm"
                                            v-text="field.templateField.name"
                                        />

                                        <!-- Previous value -->
                                        <td
                                            v-truncate-tooltip="field.lastReportValue"
                                            class="truncate border-r border-primary-200 p-2.5 text-sm"
                                        >
                                            <div
                                                v-if="field.lastReportNotPossibleToCheck"
                                                :class="{
                                                    'flex justify-center':
                                                        field.lastReportNotPossibleToCheck,
                                                }"
                                            >
                                                <mdi:close-octagon-outline
                                                    v-tooltip="t('notPossibleToCheck')"
                                                    class="text-yellow-500"
                                                />
                                            </div>

                                            <div
                                                v-else-if="
                                                    field.templateField.type ===
                                                    TemplateFieldType.Boolean
                                                "
                                            >
                                                <mdi:check-bold
                                                    v-if="field.lastReportValue == '1'"
                                                    class="text-green-500"
                                                />
                                                <mdi:close-thick
                                                    v-if="field.lastReportValue == '0'"
                                                    class="text-red-500"
                                                />
                                            </div>

                                            <span
                                                v-else-if="
                                                    field.templateField.type ===
                                                    TemplateFieldType.TankLevel
                                                "
                                                v-text="
                                                    tankLevels[field.lastReportValue ?? ''] ??
                                                    field.lastReportValue
                                                "
                                            />

                                            <span v-else v-text="field.lastReportValue" />
                                        </td>

                                        <!-- Current value -->
                                        <td
                                            v-truncate-tooltip="field.value"
                                            class="border-r truncate border-primary-200 p-2.5 text-sm"
                                        >
                                            <div
                                                v-if="field.notPossibleToCheck"
                                                :class="{
                                                    'flex justify-center': field.notPossibleToCheck,
                                                }"
                                            >
                                                <mdi:close-octagon-outline
                                                    v-tooltip="t('notPossibleToCheck')"
                                                    class="text-yellow-500"
                                                />
                                            </div>

                                            <div
                                                v-else-if="
                                                    field.templateField.type ===
                                                    TemplateFieldType.Boolean
                                                "
                                            >
                                                <mdi:check-bold
                                                    v-if="field.value == '1'"
                                                    class="text-green-500"
                                                />
                                                <mdi:close-thick
                                                    v-if="field.value == '0'"
                                                    class="text-red-500"
                                                />
                                            </div>

                                            <span
                                                v-else-if="
                                                    field.templateField.type ===
                                                    TemplateFieldType.TankLevel
                                                "
                                                v-text="tankLevels[field.value] ?? field.value"
                                            />

                                            <span v-else v-text="field.value" />
                                        </td>

                                        <td class="p-2.5">
                                            <MyImageViewer class="flex flex-col">
                                                <img
                                                    v-for="image in field.images"
                                                    :key="image.id"
                                                    class="my-1 w-10 self-center rounded hover:cursor-pointer"
                                                    :src="
                                                        imageHelper(
                                                            image as unknown as string,
                                                            'thumbnail',
                                                        )
                                                    "
                                                    :data-src="
                                                        imageHelper(
                                                            image as unknown as string,
                                                            'public',
                                                        )
                                                    "
                                                />
                                            </MyImageViewer>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </MyPanel>
                    </div>
                </div>

                <!-- Third Section -->
                <div class="w-full p-6">
                    <IncidentView
                        v-if="report?.transportObject"
                        class="print:hidden"
                        :transport-object="report.transportObject"
                        :template="report.template"
                        :report-id="report.id"
                        :section-notes="report.notes"
                        :alarms="report.alarms"
                        show-fixed-by-default
                    />
                    <div v-if="report.transportObject.template && printMode">
                        <h3
                            class="hidden text-lg font-semibold uppercase text-primary-400 print:block"
                            v-text="t('incidents')"
                        />
                        <div
                            v-for="image in report.transportObject.template.masterTemplate.images"
                            :key="image.id"
                        >
                            <IncidentView
                                v-if="report?.transportObject && report.transportObject.template"
                                :transport-object="report.transportObject"
                                :template="report.template"
                                :report-id="report.id"
                                :section-notes="report.notes"
                                :print-image="image"
                                show-fixed-by-default
                            />

                            <hr class="my-4 h-[1.5px] bg-dark-300" />
                        </div>
                    </div>
                </div>
            </div>
            <img
                :src="localAsset('assets/begreenprintlabel.png')"
                alt="begreen"
                class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
            />

            <MyDispatchLog
                v-if="report.dispatches.length > 0"
                class="ml-3 mb-3"
                :dispatches="report.dispatches"
            />
        </div>
    </MyModal>

    <BasicShareModal
        v-if="report"
        v-model="shareReportOpen"
        :customer-company-id="report.customerCompany?.id"
        :entity="t('report')"
        :endpoint="shareEndpoint"
    />

    <UpdateDepartureModal
        v-if="departureModalOpen && report"
        v-model="departureModalOpen"
        :report-id="report.id"
        :departure="report.departure"
    />
</template>

<script lang="ts" setup>
import type { Except } from 'type-fest'

import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { type MinimalResource, ResourceResponse, uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'
import { Employee, UserType } from '@/types/user'
import { image as imageHelper } from '@/utils/assets'
import { userTypeOptions } from '@/utils/employees'
import { cloudflareUpload } from '@/utils/upload'
import { LicenseType } from '@/types/company'
import { useEmployeeRoleStore } from '@/stores/employee-role-store'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'

import ManageAppRolesModal from '@/components/settings/ManageAppRolesModal.vue'
import ManageWebRolesModal from '@/components/settings/ManageWebRolesModal.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    userId?: uuid
    companyId?: uuid
}

type Form = Except<Employee, 'id' | 'locale' | 'appRoleName' | 'webRoleName'>

const emit = defineEmits<{ (e: 'closed', updated: boolean): void }>()
const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const roleStore = useEmployeeRoleStore()

const open = ref(false)
const permissionsToggle = ref(false)
const fetching = ref(false)
const uploading = ref(false)
const { data, loading, errors, reset, submit } = useForm<Form>({
    phoneNumber: '',
    username: '',
    name: '',
    profileImage: '',
    email: '',
    permissions: {},
    userType: UserType.Driver,
    webportalEnabled: true,
    appEnabled: true,
    twoFactorEnabled: true,
    locations: [],
    settlementTemplateId: null,
    appRoleId: null,
    webRoleId: null,
})
const manageAppRolesModalOpen = ref(false)
const manageWebRolesModalOpen = ref(false)
const locations = useLazyLoadedList<DropdownStringOption>(fetchLocations)
const userTypes = userTypeOptions(t)

async function fetchEmployee() {
    fetching.value = true
    const { data: response } = await axios.get<ResourceResponse<Employee>>(
        window.route('company.employees.show', {
            company: props.companyId || authStore.companyId,
            employee: props.userId!,
        }),
    )

    data.phoneNumber = response.data.phoneNumber
    data.username = response.data.username
    data.name = response.data.name
    data.profileImage = response.data.profileImage
    data.email = response.data.email
    data.userType = response.data.userType
    data.appRoleId = response.data.appRoleId
    data.webRoleId = response.data.webRoleId
    data.webportalEnabled = response.data.webportalEnabled
    data.appEnabled = response.data.appEnabled
    data.permissions = response.data.permissions
    data.twoFactorEnabled = response.data.twoFactorEnabled
    data.locations = response.data.locations
    data.settlementTemplateId = response.data.settlementTemplateId
    if (data.locations.length > 0) {
        locations.fetch()
    }

    fetching.value = false
}

async function fetchLocations(): Promise<DropdownStringOption[]> {
    if (!props.companyId || props.companyId === authStore.companyId) {
        return companyStore.locations.map(({ id, name }) => ({ value: id, label: name }))
    }

    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: props.companyId,
        }),
    )

    return response.data.map((location) => ({ value: location.id, label: location.name }))
}

async function handleImageUpload(file: File) {
    uploading.value = true
    try {
        data.profileImage = await cloudflareUpload(file)
        uploading.value = false
    } catch (e) {
        notify({ title: t('unknownError'), type: 'error' })
    }
}

async function onSubmit() {
    const response = await submit(
        'put',
        window.route('company.employees.update', {
            employee: props.userId!,
            company: props.companyId || authStore.companyId,
        }),
    )
    if (response) emit('closed', true)
}

watch(open, () => {
    if (!open.value) {
        reset()
        emit('closed', false)
    }
})
watch(
    () => props.userId,
    () => {
        open.value = !!props.userId
        if (!props.userId) return

        fetchEmployee()

        if (!props.companyId) {
            roleStore.fetchAppRoles()
            roleStore.fetchWebRoles()
            companyStore.fetchSettlementTemplates()
        }
    },
)
</script>

<template>
    <MyModal v-model="open">
        <template #title>
            {{ t('updateEntity', { entity: data.name }) }}
        </template>

        <LoaderWrapper :visible="loading || fetching" />

        <MyForm class="mt-2 space-y-2" :errors="errors" @submit.prevent="onSubmit">
            <div class="flex justify-center">
                <LoaderWrapper :visible="uploading" class="rounded-xl" />

                <MyFileSelect
                    class="w-fit rounded-full"
                    :class="{ 'h-20 min-w-[250px]': !data.profileImage }"
                    type="image/*"
                    @selected="handleImageUpload"
                >
                    <p
                        class="absolute z-10 text-xs font-semibold dark:text-gray-300"
                        :class="{ 'mt-10': data.profileImage }"
                        v-text="t('dragAndDropImage')"
                    />

                    <img
                        v-if="data.profileImage"
                        class="m-2 block h-20 w-20 rounded-full opacity-60 ring-2 ring-white hover:opacity-100"
                        :src="imageHelper(data.profileImage)"
                        alt="Avatar"
                    />
                </MyFileSelect>
            </div>

            <MyInput
                ref="usernameInput"
                v-model="data.username"
                name="username"
                :label="t('username')"
                type="text"
                :placeholder="t('username')"
                autofocus
            />

            <MyPhoneInput
                ref="phoneInput"
                v-model="data.phoneNumber"
                name="phoneNumber"
                :label="t('phoneNumber')"
                type="text"
                :placeholder="t('phoneNumber')"
            />

            <MyCheckbox
                v-model="data.twoFactorEnabled"
                name="twoFactorEnabled"
                :label="t('twoFactorEnabled')"
            />

            <MyInput
                v-model="data.name"
                name="name"
                :label="t('name')"
                type="text"
                :placeholder="t('name')"
            />

            <MyInput
                v-model="data.email"
                name="email"
                :label="t('email')"
                type="text"
                :placeholder="t('email')"
            />

            <MySelect
                v-if="authStore.hasLicense(LicenseType.AgentLocationFiltering)"
                v-model="data.locations"
                :label="t('locations')"
                :placeholder="t('allLocations')"
                multiple
                searchable
                :options="locations.items.value"
                :loading="locations.fetching.value"
                @focus="locations.fetch()"
            />

            <!-- It's is not possible to add web users for other companies so we don't need to show the toggles -->
            <div v-if="!props.companyId" class="space-x-3 pt-2">
                <MyCheckbox
                    v-model="data.webportalEnabled"
                    name="webportalEnabled"
                    :label="t('webportalEnabled')"
                />
                <MyCheckbox v-model="data.appEnabled" name="appEnabled" :label="t('appEnabled')" />
            </div>

            <div v-if="data.appEnabled">
                <h5 class="mt-5 mb-2 text-base" v-text="t('appPreferences')" />

                <hr class="mb-2" />

                <div class="space-y-2">
                    <MySelect
                        v-model="data.userType"
                        :options="userTypes"
                        name="userType"
                        :label="t('userType')"
                    />

                    <div
                        v-if="
                            data.userType === UserType.Driver &&
                            authStore.hasLicense(LicenseType.DamageReportModule) &&
                            !props.companyId
                        "
                        class="flex items-end space-x-2"
                    >
                        <MySelect
                            v-model="data.appRoleId"
                            :options="roleStore.appRoleOptions"
                            :placeholder="t('standard')"
                            group-class="w-full"
                            name="roleId"
                            :label="t('userRole')"
                            clear-button
                            :loading="roleStore.fetchingAppRoles"
                        />

                        <MyButton
                            v-tooltip="t('manageUserRoles')"
                            icon
                            type="button"
                            @click="manageAppRolesModalOpen = true"
                        >
                            <mdi:account-group />
                        </MyButton>
                    </div>

                    <MySelect
                        v-if="!authStore.hasLicense(LicenseType.AgentLocationFiltering)"
                        v-model="data.locations"
                        :label="t('locations')"
                        :placeholder="t('allLocations')"
                        multiple
                        searchable
                        :options="locations.items.value"
                        :loading="locations.fetching.value"
                        @focus="locations.fetch()"
                    />

                    <MySelect
                        v-if="
                            authStore.hasLicense(LicenseType.DriverReportModule) && !props.companyId
                        "
                        v-model="data.settlementTemplateId"
                        :options="companyStore.settlementTemplateOptions"
                        :placeholder="t('flatRate')"
                        group-class="w-full"
                        name="settlementTemplateId"
                        :label="`${t('driverReports')} ${t('settlementTemplate')}`"
                        clear-button
                        :loading="companyStore.loadingSettlementTemplates"
                    />
                </div>
            </div>

            <div v-if="data.webportalEnabled" class="flex flex-col">
                <div
                    class="mt-2 flex cursor-pointer justify-between"
                    @click="permissionsToggle = !permissionsToggle"
                >
                    <h5 class="mb-2 select-none text-base" v-text="t('webportalPermissions')" />

                    <mdi:chevron-down
                        class="ml-1 transition duration-300"
                        :class="{ 'rotate-180': permissionsToggle }"
                    />
                </div>

                <hr class="mb-2" />

                <div v-if="permissionsToggle" class="flex items-end space-x-2">
                    <MySelect
                        v-model="data.webRoleId"
                        :options="roleStore.webRoleOptions"
                        group-class="w-full"
                        name="webRoleId"
                        :label="t('role')"
                        clear-button
                        :loading="roleStore.fetchingWebRoles"
                    />

                    <MyButton
                        v-tooltip="t('manageUserRoles')"
                        icon
                        type="button"
                        @click="manageWebRolesModalOpen = true"
                    >
                        <mdi:account-group />
                    </MyButton>
                </div>
            </div>

            <div>
                <MyButton :disabled="loading" class="mt-2 w-full" scheme="primary">
                    {{ t('update') }}
                </MyButton>
            </div>
        </MyForm>

        <ManageAppRolesModal v-model="manageAppRolesModalOpen" />
        <ManageWebRolesModal v-model="manageWebRolesModalOpen" />
    </MyModal>
</template>

import type { GetDataParameters } from '@/types/table'

import { CanceledError } from 'axios'
import { debouncedWatch, useDebounceFn, useLocalStorage } from '@vueuse/core'
import { onBeforeUnmount, Ref, ref } from 'vue'
import { LocationQuery, LocationQueryRaw, useRoute, useRouter } from 'vue-router'

import { ApiPaginationData, PaginatedResponse, PaginationData } from '@/types/general'

interface Options {
    disableQueryParams: boolean
    lazy: boolean
}

const defaultOptions: Options = { disableQueryParams: false, lazy: false }

const defaultParams: GetDataParameters = {
    query: undefined,
    filter: undefined,
    page: 1,
    'per-page': 15,
    sort: undefined,
}

export function usePaginatedTable<
    Model = Record<string, unknown>,
    Params extends Record<string, unknown> = Record<string, unknown>,
>(
    onFetch: (
        params: Params,
        abortController: AbortController,
    ) => Promise<PaginatedResponse<Model>>,
    parameters?: Params,
    options?: Partial<Options>,
) {
    let abortController: AbortController | null
    const paginationOptions: Options = { ...defaultOptions, ...options }
    let unmounted = false
    const router = useRouter()
    const route = useRoute()
    const loading = ref(false)
    const error = ref(false)
    const perPage = useLocalStorage('tablePerPage', 15)
    const paginationData = ref<PaginationData>({
        from: 1,
        to: 1,
        perPage: perPage.value,
        lastPage: 1,
        currentPage: Number(route.query.page ?? 1),
        total: 0,
    })

    // @ts-ignore I don't know how this is not right
    const params = ref<Params & GetDataParameters>({
        ...defaultParams,
        query: route.query.query ?? '',
        sort: route.query.sort,
        'per-page': paginationData.value.perPage,
        page: paginationData.value.currentPage,
        ...(parameters || {}),
        filter: mergeFilters(route.query, (parameters?.filter ?? {}) as object),
    }) as Ref<Params & GetDataParameters>
    // For some reason Vue types add UnwrapRefSimple<Model>[] instead which is not correct
    const data = ref<Model[]>([]) as Ref<Model[]>

    async function setQueryParams() {
        if (paginationOptions.disableQueryParams) return

        const query: Record<string, unknown> = { ...route.query }
        for (const [key, value] of Object.entries(params.value)) {
            if (ignoredQueryParams.includes(key)) continue
            // Skip empty strings and page 1 we'll remove it from the query
            if ((value + '').length === 0 || (key === 'page' && value === 1)) {
                delete query[key]
            } else {
                // Remove reactivity from objects
                query[key] = value instanceof Object ? { ...value } : value
            }
        }

        await router.replace({ query: query as LocationQueryRaw })
    }

    const fetch = useDebounceFn(async () => {
        abortController?.abort()
        abortController = null

        error.value = false
        loading.value = true

        await setQueryParams()

        try {
            abortController = new AbortController()
            const response = await onFetch(params.value, abortController)

            data.value = response.data
            if (!paginationOptions.lazy) {
                paginationData.value = {
                    from: response.meta.from,
                    to: response.meta.to,
                    perPage: response.meta.per_page,
                    lastPage: response.meta.last_page,
                    currentPage: response.meta.current_page,
                    total: response.meta.total,
                }
            }
        } catch (e) {
            if (!(e instanceof CanceledError)) {
                error.value = true
                throw e
            }
        } finally {
            loading.value = false
        }
    }, 1)

    debouncedWatch(
        params,
        async () => {
            if (unmounted) return
            perPage.value = params.value['per-page']
            await fetch()
        },
        { deep: true, debounce: 2 },
    )

    onBeforeUnmount(() => {
        unmounted = true
    })

    return {
        loading,
        data,
        paginationData,
        error,
        params,
        setPaginationData(data: ApiPaginationData) {
            paginationData.value = {
                from: 1 + (data.current_page * data.per_page - data.per_page),
                to: Math.min(data.current_page * data.per_page, data.total),
                perPage: data.per_page,
                lastPage: data.last_page,
                currentPage: data.current_page,
                total: data.total,
            }
        },
        async refetch(parameters?: Partial<Params | GetDataParameters>) {
            params.value = { ...params.value, ...(parameters || {}) }
        },
    }
}

const ignoredQueryParams = ['per-page']

function mergeFilters(queryParams: LocationQuery, filters: object): object | undefined {
    const currentFilters = ((queryParams || {}) as Record<string, unknown>)['filter'] || {}
    const queryFilters = currentFilters instanceof Object ? currentFilters : {}
    if (Object.keys(queryFilters).length === 0 && Object.keys(filters).length === 0) {
        return undefined
    }

    return { ...filters, ...queryFilters }
}

<script lang="ts" setup>
import axios from 'axios'
import { api as viewerApi } from 'v-viewer'
import { onBeforeMount, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { datetime, differenceBetweenDates, differenceFromMinutes } from '@/utils/dates'
import { image as imageHelper, localAsset } from '@/utils/assets'
import { ResourceResponse, uuid } from '@/types/general'
import {
    Report,
    EventType,
    ReportEvent,
    EventImageType,
    EventActionType,
} from '@/types/driver-report'
import { useAuthStore } from '@/stores/auth-store'
import useGoogleMaps from '@/hooks/use-google-maps'
import { printElement } from '@/utils/print-element'
import { LocationPoint } from '@/types/company'
import { googleMapsLink } from '@/utils/google-maps'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { addressString } from '@/utils/string'

import StopReportModal from '@/components/driver-reports/StopReportModal.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'
import ManageEventModal from '@/components/driver-reports/ManageEventModal.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

export interface Props {
    id?: string
    open?: boolean
}

let polyline: google.maps.Polyline | null = null

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const mapWrapper = ref<HTMLElement>()
const googleMaps = useGoogleMaps(mapWrapper, undefined, {}, setMapMarkers)

const modalContent = ref<HTMLDivElement>()
const createEventModalOpen = ref(false)
const updateEventModalOpen = ref(false)
const stopReportModalOpen = ref(false)
const selectedEventId = ref<uuid>()

const report = ref<Report | null>(null)
const loading = ref(false)
const shareReportOpen = ref(false)
const imageViewerOpen = ref(false)

async function fetchReport() {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Report>>(
            window.route('dr.reports.show', {
                report: props.id ?? route.params.reportId,
            }),
        )
        report.value = response.data.data
        loading.value = false
        if (googleMaps) setMapMarkers()
    } finally {
        loading.value = false
    }
}

const eventActionsExist = computed(() => {
    return report.value?.events.some((event) => {
        if (event.tourEvent) return true
        if (event.tourId) return true

        return event.actions.some((action) => action.type !== EventActionType.AttachUnit)
    })
})

// Map Related
function setMapMarkers() {
    if (!report.value || !googleMaps.map.value) return

    const positions: { lat: number; lng: number }[] = []

    googleMaps.clearMarkers()

    let index = 0

    for (const event of report.value.events) {
        if (!event.location) continue

        index++

        googleMaps.addMarker(event.location, {
            markerLabel: index.toString(),
            tooltipContent: `
                ${event.address ?? [event.location.latitude, event.location.longitude]}
            `,
            href: googleMapsLink(event.location.latitude, event.location.longitude),
        })

        positions.push({ lat: event.location.latitude, lng: event.location.longitude })
    }

    googleMaps.fitMarkerBounds()

    polyline = new google.maps.Polyline({
        path: positions,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
    })

    polyline.setMap(googleMaps.map.value!)
}

function panToLocation(location: LocationPoint) {
    googleMaps.map.value?.panTo({ lat: location.latitude, lng: location.longitude })
}

// Endpoint Related
function selectEvent(id: string) {
    selectedEventId.value = id
}

function updateEvent(eventId: string) {
    selectEvent(eventId)
    updateEventModalOpen.value = true
}

const selectedEvent = computed(() => {
    if (!selectedEventId.value) return undefined

    return report.value!.events.find(({ id }) => id === selectedEventId.value)
})

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('dr.company.reports.share', {
        company: authStore.companyId,
        report: report.value?.id ?? '',
    })
})

const startNote = computed(() => {
    return report.value?.events.find((event) => event.type === EventType.ReportStart)?.departureNote
})

const endNote = computed(() => {
    return report.value?.events.find((event) => event.type === EventType.ReportEnd)?.arrivalNote
})

const hasOngoingEvent = computed(() => {
    if (report.value?.events.find((event) => event.endedAt === null)) return true
    return false
})

function showArrivalImages(event: ReportEvent) {
    const arrivalImages = event.images.filter(
        (image) => image.type === EventImageType.Arrival && image.path,
    )

    if (arrivalImages.length > 0) {
        viewerApi({
            images: arrivalImages.map((image) => imageHelper(image.path!, 'public')),
            options: {
                url: 'src',
                title: false,
            },
        })
    }
}

function showDepartureImages(event: ReportEvent) {
    const departureImages = event.images.filter(
        (image) => image.type === EventImageType.Departure && image.path,
    )

    if (departureImages.length > 0) {
        viewerApi({
            images: departureImages.map((image) => imageHelper(image.path!, 'public')),
            options: {
                url: 'src',
                title: false,
            },
        })
    }
}

async function createEventModalClosed() {
    createEventModalOpen.value = false
    fetchReport()
}

async function updateEventModalClosed() {
    updateEventModalOpen.value = false
    fetchReport()
}

async function stopReportModalClosed() {
    stopReportModalOpen.value = false
}

const totalKilometerCount = computed(() => {
    return report.value!.events.at(-1)!.kilometerCount - report.value!.events[0].kilometerCount
})

const totalBreakTime = computed(() => {
    const breakEvents = report
        .value!.events.filter((event) => event.type === EventType.Break)
        .map((event) => {
            const diff = new Date(event.endedAt!).getTime() - new Date(event.startedAt).getTime()
            return Math.floor(diff / 1000 / 60)
        })

    return differenceFromMinutes(breakEvents.reduce((a, b) => a + b, 0))
})

function print() {
    printElement(modalContent.value!, true)
}

function showImage(path: string) {
    viewerApi({
        images: [imageHelper(path, 'public')],
        options: {
            url: 'src',
            toolbar: false,
            navbar: false,
            title: false,
        },
    })
}

function onClose() {
    if (imageViewerOpen.value) return
    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dr.reports', query: route.query })
    }
}

function getEventRegistrationNumbers(event: ReportEvent): string {
    return event.actions
        .filter((action) => action.type === EventActionType.AttachUnit)
        .map((action) => report.value?.unitRegistrationNumbers[action.actionableId] ?? '')
        .join(', ')
}

useMittListener('fetchDriverReports', () => fetchReport())

onBeforeMount(async () => {
    if (route.params.reportId) fetchReport()
    if (props.id) await fetchReport()
})
watch(() => props.id, fetchReport)
watch(
    () => route.params.reportId,
    () => {
        if (route.params.reportId) fetchReport()
    },
)
</script>

<template>
    <MyModal :value="props.open" :max-width="1400" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div v-if="report" ref="modalContent">
            <div class="mb-6 flex justify-between">
                <ContentHeading>
                    <h1 class="text-xl font-bold text-primary-400">{{ t('drivingLog') }}</h1>
                    <div class="mt-1 flex space-x-2 text-xs">
                        <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                            {{ t('referenceNumber') }}:
                        </h4>
                        <h4 class="font-medium dark:text-primary-400">
                            {{ report.referenceNumber ?? t('notSpecified') }}
                        </h4>
                    </div>
                </ContentHeading>

                <div v-if="authStore.companyId" class="flex space-x-2 print:hidden">
                    <div
                        v-tooltip="{
                            disabled: !hasOngoingEvent,
                            content: t('hasOngoingEventExplanation'),
                        }"
                    >
                        <MyButton
                            scheme="primary"
                            size="small"
                            :disabled="hasOngoingEvent"
                            @click="createEventModalOpen = true"
                        >
                            {{ t('createEvent') }}
                        </MyButton>
                    </div>

                    <div v-if="report.endedAt === null">
                        <MyButton scheme="danger" size="small" @click="stopReportModalOpen = true">
                            {{ t('stopReport') }}
                        </MyButton>
                    </div>

                    <MyButton icon plain scheme="light" size="small" @click="print()">
                        <mdi:printer />
                    </MyButton>

                    <MyButton
                        v-if="!!authStore.user"
                        v-tooltip="t('shareEntity', { entity: t('report') })"
                        icon
                        plain
                        scheme="light"
                        size="small"
                        @click="shareReportOpen = true"
                    >
                        <mdi:share />
                    </MyButton>
                </div>
            </div>

            <section class="mb-10 flex w-full items-start rounded-lg bg-white dark:bg-dark-500">
                <div class="m-6 grid w-1/2 grid-cols-3 gap-3">
                    <div class="col-span-3 flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('driver')"
                        />
                        <span class="text-xl font-semibold text-primary-500 dark:text-primary-100">
                            {{ report.user.name }}
                        </span>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('kmTotal')"
                        />
                        <span class="text-xl font-semibold text-primary-500 dark:text-primary-100">
                            {{ totalKilometerCount }} KM
                        </span>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('vehicle')"
                        />
                        <span class="text-sm text-primary-500 dark:text-primary-100">
                            {{ report.vehicle?.registrationNumber ?? t('notSpecified') }}

                            <RouterLink
                                v-if="report.damageReportPickupId"
                                :to="{
                                    name: 'dmr.reports.show',
                                    params: { reportId: report.damageReportPickupId },
                                }"
                                class="block font-semibold uppercase"
                                v-text="`${t('pickup')} ${t('report')}`"
                            />

                            <RouterLink
                                v-if="report.damageReportDropOffId"
                                :to="{
                                    name: 'dmr.reports.show',
                                    params: { reportId: report.damageReportDropOffId },
                                }"
                                class="block font-semibold uppercase"
                                v-text="`${t('dropOff')} ${t('report')}`"
                            />
                        </span>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('haulier')"
                        />
                        <span class="text-sm text-primary-500 dark:text-primary-100">
                            {{ report?.haulierCompany?.name ?? t('notSpecified') }}
                        </span>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('start')"
                        />
                        <span class="text-sm text-primary-500 dark:text-primary-100">
                            {{ datetime(report.startedAt) }}
                        </span>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('end')"
                        />
                        <span class="text-sm text-primary-500 dark:text-primary-100">
                            {{ report?.endedAt ? datetime(report.endedAt) : t('enRoute') }}
                        </span>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('type')"
                        />
                        <span class="text-sm text-primary-500 dark:text-primary-100">
                            {{ report.tourType ? t('export') : t('domestic') }}
                        </span>
                    </div>

                    <div>
                        <div class="flex items-center">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('duration')"
                            />
                        </div>
                        <span
                            v-tooltip="t('hourMinuteFormatExplanation')"
                            class="text-sm text-primary-500 dark:text-primary-100"
                            v-text="
                                differenceBetweenDates(
                                    report.startedAt,
                                    report.endedAt,
                                    report.restingTime,
                                )
                            "
                        />
                    </div>

                    <div>
                        <div class="flex items-center">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('restingTime')"
                            />
                        </div>
                        <span
                            v-tooltip="t('hourMinuteFormatExplanation')"
                            class="text-sm text-primary-500 dark:text-primary-100"
                            v-text="differenceFromMinutes(report.restingTime)"
                        />
                    </div>

                    <div>
                        <div class="flex items-center">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('breakTime')"
                            />
                        </div>
                        <span
                            v-tooltip="t('hourMinuteFormatExplanation')"
                            class="text-sm text-primary-500 dark:text-primary-100"
                            v-text="totalBreakTime ?? 0"
                        />
                    </div>

                    <div
                        v-if="
                            authStore.companyId === report.company.id &&
                            report.settlementTemplateName
                        "
                        class="col-span-3 flex flex-col"
                    >
                        <div class="flex items-center">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="
                                    `${t('settlementTemplate')} (${report.settlementTemplateName})`
                                "
                            />
                        </div>
                        <div class="mt-1 flex flex-wrap space-x-2">
                            <div
                                v-for="templateRate in report.settlementTemplateRates"
                                :key="templateRate.rate?.id"
                                :style="{ backgroundColor: templateRate.rate?.color ?? '#333' }"
                                class="mb-2 flex items-center rounded-lg px-2 py-1 text-xs text-white"
                            >
                                <span
                                    class="mr-2 font-bold"
                                    v-text="templateRate.rate?.name ?? t('noRate')"
                                />

                                <span
                                    v-tooltip="t('hourMinuteFormatExplanation')"
                                    v-text="differenceFromMinutes(templateRate.duration)"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="(startNote?.length ?? 0) > 1" class="col-span-3 flex flex-col">
                        <span
                            class="mt-2 text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('startNote')"
                        />
                        <span class="whitespace-pre-line text-xs" v-text="startNote" />
                    </div>

                    <div v-if="(endNote?.length ?? 0) > 1" class="col-span-3 flex flex-col">
                        <span
                            class="mt-2 text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('endNote')"
                        />
                        <span class="whitespace-pre-line text-xs" v-text="endNote" />
                    </div>

                    <img
                        class="qrcode col-span-3 ml-auto hidden print:block"
                        style="width: 180px; height: 180px"
                    />

                    <div v-if="report.tours.length > 0" class="col-span-3 flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('tours')"
                        />
                        <div v-for="tour in report.tours" :key="tour.id">
                            <RouterLink
                                :to="{
                                    name: 'dm.tours.show',
                                    params: { tourId: tour.id },
                                }"
                                class="text-xl font-semibold text-primary-500 dark:text-primary-100 hover:text-primary-400 dark:hover:text-primary-300 cursor-pointer"
                                v-text="tour.name"
                            />
                        </div>
                    </div>
                </div>

                <section ref="mapWrapper" class="h-96 w-96 grow rounded-r-lg" />
            </section>

            <MyPanel bordered>
                <MyTable
                    :loading="loading"
                    :rows="report.events"
                    disable-ordering
                    disable-actions
                    disable-search
                    disable-footer
                    disable-sorting
                    disable-column-preferences
                    table-id="dr-reports-events"
                    class="rounded-lg"
                >
                    <template v-if="authStore.companyId" #actionRow="{ row }">
                        <ActionRowItem
                            v-if="!hasOngoingEvent"
                            class="print:hidden"
                            @click="updateEvent(row.id)"
                        >
                            <mdi:pencil />
                        </ActionRowItem>
                    </template>
                    <MyTableColumn :name="t('type')" property="type" />
                    <template #type="{ row }">
                        <span v-if="row.type === EventType.ReportStart" v-text="t('start')" />
                        <span v-if="row.type === EventType.ReportEnd" v-text="t('finish')" />
                        <span v-if="row.type === EventType.Break" v-text="t('break')" />
                        <span v-if="row.type === EventType.Customer" v-text="t('customer')" />
                        <span v-if="row.type === EventType.Traffic" v-text="t('traffic')" />
                        <span v-if="row.type === EventType.Other" v-text="t('other')" />
                        <span v-if="row.type === EventType.Resting" v-text="t('resting')" />
                        <span v-if="row.type === EventType.StartTour" v-text="t('tourStarted')" />
                    </template>
                    <MyTableColumn
                        :name="t('referenceNumber')"
                        :width="170"
                        property="referenceNumber"
                    />

                    <MyTableColumn :name="t('arrived')" :width="170" property="startedAt" />
                    <template #startedAt="{ row }">
                        {{ row.type !== EventType.ReportStart ? datetime(row.startedAt) : '' }}
                    </template>
                    <MyTableColumn :name="t('departed')" :width="170" property="endedAt" />
                    <template #endedAt="{ row }">
                        <span v-if="row.endedAt === null" v-text="t('enRoute')" />
                        <span v-else>
                            {{
                                row.type !== EventType.ReportEnd ? datetime(row.endedAt || '') : ''
                            }}
                        </span>
                    </template>
                    <MyTableColumn :name="t('duration')" property="duration" />
                    <template #duration="{ row }">
                        <div v-tooltip="t('hourMinuteFormatExplanation')">
                            <span
                                v-if="
                                    row.type === EventType.ReportEnd ||
                                    row.type === EventType.ReportStart
                                "
                                v-text="''"
                            />
                            {{ differenceBetweenDates(row.startedAt, row.endedAt) }}
                        </div>
                    </template>
                    <MyTableColumn :name="t('wait')" property="waitTime" />
                    <template #waitTime="{ row }">
                        <div v-tooltip="t('hourMinuteFormatExplanation')">
                            <span v-if="row.type !== EventType.Customer" v-text="''" />
                            <span v-else>{{ differenceFromMinutes(row.waitTime) }}</span>
                        </div>
                    </template>
                    <MyTableColumn :name="t('customer')" property="customerCompany.name" />
                    <MyTableColumn :name="t('address')" property="address" />
                    <template #address="{ row }">
                        {{
                            row.customerLocation ? addressString(row.customerLocation) : row.address
                        }}
                    </template>
                    <MyTableColumn :name="t('km')" property="kilometerCount" />
                    <template #kilometerCount="{ row }">
                        <div class="flex">
                            <span class="w-full self-center truncate" v-text="row.kilometerCount" />
                            <MyButton
                                v-if="row.kilometerCountImage"
                                class="ml-2 hover:bg-dark-300"
                                icon
                                size="micro"
                                style="width: 20px"
                                @click="showImage(row.kilometerCountImage)"
                            >
                                <mdi:eye />
                            </MyButton>
                        </div>
                    </template>

                    <MyTableColumn :name="t('kmSum')" property="kilometerSum" />
                    <template #kilometerSum="{ row }">
                        {{ row.kilometerCount - report.events[0].kilometerCount }}
                    </template>
                    <MyTableColumn :name="t('registrationNumberShort')" property="units" />
                    <template #units="{ row }">{{ getEventRegistrationNumbers(row) }}</template>

                    <MyTableColumn :name="t('arrivalNote')" property="arrivalNote" />

                    <template #arrivalNote="{ row }">
                        <div class="flex">
                            <span class="w-full self-center truncate" v-text="row.arrivalNote" />
                            <MyButton
                                v-if="row.images.find((i) => i.type === EventImageType.Arrival)"
                                class="ml-2 hover:bg-dark-300"
                                icon
                                size="micro"
                                style="width: 20px"
                                @click="showArrivalImages(row)"
                            >
                                <mdi:eye />
                            </MyButton>
                        </div>
                    </template>

                    <MyTableColumn :name="t('departureNote')" property="departureNote" />
                    <MyTableColumn :name="t('loaded')" property="shipmentLoaded" boolean-column />
                    <template #departureNote="{ row }">
                        <div class="flex">
                            <span class="w-full self-center truncate" v-text="row.departureNote" />
                            <MyButton
                                v-if="row.images.find((i) => i.type === EventImageType.Departure)"
                                class="ml-2 hover:bg-dark-300"
                                icon
                                size="micro"
                                style="width: 20px"
                                @click="showDepartureImages(row)"
                            >
                                <mdi:eye />
                            </MyButton>
                        </div>
                    </template>

                    <MyTableColumn
                        :name="t('unloaded')"
                        property="shipmentUnloaded"
                        boolean-column
                    />
                    <MyTableColumn class="print:hidden" :name="t('geo')" property="geo" />

                    <template #geo="{ row }">
                        <a
                            v-if="row.location"
                            v-tooltip="row.customerLocation?.address ?? row.address"
                            :href="googleMapsLink(row.location.latitude, row.location.longitude)"
                            target="_blank"
                            class="hover:bg-dark-300"
                            icon
                            size="micro"
                            @mouseover="panToLocation(row.location)"
                        >
                            <mdi:map-marker />
                        </a>
                    </template>
                    <MyTableColumn
                        :hidden="!eventActionsExist"
                        class="print:hidden"
                        :name="''"
                        property="actions"
                    />

                    <template #actions="{ row }">
                        <div class="flex items-center space-x-2">
                            <div v-for="action in row.actions" :key="action.actionableId">
                                <RouterLink
                                    v-if="action.type === EventActionType.PackagingTransaction"
                                    v-tooltip="t('packagingTransaction')"
                                    :to="{
                                        name: 'packaging.transactions.show',
                                        params: { transactionId: action.actionableId },
                                    }"
                                >
                                    <mdi:package-variant-closed />
                                </RouterLink>

                                <RouterLink
                                    v-if="action.type === EventActionType.PODReceipt"
                                    v-tooltip="t('podReceipt')"
                                    :to="{
                                        name: 'pod.receipts.show',
                                        params: { receiptId: action.actionableId },
                                    }"
                                >
                                    <mdi:receipt />
                                </RouterLink>

                                <RouterLink
                                    v-if="
                                        action.type === EventActionType.AttachUnit &&
                                        action.damageReportId
                                    "
                                    v-tooltip="`${t('pickup')} ${t('damageReport')}`"
                                    :to="{
                                        name: 'dmr.reports.show',
                                        params: { reportId: action.damageReportId },
                                    }"
                                >
                                    <mdi:truck-trailer />
                                </RouterLink>

                                <RouterLink
                                    v-if="
                                        action.type === EventActionType.DetachUnit &&
                                        action.damageReportId
                                    "
                                    v-tooltip="`${t('dropOff')} ${t('damageReport')}`"
                                    :to="{
                                        name: 'dmr.reports.show',
                                        params: { reportId: action.damageReportId },
                                    }"
                                >
                                    <mdi:truck-trailer />
                                </RouterLink>
                            </div>

                            <RouterLink
                                v-if="row.tourEvent?.shipmentId"
                                v-tooltip="t('showShipment')"
                                class="print:hidden"
                                :to="{
                                    name: 'dm.shipments.show',
                                    params: { shipmentId: row.tourEvent.shipmentId },
                                }"
                            >
                                <mdi:eye />
                            </RouterLink>

                            <RouterLink
                                v-if="row.tourId"
                                v-tooltip="t('showTour')"
                                class="print:hidden"
                                :to="{
                                    name: 'dm.tours.show',
                                    params: { tourId: row.tourId },
                                }"
                            >
                                <mdi:eye />
                            </RouterLink>
                        </div>
                    </template>
                </MyTable>
            </MyPanel>
            <img
                :src="localAsset('assets/begreenprintlabel.png')"
                alt="begreen"
                class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
            />
        </div>

        <h1
            v-if="!report && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('driverReportNotFound')"
        />
    </MyModal>

    <BasicShareModal
        v-if="report"
        v-model="shareReportOpen"
        :entity="t('report')"
        :endpoint="shareEndpoint"
    />

    <ManageEventModal
        v-model="createEventModalOpen"
        :report-ended="report?.endedAt !== null"
        @close="createEventModalClosed"
    />

    <ManageEventModal
        v-model="updateEventModalOpen"
        :report-event="selectedEvent"
        :report-ended="report?.endedAt !== null"
        @close="updateEventModalClosed"
    />

    <StopReportModal
        v-if="report && stopReportModalOpen"
        v-model="stopReportModalOpen"
        :report-id="report.id"
        @close="stopReportModalClosed"
    />
</template>

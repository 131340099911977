<script lang="ts" setup>
import { debouncedWatch } from '@vueuse/shared'
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { PODPreferences, PodStartScreen, PodFieldPreferences } from '@/types/pod'
import { FieldPreference } from '@/types/general'
import useForm from '@/hooks/use-form'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import Loader from '@/components/loaders/Loader.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import DriverReportPreferences from '@/components/settings/DriverReportPreferences.vue'
import DamageReportPreferences from '@/components/settings/DamageReportPreferences.vue'
import PackagingPreferences from '@/components/settings/PackagingPreferences.vue'
import DeliveryManagementPreferences from '@/components/settings/DeliveryManagementPreferences.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyButton from '@/components/my-components/MyButton.vue'

let watcherStopped = true

const defaultFields: Record<keyof PodFieldPreferences, FieldPreference> = {
    receiver: FieldPreference.Optional,
    consignmentNote: FieldPreference.Optional,
    shipmentNumber: FieldPreference.Optional,
    trailerNumber: FieldPreference.Optional,
    truckNumber: FieldPreference.Optional,
    signature: FieldPreference.Optional,
    images: FieldPreference.Optional,
}

const { t } = useI18n()
const authStore = useAuthStore()
const podForm = useForm<PODPreferences>({
    dispatches: {
        startScreen: PodStartScreen.details,
        fields: { ...defaultFields },
        haulierHidden: false,
    },
    deliveries: {
        startScreen: PodStartScreen.details,
        fields: { ...defaultFields },
        haulierHidden: false,
    },
    pdfGenerationEmail: null,
})

const pdfGenerationEmail = ref('')

async function savePODPreferences() {
    if (watcherStopped) return
    if (!authStore.hasLicense(LicenseType.PODModule)) return

    const response = await podForm.submit(
        'PUT',
        window.route('pod.company.preferences.update', { company: authStore.companyId }),
    )
    if (response == null) return

    authStore.licenses[LicenseType.PODModule] = {
        ...authStore.licenses[LicenseType.PODModule],
        ...podForm.data,
    }
}

function saveEmail() {
    podForm.data.pdfGenerationEmail = pdfGenerationEmail.value
}

debouncedWatch(() => podForm.data, savePODPreferences, { debounce: 250, deep: true })

onBeforeMount(() => {
    const preferences = (authStore.licenses[LicenseType.PODModule] ?? {}) as Partial<PODPreferences>
    podForm.data.deliveries = {
        ...podForm.data.deliveries,
        ...(preferences.deliveries || {}),
        fields: { ...defaultFields, ...preferences.deliveries?.fields },
    }
    podForm.data.dispatches = {
        ...podForm.data.dispatches,
        ...(preferences.dispatches || {}),
        fields: { ...defaultFields, ...preferences.dispatches?.fields },
    }
    podForm.data.pdfGenerationEmail = preferences.pdfGenerationEmail || null
    pdfGenerationEmail.value = preferences.pdfGenerationEmail || ''

    setTimeout(() => {
        watcherStopped = false
    }, 400)
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('licenses')" />
    </CrumbsAndActions>

    <div class="flex flex-wrap justify-center md:space-x-8 lg:justify-start">
        <div v-if="authStore.hasLicense(LicenseType.PODModule)" class="mb-6">
            <h1 class="mb-2 text-3xl font-bold uppercase text-primary-400" v-text="t('pod')" />
            <MyForm :errors="podForm.errors.value" @submit.prevent="saveEmail">
                <MyTabs
                    v-slot="{ tabKey }"
                    :tabs="{ dispatches: t('dispatches'), deliveries: t('deliveries') }"
                    :default-tab="'dispatches'"
                    shadow
                    small
                >
                    <MyPanel padded>
                        <Loader
                            class="absolute right-6 top-6 opacity-0 transition-opacity duration-300"
                            :class="{ 'opacity-100': podForm.loading.value }"
                        />

                        <div v-if="tabKey === 'dispatches'" class="flex flex-col space-y-3">
                            <h3
                                class="relative text-sm font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300"
                                v-text="t('startScreen')"
                            />

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.startScreen"
                                class="text-center text-sm"
                            >
                                <MyRadioButton
                                    :label="t('receiving', { property: 'company' })"
                                    :value="PodStartScreen.receiver"
                                />
                                <MyRadioButton
                                    :label="t('consignmentNote')"
                                    :value="PodStartScreen.consignmentNote"
                                />
                                <MyRadioButton
                                    :label="t('details')"
                                    :value="PodStartScreen.details"
                                    class="leading-10"
                                />
                            </MyRadioButtonGroup>

                            <hr class="mb-2" />

                            <h3
                                class="relative text-sm font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300"
                                v-text="t('fieldsForDispatch')"
                            />

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.receiver"
                                :label="t('receiving', { property: 'company' })"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.consignmentNote"
                                :label="t('consignmentNote')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.shipmentNumber"
                                :label="t('shipmentNumber')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.trailerNumber"
                                :label="t('trailerNumber')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.truckNumber"
                                :label="t('truckNumber')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.signature"
                                class="mb-2"
                                :label="t('signature')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.fields.images"
                                class="mb-2"
                                :label="t('images')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <hr class="mb-2" />

                            <MyRadioButtonGroup
                                v-model="podForm.data.dispatches.haulierHidden"
                                class="mb-2"
                                :label="t('showHaulierOnReceipt')"
                            >
                                <MyRadioButton :label="t('shown')" :value="false" />

                                <MyRadioButton :label="t('hidden')" :value="true" />
                            </MyRadioButtonGroup>
                        </div>
                        <div v-if="tabKey === 'deliveries'" class="flex flex-col space-y-3">
                            <h3
                                class="relative text-sm font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300"
                                v-text="t('startScreen')"
                            />

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.startScreen"
                                class="text-center text-sm"
                            >
                                <MyRadioButton
                                    :label="t('receiving', { property: 'company' })"
                                    :value="PodStartScreen.receiver"
                                />
                                <MyRadioButton
                                    :label="t('consignmentNote')"
                                    :value="PodStartScreen.consignmentNote"
                                />
                                <MyRadioButton
                                    class="leading-10"
                                    :label="t('details')"
                                    :value="PodStartScreen.details"
                                />
                            </MyRadioButtonGroup>

                            <hr class="mb-2" />

                            <h3
                                class="relative text-sm font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300"
                                v-text="t('fieldsForDelivery')"
                            />

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.receiver"
                                :label="t('receiving', { property: 'company' })"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.consignmentNote"
                                :label="t('consignmentNote')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.shipmentNumber"
                                :label="t('shipmentNumber')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.trailerNumber"
                                :label="t('trailerNumber')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.truckNumber"
                                :label="t('truckNumber')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.signature"
                                :label="t('signature')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.fields.images"
                                :label="t('images')"
                            >
                                <MyRadioButton
                                    :label="t('required')"
                                    :value="FieldPreference.Required"
                                />
                                <MyRadioButton
                                    :label="t('optional')"
                                    :value="FieldPreference.Optional"
                                />
                                <MyRadioButton
                                    :label="t('hidden')"
                                    :value="FieldPreference.Hidden"
                                />
                            </MyRadioButtonGroup>

                            <hr class="mb-2" />

                            <MyRadioButtonGroup
                                v-model="podForm.data.deliveries.haulierHidden"
                                class="mb-2"
                                :label="t('showHaulierOnReceipt')"
                            >
                                <MyRadioButton :label="t('shown')" :value="false" />

                                <MyRadioButton :label="t('hidden')" :value="true" />
                            </MyRadioButtonGroup>
                        </div>
                        <hr class="mb-2" />
                        <div v-if="authStore.hasLicense(LicenseType.PodPdfGeneration)" class="mb-6">
                            <div class="flex items-center space-x-2 align-middle">
                                <MyInput
                                    v-model="pdfGenerationEmail"
                                    :label="t('pdfGenerationEmail')"
                                    :placeholder="t('email')"
                                    type="email"
                                    name="pdfGenerationEmail"
                                />
                                <MyButton
                                    scheme="primary"
                                    class="mt-auto flex-shrink-0"
                                    @click="saveEmail"
                                    v-text="t('saveEmail')"
                                />
                            </div>
                        </div>
                    </MyPanel>
                </MyTabs>
            </MyForm>
        </div>
        <div v-if="authStore.hasLicense(LicenseType.PackagingModule)" class="mb-6">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('packaging')"
            />

            <PackagingPreferences />
        </div>

        <div v-if="authStore.hasLicense(LicenseType.DriverReportModule)" class="mb-6">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('driverReports')"
            />

            <DriverReportPreferences />
        </div>

        <div v-if="authStore.hasLicense(LicenseType.DamageReportModule)" class="mb-6">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('damageReports')"
            />

            <DamageReportPreferences />
        </div>

        <div v-if="authStore.hasLicense(LicenseType.DeliveryManagementModule)" class="mb-6">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('deliveryManagement')"
            />

            <DeliveryManagementPreferences />
        </div>
    </div>
</template>

<script lang="ts">
interface Props {
    modelValue: string
    variables: InputVariable[]
    label?: string
    id?: string
    name?: string
    error?: string
    helper?: string
}
</script>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { TargetInputEvent } from '@/types/general'
import { InputVariable } from '@/types/inputs'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyHelperMessage from '@/components/my-components/form/MyHelperMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

let cachedValue = ''

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void
    (e: 'focus', event: FocusEvent): void
    (e: 'blur', event: FocusEvent): void
}>()

const { t } = useI18n()

const element = ref<HTMLDivElement>()
const formattedContent = ref(props.modelValue)
const id = ref(props.id ?? uuid())

function updateContent(e: TargetInputEvent) {
    cachedValue = e.target.innerHTML
    emit('update:modelValue', e.target.innerHTML)
}

async function insertVariable(variable: string) {
    if (document.activeElement !== element.value) {
        element.value?.focus()
        const sel = window.getSelection()
        sel?.selectAllChildren(element.value!)
        sel?.collapseToEnd()
    }

    let sel
    if (!window.getSelection || !(sel = window.getSelection())?.rangeCount) return

    const range = sel.getRangeAt(0)
    range.collapse(true)
    const span = document.createElement('span')
    span.classList.add('email-variable')
    span.contentEditable = 'false'
    span.appendChild(document.createTextNode(variable))
    // This inserts a space after the variable to make sure the cursor can be set at the end of the line
    range.insertNode(document.createTextNode(' '))
    range.insertNode(span)

    // Move the caret immediately after the inserted span
    range.setStartAfter(span)
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
}

watch(
    () => props.id,
    () => {
        id.value = props.id ?? uuid()
    },
)

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue !== cachedValue) {
            formattedContent.value = props.modelValue
            cachedValue = props.modelValue
        }
    },
)
</script>

<template>
    <div class="w-full">
        <MyInputLabel v-if="props.label" :for="id" v-text="props.label" />

        <p
            ref="element"
            class="min-h-[200px] w-full max-w-full resize-y overflow-y-auto whitespace-pre-wrap rounded-xl border border-gray-300 bg-white px-4 py-2.5 text-sm outline-none ring-0 transition-shadow placeholder:text-xs placeholder:font-semibold placeholder:uppercase placeholder:text-gray-400 focus:border-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border disabled:bg-primary-50 dark:border-transparent dark:bg-dark-400 dark:focus:border-transparent dark:focus:shadow-dark-600 dark:disabled:bg-dark-600 sm:text-sm"
            contenteditable
            @input="updateContent($event as TargetInputEvent)"
            @blur="emit('blur', $event)"
            @focus="emit('focus', $event)"
            v-html="formattedContent"
        />

        <MyHelperMessage :helper="props.helper" />
        <MyErrorMessage :input-name="props.name" :error="props.error" :label="props.label" />

        <div class="mt-3">
            <div class="mb-1 flex flex-wrap items-center">
                <p class="mr-1 text-sm font-semibold" v-text="t('variables')" />
                <mdi:information v-tooltip="t('emailVariableExplanation')" />
            </div>

            <button
                v-for="variable in props.variables"
                :key="variable.value"
                v-tooltip="variable.description"
                type="button"
                class="mx-1 mb-2 cursor-pointer whitespace-nowrap rounded bg-blue-200 py-1 px-2 text-sm text-blue-500"
                @mousedown.prevent="insertVariable(variable.value)"
                v-text="variable.value"
            />
        </div>
    </div>
</template>

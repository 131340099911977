<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import isEqual from 'lodash/isEqual'
import { useRoute, useRouter } from 'vue-router'

import { image as imageHelper } from '@/utils/assets'
import useForm from '@/hooks/use-form'
import { CanvasDrawingRef, CustomerLocation, FabricDrawing, LocationPoint } from '@/types/company'
import { useAuthStore } from '@/stores/auth-store'
import { FileAttachment, ResourceResponse, uuid } from '@/types/general'
import { DeliveryDescription } from '@/types/delivery-management'
import { useFileUploader } from '@/hooks/use-file-uploader'
import { Address } from '@/types/form'
import { addressString } from '@/utils/string'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyAddressInput from '@/components/my-components/form/MyAddressInput.vue'
import DeliveryDescriptionLocationModal from '@/components/customers/DeliveryDescriptionLocationModal.vue'
import CustomerLocationCard from '@/components/customers/CustomerLocationCard.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

const router = useRouter()
const route = useRoute()

const CanvasDrawing = defineAsyncComponent(() => import('@/components/customers/CanvasDrawing.vue'))

export interface Props {
    deliveryDescriptionId: uuid | undefined
    modelValue: boolean
}

interface Form {
    dropNumber: string | null
    fields: Record<string, string | null>
    images: FileAttachment[]
    note: string
    drawingData: string | null
    drawingPath: string | null
    address: string | null
    location: LocationPoint | null
    locations: CustomerLocation[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'save', formData: Form): void
    (e: 'close', value: boolean): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()

const defaultFields = {
    entrance: null,
    alarm: null,
    tourNumber: null,
    timeDemands: null,
    keyBox: null,
    itemLocation: null,
    palletLocation: null,
    returnItemLocation: null,
    note: null,
    address: null,
}

const { data, loading, errors, reset, submit } = useForm<Form>({
    dropNumber: null,
    fields: { ...defaultFields },
    images: [],
    note: '',
    drawingData: null,
    drawingPath: null,
    address: null,
    location: null,
    locations: [],
})

const addressInput = ref('')
const canvasKey = ref(0)

const { filesUploading, uploadFiles } = useFileUploader({
    addFile(file) {
        file.note = data.note
        data.images.push(file)
    },
    updateFile(imageId, path) {
        data.images = data.images.map((image) => {
            if (image.id === imageId) {
                return { ...image, path, note: data.note }
            }
            return image
        })
    },
})

const fetchedDrawingData = ref<FabricDrawing | null>(null)
const canvasDrawingRef = ref<CanvasDrawingRef>()
const fetchingDeliveryDescription = ref(false)
const replaceDrawingPath = ref(false)

const locationModalOpen = ref(false)

const shouldRenderDrawingPath = computed(() => {
    if (replaceDrawingPath.value) return false
    return data.drawingPath !== null && fetchedDrawingData.value === null
})

function removeFile(image: FileAttachment) {
    data.images = data.images.filter((i) => i !== image)
}

async function fetchExistingData() {
    if (!props.deliveryDescriptionId) return

    try {
        fetchingDeliveryDescription.value = true
        const response = await axios.get<ResourceResponse<DeliveryDescription>>(
            window.route('company.delivery-descriptions.show', {
                company: authStore.companyId,
                delivery_description: props.deliveryDescriptionId,
            }),
        )

        if (response.data) {
            fetchedDrawingData.value = response.data.data.drawingData
            const existingData = response.data.data.fields

            reset({
                dropNumber: response.data.data.dropNumber,
                fields: { ...defaultFields, ...existingData },
                drawingPath: response.data.data.drawingPath,
                images: response.data.data.images,
                address: response.data.data.address,
                location: response.data.data.location,
                locations: response.data.data.locations,
            })

            if (data.address) {
                addressInput.value = data.address
            } else {
                if (data.locations.length > 0) {
                    data.address = addressString(data.locations[0])
                    data.location = data.locations[0].location
                    addressInput.value = addressString(data.locations[0])
                }
            }
        }
    } catch (e) {
        if (!axios.isAxiosError(e) || e.response?.status != 404) {
            throw e
        }
    } finally {
        fetchingDeliveryDescription.value = false
    }
}

async function onSubmit() {
    loading.value = true
    if (canvasDrawingRef.value) {
        const drawingRef = canvasDrawingRef.value.saveCanvasJSON()

        data.drawingData = JSON.stringify(drawingRef) ?? null

        if (!isEqual(fetchedDrawingData.value, drawingRef)) {
            data.drawingPath = (await canvasDrawingRef.value.saveCanvasToCloudFlare()) ?? null
        }
    }

    const response = props.deliveryDescriptionId ? await update() : await store()

    if (response) {
        emit('save', data)
    } else {
        notify({ type: 'error', text: t('formError') })
    }
}

async function store() {
    return await submit(
        'POST',
        window.route('company.delivery-descriptions.store', {
            company: authStore.companyId,
        }),
    )
}

async function update() {
    return await submit(
        'PUT',
        window.route('company.delivery-descriptions.update', {
            company: authStore.companyId,
            delivery_description: props.deliveryDescriptionId,
        }),
    )
}

function addressChanged(address: Address) {
    data.address = addressString(address)
    data.location = { latitude: address.latitude, longitude: address.longitude }
    data.drawingPath = null
    data.drawingData = null
    fetchedDrawingData.value = null
    canvasKey.value++
}

function removeLocation(location: CustomerLocation) {
    data.locations = data.locations.filter((l) => l.id !== location.id)
    clearErrors()
}

function locationSelected(location: CustomerLocation) {
    data.locations.push(location)
    clearErrors()
}

function clearErrors() {
    errors.value = {}
}

function close() {
    emit('close', false)
}

onMounted(() => {
    fetchExistingData()
})

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue) {
            fetchExistingData()
        } else {
            reset({ fields: { ...defaultFields }, drawingData: null, drawingPath: null })
            fetchedDrawingData.value = null
            replaceDrawingPath.value = false
            addressInput.value = ''
            router.replace({ query: { ...route.query, tab: undefined } })
            clearErrors()
        }
    },
)
</script>

<template>
    <MyModal :max-width="1000" :value="props.modelValue" @close="close">
        <template #title>{{ t('deliveryDescription') }}</template>

        <LoaderWrapper :visible="loading || fetchingDeliveryDescription" />

        <MyForm class="mt-4 space-y-4" :errors="errors" @submit.prevent>
            <div class="flex space-x-4">
                <MyInput
                    v-model="data.dropNumber"
                    name="dropNumber"
                    autofocus
                    :label="t('dropNumber')"
                    :placeholder="t('dropNumber')"
                />

                <MyAddressInput
                    v-model="addressInput"
                    name="address"
                    :label="t('address')"
                    @change="addressChanged"
                />
            </div>

            <div>
                <MyInputLabel class="mb-2">{{ t('customerLocations') }}</MyInputLabel>

                <div class="grid grid-cols-2 gap-3">
                    <CustomerLocationCard
                        v-for="(location, index) in data.locations"
                        :key="location.id"
                        :location="location"
                        :error="
                            errors && errors[`locations.${index}.id`]
                                ? errors[`locations.${index}.id`][0]
                                : null
                        "
                    >
                        <template #action>
                            <MyButton
                                scheme="danger"
                                :icon="true"
                                type="button"
                                size="small"
                                @click="removeLocation(location)"
                            >
                                <mdi:trash-can />
                            </MyButton>
                        </template>
                    </CustomerLocationCard>
                </div>

                <MyButton
                    :class="{ 'mt-4': data.locations.length > 0 }"
                    scheme="primary"
                    size="small"
                    @click="locationModalOpen = true"
                >
                    {{ t('addCustomerLocation') }}
                </MyButton>
            </div>

            <div class="flex w-full space-x-4 align-top">
                <div class="grid grid-cols-2 gap-4 w-full">
                    <MyTextarea
                        v-model="data.fields.entrance"
                        name="fields.entrance"
                        :label="t('entrance')"
                        :placeholder="t('entrance')"
                    />
                    <MyTextarea
                        v-model="data.fields.itemLocation"
                        name="fields.itemLocation"
                        :label="t('itemLocation')"
                        :placeholder="t('itemLocation')"
                    />
                    <MyTextarea
                        v-model="data.fields.palletLocation"
                        name="fields.palletLocation"
                        :label="t('palletLocation')"
                        :placeholder="t('palletLocation')"
                    />
                    <MyTextarea
                        v-model="data.fields.returnItemLocation"
                        name="fields.returnItemLocation"
                        :label="t('returnItemLocation')"
                        :placeholder="t('returnItemLocation')"
                    />

                    <MyTextarea
                        v-model="data.fields.alarm"
                        name="fields.alarm"
                        :label="t('alarm')"
                        :placeholder="t('alarm')"
                    />
                    <MyTextarea
                        v-model="data.fields.timeDemands"
                        name="fields.timeDemands"
                        :label="t('timeDemands')"
                        :placeholder="t('timeDemands')"
                    />
                    <MyTextarea
                        v-model="data.fields.keyBox"
                        name="fields.keyBox"
                        :label="t('keyBox')"
                        :placeholder="t('keyBox')"
                    />
                    <MyTextarea
                        v-model="data.fields.tourNumber"
                        name="fields.tourNumber"
                        :label="t('tourNumber')"
                        :placeholder="t('tourNumber')"
                    />

                    <MyTextarea
                        v-model="data.fields.note"
                        name="fields.note"
                        group-class="col-span-2"
                        :label="t('note')"
                        :placeholder="t('note')"
                    />
                </div>

                <!-- Fourth Column for File Select and Canvas Drawing -->
                <div class="flex w-1/2 flex-col pl-4">
                    <div v-if="shouldRenderDrawingPath">
                        <img
                            :src="imageHelper(data.drawingPath!)"
                            class="w-[400px] h-[400px] max-w-sm rounded-t-lg object-contain"
                        />
                        <MyButton
                            class="gap-4 mt-4"
                            type="button"
                            scheme="primary"
                            @click="replaceDrawingPath = true"
                        >
                            {{ t('replaceWithNewDrawing') }}
                        </MyButton>
                    </div>
                    <CanvasDrawing
                        v-else-if="!fetchingDeliveryDescription"
                        :key="canvasKey"
                        ref="canvasDrawingRef"
                        :drawing-data="fetchedDrawingData"
                        :drawing-path="data.drawingPath"
                        :location="data.location"
                        class="w-full h-full"
                    />
                </div>
            </div>

            <MyInputLabel>{{ t('images') }}</MyInputLabel>

            <div class="grid grid-cols-4 gap-3">
                <div v-for="file in data.images" :key="file.id" class="flex flex-col">
                    <MyFileAttachment
                        :file="file"
                        removable
                        :uploading="filesUploading.includes(file.id)"
                        @on-remove-clicked="removeFile(file)"
                    />

                    <div class="mt-2">
                        <MyTextarea
                            v-model="file.note"
                            :name="`file.note.${file.id}`"
                            :label="t('note')"
                            :placeholder="t('note')"
                        ></MyTextarea>
                    </div>
                </div>

                <MyFileSelect
                    type="image/*,application/pdf"
                    multiple
                    class="min-h-[200px] px-2 rounded-xl"
                    :text="t('selectImages')"
                    @selected:multiple="uploadFiles"
                >
                    <p class="text-xs font-semibold dark:text-gray-300" v-text="t('dragAndDrop')" />
                    <p class="text-sm font-semibold dark:text-gray-300" v-text="t('image')" />
                </MyFileSelect>
            </div>

            <!-- Submit Button -->
            <div class="flex justify-end pt-2">
                <MyButton
                    :disabled="loading || fetchingDeliveryDescription || filesUploading.length > 0"
                    scheme="primary"
                    @click="onSubmit"
                >
                    {{ t('save') }}
                </MyButton>
            </div>
        </MyForm>
    </MyModal>

    <DeliveryDescriptionLocationModal
        v-model="locationModalOpen"
        :selected-locations="data.locations"
        @location-selected="locationSelected($event)"
    />
</template>

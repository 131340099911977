<script lang="ts" setup>
import axios from 'axios'
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { EmailTemplate, EmailTemplateType } from '@/types/emails'
import { ResourceResponse } from '@/types/general'
import { getPackagingVariables } from '@/utils/email-templates'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyVariableInput from '@/components/my-components/form/MyVariableInput.vue'

interface Form {
    type: EmailTemplateType
    subject: string
    body: string
}

const { t } = useI18n()
const authStore = useAuthStore()

const packagingVariables = getPackagingVariables(t)

const loading = ref(false)
const templates = ref<Record<EmailTemplateType, EmailTemplate>>(window.defaultEmailTemplates)
const selectedTemplate = ref<EmailTemplate>(templates.value[0])
const form = useForm<Form>({
    type: EmailTemplateType.PackagingStatement,
    subject: 'f',
    body: '',
})

function selectTemplate(template: EmailTemplate) {
    selectedTemplate.value = template
    form.data.body = template.body
    form.data.subject = template.subject
}

async function fetchTemplates() {
    loading.value = true
    const response = await axios.get<ResourceResponse<EmailTemplate[]>>(
        window.route('company.email-templates.index', { company: authStore.companyId }),
    )

    for (const template of response.data.data) {
        templates.value[template.type] = template
    }
    loading.value = false
    selectTemplate(templates.value[0])
}

async function saveTemplate() {
    loading.value = true

    await form.submit(
        'PUT',
        window.route('company.email-templates.update', { company: authStore.companyId }),
    )

    loading.value = false
}

onBeforeMount(() => fetchTemplates())
</script>

<template>
    <div class="mx-auto w-full max-w-4xl">
        <h1 class="text-xl font-bold" v-text="t('emailTemplates')" />

        <div class="relative mt-5 flex min-h-[400px] w-full">
            <LoaderWrapper :visible="loading" immediate />

            <aside class="h-full w-full max-w-xs flex-shrink-0">
                <div
                    v-for="template in templates"
                    :key="template.type"
                    class="w-full cursor-pointer rounded-lg p-5"
                    :class="{
                        'bg-gray-200': selectedTemplate?.type !== template.type,
                        'bg-primary-500 text-white': selectedTemplate?.type === template.type,
                    }"
                    @click="selectTemplate(template)"
                >
                    <template v-if="template.type === EmailTemplateType.PackagingStatement">
                        {{ t('packagingStatement') }}
                    </template>
                </div>

                <div
                    v-if="Object.keys(templates).length === 0"
                    class="flex h-full w-full items-center justify-center"
                    v-text="t('noTemplatesFound')"
                />
            </aside>

            <MyForm
                v-if="selectedTemplate"
                :errors="form.errors.value"
                class="ml-5 w-full"
                @submit.prevent="saveTemplate"
            >
                <h1 class="text-lg font-semibold" v-text="t('packagingStatement')" />

                <MyVariableInput
                    v-model="form.data.body"
                    name="body"
                    :label="t('emailBody')"
                    :variables="packagingVariables"
                />

                <div class="flex justify-end">
                    <MyButton scheme="primary" v-text="t('save')" />
                </div>
            </MyForm>

            <div v-else class="my-0 flex w-full items-center justify-center self-stretch">
                {{ t('selectAnEmailTemplate') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const { t } = useI18n()

export interface Props {
    modelValue: string[]
    label?: string
    required?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'update:modelValue', value: string[]): void
}>()

const errorMessage = ref('')

const mails = ref(props.modelValue.length ? props.modelValue : [''])

function validate(mail: string) {
    if (mail?.trim() === '') return false

    return true
}

function removeMail(index: number) {
    if (props.modelValue.length == 1) {
        errorMessage.value = t('emailRequired')
        return
    }
    errorMessage.value = ''
    mails.value.splice(index, 1)

    emit('update:modelValue', mails.value)
}

function handleKeyUp(id: number, e: KeyboardEvent) {
    emit('update:modelValue', mails.value)
}

function handleNewMail() {
    if (!validate(mails.value[mails.value.length - 1])) {
        errorMessage.value = t('invalidEmail')
        return
    }

    mails.value.push('')
    errorMessage.value = ''
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue !== mails.value) {
            mails.value = props.modelValue
        }
    },
)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <div class="flex flex-col w-full space-y-3">
        <div v-for="(_, index) in mails" :key="index" class="flex flex-col">
            <div class="flex items-center space-x-3">
                <MyInput
                    v-model="mails[index]"
                    type="email"
                    class="w-full"
                    group-class="grow"
                    autocomplete="email"
                    :name="`emails.${index}`"
                    :label="props.label ?? t('email')"
                    :placeholder="t('email')"
                    :required="required || index > 0"
                    @keyup="handleKeyUp(index, $event)"
                />

                <MyButton
                    v-if="index > 0"
                    v-tooltip="t('removeEmail')"
                    type="button"
                    class="dark:text-primary-50"
                    size="small"
                    pill
                    icon
                    @click.prevent="removeMail(index)"
                >
                    <mdi:trash-can class="h-5 w-5 mt-6" />
                </MyButton>
            </div>
        </div>

        <MyErrorMessage :error="errorMessage" />

        <MyButton
            plain
            scheme="primary"
            type="button"
            class="self-end"
            size="small"
            @click="handleNewMail"
        >
            <mdi:plus />
            {{ t('addEmail') }}
        </MyButton>
    </div>
</template>

<script lang="ts">
interface Props {
    events?: FormTourEvent[]
    tourId?: uuid
    isolated?: boolean
    tourShipperCompanyId?: uuid | null
    tourCompanyId?: uuid
    isHubHaulier?: boolean
}
</script>
<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import Datepicker from '@vuepic/vue-datepicker'
import { useI18n } from 'vue-i18n'
import Draggable from 'vuedraggable'
import { debouncedWatch } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { v4 as uuid4 } from 'uuid'
import dayjs from 'dayjs'

import { PaginatedResponse, uuid } from '@/types/general'
import { addressString } from '@/utils/string'
import { datetime } from '@/utils/dates'
import { type PaginationData } from '@/types/general'
import {
    EventType,
    FormTourEvent,
    MinimalShipment,
    ShipmentType,
} from '@/types/delivery-management'
import { useAuthStore } from '@/stores/auth-store'
import { LocationPoint } from '@/types/company'
import { shipmentTypes } from '@/utils/delivery-management'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import ShipmentModal from '@/components/delivery-management/ShipmentModal.vue'
import TableFooter from '@/components/table/internal/TableFooter.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'eventsChanged', value: FormTourEvent[]): void
    (e: 'shipmentSelected', value: MinimalShipment): void
    (e: 'update:open', value: boolean): void
}>()

const { t } = useI18n()
const route = useRoute()
const authStore = useAuthStore()
const presetRanges = usePresetRangeList()

const paginationData = ref<PaginationData>({
    from: 1,
    to: 1,
    perPage: 5,
    lastPage: 1,
    currentPage: Number(route.query.page ?? 1),
    total: 0,
})
const events = ref(props.events || [])
const shipments = ref<MinimalShipment[]>([])
const searchQuery = ref('')
const loading = ref(false)
const selectedShipmentId = ref<string>()
const shipmentModalOpen = ref(false)
const date = ref<Date[]>([
    dayjs(dayjs().subtract(6, 'days').startOf('day').toDate().toISOString()).toDate(),
    dayjs(dayjs().endOf('day').toDate().toISOString()).toDate(),
])

const tourEventTypes = computed<Record<EventType, string>>(() => {
    return {
        [EventType.Shipment]: t('shipment'),
        [EventType.Bridge]: t('bridge'),
        [EventType.Ferry]: t('ferry'),
    }
})

const pickedShipmentIds = computed(() => {
    return events.value.map((event) => event.shipment?.id)
})

function tourEventType(tourEvent: FormTourEvent) {
    if (tourEvent.type !== EventType.Shipment) return tourEventTypes.value[tourEvent.type]

    return t(shipmentTypes[tourEvent.shipment!.type])
}

function getShipmentStartLocation(shipment: MinimalShipment): LocationPoint {
    if (shipment.addressLocation) {
        return shipment.addressLocation
    } else if (shipment.type === ShipmentType.Pickup) {
        return shipment.sendingLocation.location
    } else {
        return shipment.receivingLocation.location
    }
}

async function fetchAvailableShipments(page?: number) {
    loading.value = true
    let filter = {}

    if (props.isolated) {
        filter = { 'tour-id': props.tourId, 'has-shipper': true }
    } else {
        filter = { 'for-tour-or-without': props.tourId ?? 'none' }
    }

    const params: AxiosRequestConfig['params'] = {
        filter: {
            ...filter,
            'start-date': dayjs(date.value[0]).startOf('day').toISOString(),
            'end-date': dayjs(date.value[1]).endOf('day').toISOString(),
        },
        query: searchQuery.value,
        page: page ?? paginationData.value.currentPage,
        shipperCompanyId: props.tourShipperCompanyId,
        'per-page': 5,
        simple: true,
    }
    const response = await axios.get<PaginatedResponse<MinimalShipment>>(
        window.route('dm.company.shipments.index', {
            company: props.tourCompanyId ?? authStore.companyId,
        }),
        { params: params },
    )

    shipments.value = response.data.data

    paginationData.value = {
        from: response.data.meta.from,
        to: response.data.meta.to,
        perPage: response.data.meta.per_page,
        lastPage: response.data.meta.last_page,
        currentPage: response.data.meta.current_page,
        total: response.data.meta.total,
    }

    loading.value = false
}

async function selectShipment(shipment: MinimalShipment) {
    if (props.isolated) {
        emit('shipmentSelected', shipment)
    } else {
        events.value.push({
            id: uuid4(),
            shipment: shipment,
            type: EventType.Shipment,
            order: events.value.length,
            startLocation: getShipmentStartLocation(shipment),
        })
        emit('eventsChanged', [...events.value])
    }
}

async function removeTourEvent(event: FormTourEvent) {
    events.value = events.value.filter((e) => e.id !== event.id)
    emit('eventsChanged', [...events.value])
}

function saveOrder() {
    events.value = events.value.map((event, index) => ({ ...event, order: index }))
    emit('eventsChanged', [...events.value])
}

function showShipment(shipmentId: string) {
    selectedShipmentId.value = shipmentId
    shipmentModalOpen.value = true
}

function getShipmentDestinationName(shipment: MinimalShipment): string {
    if (shipment.type === ShipmentType.Pickup) {
        return shipment.sendingCompany.name
    }

    return shipment.receivingCompany.name
}

function getShipmentAddress(shipment: MinimalShipment): string {
    if (shipment.address) return shipment.address

    if (shipment.type === ShipmentType.Pickup) {
        return addressString(shipment.sendingLocation)
    }

    return addressString(shipment.receivingCompany)
}

debouncedWatch(
    () => searchQuery.value,
    (value) => fetchAvailableShipments(),
    { debounce: 500 },
)

watch(
    () => date.value,
    () => fetchAvailableShipments(),
    { deep: true },
)

watch(
    () => props.events,
    (value) => {
        events.value = value || []
    },
)

watch(
    () => props.tourCompanyId,
    () => fetchAvailableShipments(),
)

onBeforeMount(() => fetchAvailableShipments())
</script>

<template>
    <div class="flex min-h-full w-full flex-row">
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <div v-if="!props.isolated" class="flex w-full flex-col mr-6">
            <span class="dark:text-primary-50 mb-4">{{ t('currentShipments') }} </span>
            <Draggable
                v-model="events"
                ghost-class="drag-ghost-card"
                item-key="id"
                handle=".handle"
                @end="saveOrder"
            >
                <template #item="{ index }">
                    <div class="my-4 flex flex-col justify-start space-y-3">
                        <div class="flex">
                            <span class="mr-3 text-xs">{{ index + 1 }} </span>

                            <div
                                class="flex w-full items-center justify-between space-x-3 rounded-xl bg-white p-3 shadow-md dark:bg-dark-400"
                            >
                                <div class="flex space-x-3">
                                    <mdi:drag
                                        class="handle cursor-grab self-center text-xl text-dark-700 transition dark:text-white"
                                    />
                                    <div
                                        v-if="events[index].shipment"
                                        class="flex w-2/5 flex-shrink-0 flex-col"
                                    >
                                        <span class="text-sm dark:text-primary-50 font-semibold">
                                            {{ t('shipment') }}
                                        </span>
                                        <div class="flex">
                                            <span
                                                class="text-sm dark:text-primary-50 mr-1 break-all"
                                            >
                                                {{
                                                    events[index].shipment?.shipmentNumber ??
                                                    events[index].shipment?.name ??
                                                    '-'
                                                }}
                                            </span>
                                            <mdi:eye
                                                v-if="events[index].shipment"
                                                class="ml-1 cursor-pointer hover:text-primary-400 dark:hover:text-primary-300 min-h-5 min-w-5"
                                                @click="showShipment(events[index].shipment!.id)"
                                            />
                                        </div>

                                        <span
                                            class="mr-2 text-sm dark:text-primary-50"
                                            v-text="tourEventType(events[index])"
                                        />
                                        <span class="text-xs">
                                            {{ datetime(events[index].shipment!.plannedAt) }}
                                        </span>
                                    </div>
                                    <div class="flex w-2/5 flex-col">
                                        <span class="text-sm dark:text-primary-50 font-semibold">
                                            {{
                                                events[index].shipment!.type === ShipmentType.Pickup
                                                    ? t('sender')
                                                    : t('receiver')
                                            }}
                                        </span>
                                        <span
                                            v-truncate-tooltip="
                                                getShipmentDestinationName(events[index].shipment!)
                                            "
                                            class="text-sm dark:text-primary-50 truncate"
                                            v-text="
                                                getShipmentDestinationName(events[index].shipment!)
                                            "
                                        />

                                        <div
                                            class="text-xs dark:text-primary-200 break-words"
                                            v-text="getShipmentAddress(events[index].shipment!)"
                                        />
                                    </div>
                                </div>

                                <div class="flex flex-shrink-0 items-center justify-end">
                                    <MyButton
                                        scheme="primary"
                                        type="button"
                                        size="small"
                                        @click="removeTourEvent(events[index])"
                                    >
                                        {{ t('remove') }}
                                    </MyButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Draggable>
            <div v-if="events.length <= 0" class="m-4 flex justify-center">
                <span class="dark:text-primary-300">{{ t('noCurrentShipments') }}</span>
            </div>
        </div>

        <div class="flex w-full flex-col">
            <div class="flex items-center space-x-1 mb-4">
                <span
                    v-if="props.isolated"
                    class="dark:text-primary-50 cursor-pointer"
                    @click="emit('update:open', false)"
                >
                    <mdi:chevron-left class="w-5 h-5" />
                </span>
                <span class="dark:text-primary-50">{{ t('availableShipments') }}</span>
            </div>
            <div
                class="flex w-full flex-col justify-start px-4 rounded-xl border border-gray-300 dark:border-dark-500"
            >
                <div class="w-full flex self-center mt-3 space-x-3">
                    <MyInput
                        v-model="searchQuery"
                        :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                        :placeholder="t('search')"
                        class="h-10 w-full border-r-0 border-l-0 pt-1.5 pb-1.5"
                        type="search"
                    >
                        <template #icon>
                            <mdi:magnify />
                        </template>
                    </MyInput>
                    <Datepicker
                        v-model="date"
                        input-class-name="w-64 h-10"
                        range
                        :enable-time-picker="false"
                        auto-apply
                        :teleport="true"
                        :clearable="false"
                        :preset-dates="presetRanges"
                        close-on-scroll
                        position="left"
                        format="yyyy-MM-dd"
                        :transitions="false"
                        :placeholder="t('allTime')"
                        :config="{ closeOnAutoApply: true }"
                    />
                </div>

                <div class="mt-4 flex flex-col space-y-3">
                    <div
                        v-for="shipment in shipments"
                        :key="shipment.id"
                        class="flex justify-between space-x-3 w-full rounded-xl bg-white p-3 shadow-md dark:bg-dark-400"
                        :class="{ 'opacity-50': pickedShipmentIds.includes(shipment.id) }"
                    >
                        <div class="flex w-2/5 flex-col">
                            <span class="text-sm dark:text-primary-50 font-semibold">
                                {{ t('shipment') }}
                            </span>
                            <div class="flex">
                                <span class="flex text-sm dark:text-primary-50 mr-1 break-all">
                                    {{ shipment.shipmentNumber ?? shipment.name ?? '-' }}
                                    <mdi:eye
                                        v-if="shipment && !pickedShipmentIds.includes(shipment.id)"
                                        class="ml-1 cursor-pointer hover:text-primary-400 dark:hover:text-primary-300 min-h-5 min-w-5"
                                        @click="showShipment(shipment!.id)"
                                    />
                                </span>
                            </div>

                            <span
                                class="mr-2 text-sm dark:text-primary-50"
                                v-text="t(shipmentTypes[shipment.type])"
                            />
                            <span class="text-xs">{{ datetime(shipment.plannedAt) }}</span>
                        </div>
                        <div class="flex w-2/5 flex-col">
                            <span class="text-sm dark:text-primary-50 font-semibold truncate">
                                {{
                                    shipment.type === ShipmentType.Pickup
                                        ? t('sender')
                                        : t('receiver')
                                }}
                            </span>
                            <span
                                v-truncate-tooltip="getShipmentDestinationName(shipment)"
                                class="text-sm dark:text-primary-50 truncate"
                                v-text="getShipmentDestinationName(shipment)"
                            />
                            <div
                                class="text-xs dark:text-primary-200 break-words"
                                v-text="getShipmentAddress(shipment)"
                            />
                        </div>

                        <div v-if="props.isolated" class="flex w-1/4 flex-col">
                            <span class="text-sm dark:text-primary-50 font-semibold">
                                {{ t('tour') }}
                            </span>
                            <span class="text-xs dark:text-primary-200">
                                {{ shipment.tour?.name ?? t('unassigned') }}
                            </span>
                        </div>

                        <div class="flex flex-shrink-0 items-center justify-end">
                            <MyButton
                                v-if="!pickedShipmentIds.includes(shipment.id)"
                                scheme="primary"
                                type="button"
                                size="small"
                                @click="selectShipment(shipment)"
                            >
                                {{ t('select') }}
                            </MyButton>
                            <MyButton v-else scheme="primary" type="button" size="small" disabled>
                                {{ t('added') }}
                            </MyButton>
                        </div>
                    </div>

                    <div v-if="shipments.length <= 0 && !loading" class="flex justify-center">
                        <span class="dark:text-primary-300">{{ t('noAvailableShipments') }}</span>
                    </div>

                    <TableFooter
                        class="border-t border-primary-200 dark:border-dark-500"
                        hide-per-page
                        :pagination-data="paginationData"
                        :refetch="(params) => fetchAvailableShipments(params?.page ?? 1)"
                        :row-count="shipments.length ?? 0"
                    />
                </div>
            </div>
        </div>

        <ShipmentModal
            v-if="selectedShipmentId"
            :id="selectedShipmentId"
            v-model:open="shipmentModalOpen"
            @update:open="selectedShipmentId = undefined"
        />
    </div>
</template>

<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useConfirm } from '@/hooks/use-confirm'
import useGoogleMaps from '@/hooks/use-google-maps'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { LicenseType, Location, LocationPoint } from '@/types/company'
import { MyButtonScheme } from '@/types/layout/my-button'
import { phoneNumberString } from '@/utils/string'
import { PermissionType } from '@/types/general'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import ManageLocationModal from '@/components/locations/ManageLocationModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const confirm = useConfirm()

const searchQuery = ref('')
const loading = ref(false)
const selectedLocationId = ref<string>()
const mapWrapper = ref<HTMLElement>()
const createModalOpen = ref(false)
const updateModalOpen = ref(false)
const showMissingList = ref(false)
const locationElements = ref<HTMLDivElement[]>([])
const locations = computed<Location[]>(() => {
    if (!searchQuery.value) return companyStore.locations

    const query = searchQuery.value.toLowerCase()
    return companyStore.locations.filter((location) => {
        if (location.name.toLowerCase().includes(query)) return true
        if (location.pNumber && location.pNumber.toLowerCase().includes(query)) return true
        if (location.agentNumber.toLowerCase().includes(query)) return true
        if (location.address.toLowerCase().includes(query)) return true
        if (location.city.toLowerCase().includes(query)) return true
        if (location.zipcode.toLowerCase().includes(query)) return true
        if (location.country.toLowerCase().includes(query)) return true

        return false
    })
})
const showAgentNumber = computed(() => authStore.hasLicense(LicenseType.AgentLocationFiltering))
const selectedLocation = computed(() => {
    if (!selectedLocationId.value) return undefined

    return companyStore.locations.find(({ id }) => id === selectedLocationId.value)
})
const selectedLocationPoint = computed<LocationPoint>(
    () => selectedLocation.value?.location ?? { latitude: 0, longitude: 0 },
)
const createLocationRoute = computed(() =>
    window.route('company.locations.store', { company: authStore.companyId }),
)
const updateLocationRoute = computed(() =>
    window.route('company.locations.update', {
        company: authStore.companyId,
        location: selectedLocationId.value || '',
    }),
)
const locationTradesEnabled = computed(() => authStore.hasLicense(LicenseType.LocationTrade))

useGoogleMaps(mapWrapper, selectedLocationPoint)

function selectLocation(locationId: string) {
    selectedLocationId.value = locationId
}

function selectFirstLocation() {
    if (locations.value.length > 0) {
        selectLocation(locations.value[0].id)
    }
}

function missingData({ name, location, city, country, address }: Location): string[] {
    const missing: string[] = []

    if (!name) missing.push('name')
    if (!location) missing.push('location')
    if (!city) missing.push('city')
    if (!country) missing.push('country')
    if (!address) missing.push('address')

    return missing
}

async function fetch() {
    await companyStore.fetchLocations()
}

function scrollToLocation(locationId: string) {
    setTimeout(() => {
        const index = locations.value.findIndex(({ id }) => id === locationId)
        if (index)
            locationElements.value[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 150)
}

async function createModalClosed(locationId?: string) {
    createModalOpen.value = false
    if (!locationId) return

    await fetch()
    selectLocation(locationId)
    scrollToLocation(locationId)
}

async function updateModalClosed(locationId?: string) {
    updateModalOpen.value = false
    if (locationId) {
        await fetch()
        scrollToLocation(locationId)
    }
}

async function deleteLocation() {
    try {
        const url = window.route('company.locations.destroy', {
            company: authStore.companyId,
            location: selectedLocationId.value!,
        })

        await confirm(
            t('deleteEntityTitle', { entity: t('location') }),
            t('deleteEntityDescription', { entity: t('location') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )

        loading.value = true
        await axios.delete(url)
        selectedLocationId.value = locations.value[0]?.id
        await fetch()
        notify({ title: t('locationDeleted'), type: 'success' })
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 403) {
            notify({ title: t('ActionNotAllowed'), type: 'error' })
        } else if (e) {
            notify({ title: t('unknownError'), type: 'error' })
        }
    } finally {
        loading.value = false
    }
}

let initialLoad = true
watch(
    () => companyStore.locations,
    (locations) => {
        if (locations.length > 0 && initialLoad) {
            selectFirstLocation()
            initialLoad = false
        }
    },
)
onBeforeMount(() => {
    if (locations.value.length > 0 && initialLoad) {
        selectFirstLocation()
        initialLoad = false
    }
})
</script>

<template>
    <ManageLocationModal
        v-model="createModalOpen"
        :route="createLocationRoute"
        @close="createModalClosed"
    />

    <ManageLocationModal
        v-model="updateModalOpen"
        :location="selectedLocation"
        :route="updateLocationRoute"
        @close="updateModalClosed"
    />

    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('locations')" />

        <template #actions>
            <MyButton
                v-if="authStore.hasPermission(PermissionType.ManageCompany)"
                plain
                scheme="primary"
                size="small"
                @click="createModalOpen = true"
            >
                <mdi:plus-thick class="mr-1" />
                {{ t('createNew') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <section class="grid gap-6 lg:grid-cols-[2fr_2fr_3fr]">
        <MyPanel
            bordered
            class="relative space-y-3 p-3 lg:max-h-[70vh] lg:min-h-[500px] lg:overflow-y-scroll"
            invisible
            panel-class="order-last lg:order-first"
        >
            <LoaderWrapper :visible="loading || companyStore.loadingLocations" class="rounded-xl" />

            <MyInput
                v-model="searchQuery"
                :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                :placeholder="t('search')"
                group-class="sticky top-0 z-10"
                type="search"
            >
                <template #icon>
                    <mdi:magnify />
                </template>
            </MyInput>

            <div
                v-for="location in locations"
                :key="location.id"
                ref="locationElements"
                :class="{
                    'bg-primary-200 dark:bg-dark-400': selectedLocationId === location.id,
                }"
                class="group relative cursor-pointer rounded-lg p-3 transition group-hover:bg-primary-200 dark:group-hover:bg-dark-400"
                @click="selectLocation(location.id)"
            >
                <div
                    v-if="missingData(location).length > 0"
                    class="absolute right-3 top-3 text-red-400"
                >
                    <mdi:exclamation-thick />
                </div>

                <div class="flex items-center space-x-2">
                    <h2
                        v-tooltip="{
                            content: t('disabledLocationExplanation'),
                            disabled: location.active,
                        }"
                        class="font-semibold uppercase"
                        :class="{ 'opacity-50': !location.active }"
                        v-text="location.name"
                    />
                    <div
                        v-if="!location.active"
                        v-tooltip="t('disabledLocationExplanation')"
                        class="rounded-lg bg-red-600 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                        v-text="t('inactive')"
                    />
                    <div
                        v-if="locationTradesEnabled && location.activeTrade"
                        v-tooltip="t('activeLocationTradesEnabled')"
                        class="rounded-lg bg-blue-500 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                    >
                        <mdi:account-switch-outline />
                    </div>
                </div>

                <div class="flex justify-between text-xs text-primary-500 dark:text-primary-300">
                    <div>
                        <div v-if="location.pNumber">
                            <span class="mr-1 font-semibold uppercase" v-text="t('pNumber')" />
                            <span v-text="location.pNumber" />
                        </div>
                        <div v-if="showAgentNumber && location.agentNumber">
                            <span class="mr-1 font-semibold uppercase" v-text="t('agentNumber')" />
                            <span v-text="location.agentNumber" />
                        </div>
                    </div>
                    <span v-text="location.city + ' ' + location.country" />
                </div>
            </div>

            <div
                v-if="locations.length === 0"
                class="mt-2 text-center"
                v-text="t('noLocationsFound')"
            />
        </MyPanel>
        <MyPanel padded>
            <template v-if="selectedLocation">
                <div
                    v-if="missingData(selectedLocation).length > 0"
                    class="mb-6 cursor-pointer rounded-lg bg-yellow-400 p-3 text-xs uppercase text-primary-50 transition hover:bg-yellow-500"
                    @click="showMissingList = !showMissingList"
                >
                    <span
                        class="block text-center font-semibold"
                        v-text="t('missingInfo', { about: t('location') })"
                    />

                    <div v-if="showMissingList" class="mt-3 border-t border-primary-50/20 pt-3">
                        <ul>
                            <li
                                v-for="(val, index) in missingData(selectedLocation)"
                                :key="'missing' + index"
                                v-text="t(val)"
                            />
                        </ul>
                    </div>
                </div>
                <div
                    v-if="authStore.hasPermission(PermissionType.ManageCompany)"
                    class="absolute right-4 top-4 space-x-2"
                >
                    <MyButton
                        icon
                        pill
                        plain
                        scheme="light"
                        size="small"
                        @click="updateModalOpen = true"
                    >
                        <mdi:pencil />
                    </MyButton>

                    <MyButton icon pill plain scheme="light" size="small" @click="deleteLocation">
                        <mdi:delete />
                    </MyButton>
                </div>
                <div class="flex items-center space-x-2">
                    <h1
                        class="text-lg font-semibold uppercase"
                        :class="{ 'opacity-50': !selectedLocation.active }"
                        v-text="selectedLocation.name"
                    />
                    <div
                        v-if="!selectedLocation.active"
                        v-tooltip="t('disabledLocationExplanation')"
                        class="rounded-lg bg-red-600 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                        v-text="t('inactive')"
                    />
                    <div
                        v-if="locationTradesEnabled && selectedLocation.activeTrade"
                        v-tooltip="t('activeLocationTradesEnabled')"
                        class="rounded-lg bg-blue-500 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                    >
                        <mdi:account-switch-outline />
                    </div>
                </div>

                <p v-if="selectedLocation.pNumber" class="text-xs">
                    <span class="font-semibold uppercase" v-text="t('pNumber')" />
                    {{ selectedLocation.pNumber }}
                </p>
                <p v-if="showAgentNumber && selectedLocation.agentNumber" class="text-xs">
                    <span class="font-semibold uppercase" v-text="t('agentNumber')" />
                    {{ selectedLocation.agentNumber }}
                </p>
                <div class="my-6 border-t border-primary-200" />

                <div class="text-sm">
                    <h2 class="mb-2 text-xs font-semibold uppercase" v-text="t('address')" />
                    <p v-text="selectedLocation.address" />
                    <p>
                        <span v-text="selectedLocation.zipcode" />,
                        <span v-text="selectedLocation.city" />
                    </p>
                    <p v-text="selectedLocation.country" />
                </div>
                <div class="my-6 border-t border-primary-200" />

                <div class="text-sm">
                    <h2
                        class="mb-2 text-xs font-semibold uppercase"
                        v-text="t('contactInformation')"
                    />
                    <p v-text="selectedLocation.email" />
                    <p
                        v-if="selectedLocation.phoneNumber"
                        v-text="phoneNumberString(selectedLocation.phoneNumber)"
                    />
                </div>
                <div class="my-6 border-t border-primary-200" />

                <div class="text-sm">
                    <h2 class="mb-2 text-xs font-semibold uppercase" v-text="t('note')" />
                    <p v-text="selectedLocation.note" />
                </div>
            </template>
        </MyPanel>
        <section ref="mapWrapper" class="h-full overflow-hidden rounded-xl shadow-xl" />
    </section>
</template>

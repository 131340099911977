<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import { useAuthStore } from '@/stores/auth-store'
import { uuid, PaginatedResponse, PermissionType, MinimalResource } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { MinimalTour, TourState } from '@/types/delivery-management'
import { GetDataParameters } from '@/types/table'
import { DropdownOption, DropdownStringOption } from '@/types/inputs'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { datetime } from '@/utils/dates'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { numberFormatter } from '@/utils/numbers'
import { TourType } from '@/types/driver-report'
import { tourTypes } from '@/utils/type-translations'
import { useMittListener } from '@/hooks/use-mitt-listener'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import ManageTourModal from '@/components/delivery-management/manageTour/ManageTourModal.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import CreateTicketModal from '@/components/delivery-management/CreateTicketModal.vue'

interface FetchToursParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        type: TourType | undefined
        state: TourState | undefined
        'user-id': uuid | undefined
        'hub-id': uuid | undefined
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

const { t } = useI18n()
const authStore = useAuthStore()
const confirm = useConfirm()
const route = useRoute()
const presetRanges = usePresetRangeList()

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalTour,
    FetchToursParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<MinimalTour>>(
            window.route('dm.company.tours.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            type: undefined,
            state: undefined,
            'user-id': undefined,
            'hub-id': undefined,
            'start-date': dayjs().startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)

const tourModalOpen = ref(false)
const ticketModalOpen = ref(false)
const selectedTour = ref<MinimalTour>()
const drivers = useLazyLoadedList<DropdownStringOption>(fetchDrivers)
const hubs = useLazyLoadedList<DropdownStringOption>(fetchHubs)
const datepicker = ref<DatePickerInstance>(null)
const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

function canManageTour(tour: MinimalTour): boolean {
    if (tour.company.id === authStore.companyId) return true
    if (tour.shipperCompany?.id === authStore.companyId) return true

    if (!tour.haulierCanManage) return false
    if (tour.haulierCompany?.id === authStore.companyId) return true

    return !!tour.hub?.hauliers.map((haulier) => haulier.id).includes(authStore.companyId)
}

const tourTypeOptions = computed<DropdownOption[]>(() => {
    return Object.entries(tourTypes).map(([value, translation]) => ({
        label: t(translation),
        value,
    }))
})

const earlyOrDelayedTooltip = (row: MinimalTour) => {
    const early = `${row.handledEarlyShipmentsCount}/${row.shipmentsCount}`
    const delayed = `${row.delayedShipmentsCount}/${row.shipmentsCount - row.handledEarlyShipmentsCount}`

    return `${early} ${t('isEarly')}\n${delayed} ${t('isDelayed')}`
}

const slaPercentage = (row: MinimalTour) => {
    if (!row.delayedShipmentsCount && !row.handledEarlyShipmentsCount) return '100%'
    const onTimeShipments =
        row.shipmentsCount - row.delayedShipmentsCount - row.handledEarlyShipmentsCount

    return numberFormatter.format((onTimeShipments / row.shipmentsCount) * 100) + '%'
}

async function fetchDrivers(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId }),
    )

    return response.data.map((driver) => ({
        value: driver.id,
        label: driver.name,
    }))
}

async function fetchHubs(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.hubs', { company: authStore.companyId }),
    )

    return response.data.map((hub) => ({
        value: hub.id,
        label: hub.name,
    }))
}

function updateTour(tour: MinimalTour) {
    selectedTour.value = tour
    tourModalOpen.value = true
}

function createTicket(tour: MinimalTour) {
    selectedTour.value = tour
    ticketModalOpen.value = true
}

async function ticketModalClosed() {
    ticketModalOpen.value = false
    selectedTour.value = undefined
}

async function tourModalClosed() {
    tourModalOpen.value = false
    selectedTour.value = undefined
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function showTour(id: string): RouteLocationRaw {
    return {
        name: 'dm.tours.show',
        params: { tourId: id },
        query: route.query,
    }
}

function showShipments(id: string): RouteLocationRaw {
    let startDate = dayjs().year(2023).startOf('year').toDate().toISOString()
    if (date.value[0]) {
        startDate = date.value[0].toISOString()
    }

    let endDate = dayjs().endOf('day').toDate().toISOString()
    if (date.value[1]) {
        endDate = date.value[1].toISOString()
    }

    return {
        name: 'dm.shipments',
        query: {
            filter: {
                // @ts-ignore
                'tour-id': id,
                'start-date': startDate,
                'end-date': endDate,
            },
        },
    }
}

async function deleteTour(tour: MinimalTour) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('tour') }),
            t('deleteEntityDescription', { entity: t('tour') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('dm.company.tours.destroy', {
            company: authStore.companyId,
            tour: tour.id,
        }),
    )
    await refetch()
}

function clearDates() {
    datepicker.value?.closeMenu()
    date.value = []
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
}

onBeforeMount(() => {
    if (params.value.filter['user-id']) drivers.fetch()
    if (params.value.filter['hub-id']) hubs.fetch()
})

watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})

useMittListener('fetchTours', () => refetch())
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('tours')" />

        <template #actions>
            <MyButton plain scheme="primary" size="small" @click="tourModalOpen = true">
                <mdi:plus-thick class="mr-1" />
                {{ t('addTour') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <MyTable
            table-id="dm-tours"
            :rows="data"
            :pagination-data="paginationData"
            :loading="loading"
            :error="error"
            :get-data="refetch"
            :entity-name="t('tours')"
            :has-time-range="!!params.filter['start-date']"
        >
            <template #filters>
                <Datepicker
                    ref="datepicker"
                    v-model="date"
                    input-class-name="w-64"
                    range
                    :enable-time-picker="false"
                    auto-apply
                    :teleport="true"
                    :clearable="false"
                    :preset-dates="presetRanges"
                    close-on-scroll
                    position="left"
                    format="yyyy-MM-dd"
                    :transitions="false"
                    :placeholder="t('allTime')"
                    :config="{ closeOnAutoApply: true }"
                >
                    <template #allTime>
                        <button
                            class="dp__btn dp--preset-range"
                            @click="clearDates()"
                            v-text="t('allTime')"
                        />
                    </template>
                </Datepicker>

                <MyFilterDivider />

                <MyFilterButton
                    filter-name="state"
                    value="created"
                    @selected="setFilter"
                    v-text="t('created')"
                />

                <MyFilterButton
                    filter-name="state"
                    value="enRoute"
                    @selected="setFilter"
                    v-text="t('enRoute')"
                />

                <MyFilterButton
                    filter-name="state"
                    value="finished"
                    @selected="setFilter"
                    v-text="t('finished')"
                />

                <MyFilterDivider />

                <MyFilterSelect
                    :label="t('type')"
                    :options="tourTypeOptions"
                    filter-name="type"
                    @change="setFilter"
                />

                <MyFilterSelect
                    :label="t('hubs')"
                    :options="hubs.items.value ?? params.filter['hub-id']"
                    :loading="hubs.fetching.value"
                    filter-name="hub-id"
                    @focus="hubs.fetch()"
                    @change="setFilter"
                />

                <MyFilterSelect
                    :label="t('drivers')"
                    :options="drivers.items.value"
                    :loading="drivers.fetching.value"
                    filter-name="user-id"
                    @focus="drivers.fetch()"
                    @change="setFilter"
                />
            </template>

            <template #actionRow="{ row }">
                <RouterLink :to="showTour(row.id)">
                    <ActionRowItem>
                        <mdi:eye />
                    </ActionRowItem>
                </RouterLink>
            </template>

            <template
                v-if="authStore.hasPermission(PermissionType.ManageCompany)"
                #actionMenu="{ row }"
            >
                <RouterLink :to="showShipments(row.id)">
                    <ActionMenuItem v-tooltip="t('showShipmentsForTour')">
                        <span class="mr-2"><mdi:eye /></span>
                        {{ t('showShipments') }}
                    </ActionMenuItem>
                </RouterLink>

                <ActionMenuItem
                    v-if="row.company.id === authStore.companyId && row.shipperCompany"
                    @click="createTicket(row)"
                >
                    <span class="mr-2"><mdi:briefcase-edit /></span>
                    {{ t('createEntity', { entity: t('ticket') }) }}
                </ActionMenuItem>

                <ActionMenuItem
                    v-if="row.endedAt === null && canManageTour(row)"
                    @click="updateTour(row)"
                >
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>

                <ActionMenuItem @click="deleteTour(row)">
                    <span class="mr-2"><mdi:trash-can /></span>
                    {{ t('remove') }}
                </ActionMenuItem>
            </template>

            <MyTableColumn :name="t('hub')" property="hub.name" />

            <MyTableColumn :name="t('name')" property="name" />

            <MyTableColumn :name="t('plannedAt')" property="plannedAt" />
            <template #plannedAt="{ row }">
                <span v-text="datetime(row.plannedAt)" />
            </template>

            <MyTableColumn :name="t('endedAt')" property="endedAt" />
            <template #endedAt="{ row }">
                <span v-text="row.endedAt ? datetime(row.endedAt) : ''" />
            </template>

            <MyTableColumn :name="t('status')" property="state" :sortable="false" />
            <template #state="{ row }">
                <div v-if="row.endedAt === null && row.startedAt === null">
                    {{ t('created') }}
                </div>
                <div v-if="row.endedAt === null && row.startedAt !== null">
                    {{ t('enRoute') }}
                </div>
                <div v-if="row.endedAt !== null && row.startedAt !== null">
                    {{ t('finished') }}
                </div>
            </template>

            <MyTableColumn :name="t('company')" property="company.name" />

            <MyTableColumn :name="t('shipper')" property="shipperCompany.name" />

            <MyTableColumn :name="t('serviceCodes')" property="categories" :sortable="false" />

            <MyTableColumn :name="t('shipments')" property="shipmentsCount" />

            <MyTableColumn :name="t('deliveredShipments')" property="deliveredShipmentsCount" />

            <MyTableColumn :name="t('delivered')" property="percentage" :sortable="false" />
            <template #percentage="{ row }">
                <span v-if="row.shipmentsCount === 0">0%</span>

                <span v-else>
                    {{ Math.round((row.deliveredShipmentsCount / row.shipmentsCount) * 100) + '%' }}
                </span>
            </template>

            <MyTableColumn :name="t('sla')" property="sla" :sortable="false" />
            <template #sla="{ row }">
                <div v-tooltip="earlyOrDelayedTooltip(row)">
                    <span
                        :class="{
                            'text-yellow-600 font-semibold':
                                row.delayedShipmentsCount || row.handledEarlyShipmentsCount,
                        }"
                        v-text="slaPercentage(row)"
                    />
                </div>
            </template>

            <MyTableColumn :name="t('deviations')" property="deviationsCount" />
            <template #deviationsCount="{ row }">
                <div class="flex items-center space-x-2">
                    <span v-text="row.deviationsCount" />
                    <mdi:alert v-if="row.deviationsCount > 0" class="text-yellow-500" />
                </div>
            </template>
            <MyTableColumn :name="t('tickets')" property="ticketsCount" />
            <template #ticketsCount="{ row }">
                <div class="flex items-center space-x-2">
                    <span v-text="row.ticketsCount" />
                    <mdi:alert v-if="row.ticketsCount > 0" class="text-yellow-500" />
                </div>
            </template>

            <MyTableColumn :name="t('driver')" property="driver.name" hidden />
            <MyTableColumn
                :name="t('transportObjects')"
                property="transportObjects"
                hidden
                :sortable="false"
            />
            <template #transportObjects="{ row }">
                {{ row.transportObjects.map((to) => to.registrationNumber).join(', ') }}
            </template>
            <MyTableColumn :name="t('type')" property="type" hidden />
            <template #type="{ row }">
                <span v-if="row.type === TourType.Domestic" v-text="t('domestic')" />
                <span v-if="row.type === TourType.Export" v-text="t('export')" />
            </template>

            <MyTableColumn :name="t('haulier')" property="haulierCompany.name" hidden />
            <MyTableColumn :name="t('startedAt')" property="startedAt" hidden />
            <template #startedAt="{ row }">
                <span v-if="row.startedAt" v-text="datetime(row.startedAt)" />
            </template>

            <template #noDataButton>
                <MyButton
                    v-if="params.filter['start-date']"
                    plain
                    scheme="light"
                    size="small"
                    @click="clearDates()"
                >
                    <mdi:magnify class="mr-2 h-4 w-4" />
                    {{ t('searchAllTime') }}
                </MyButton>
            </template>
        </MyTable>
    </MyPanel>

    <ManageTourModal
        v-if="tourModalOpen"
        v-model="tourModalOpen"
        :tour-id="selectedTour?.id"
        :is-hub-haulier="selectedTour ? canManageTour(selectedTour) : false"
        @close="tourModalClosed"
        @saved="refetch()"
    />

    <CreateTicketModal
        v-if="selectedTour"
        v-model="ticketModalOpen"
        :tour="selectedTour"
        @close="ticketModalClosed"
    />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import {
    MinimalShipment,
    Shipment,
    ShipmentEventType,
    ShipmentType,
} from '@/types/delivery-management'

const { t } = useI18n()
const props = defineProps<{
    shipment: Shipment | MinimalShipment
}>()

const isDelivery = computed(() => props.shipment.type === ShipmentType.Delivery)

const isInTransit = computed(() => {
    if (props.shipment.currentEventType === ShipmentEventType.TerminalLoad) {
        return true
    }

    return props.shipment.currentEventType === ShipmentEventType.Pickup
})
</script>
<template>
    <div v-if="props.shipment.handledAt && !isDelivery" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-green-400" />
        {{ t('pickedUp') }}
    </div>

    <div v-else-if="props.shipment.delayed && isDelivery" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-red-400" />
        {{ !shipment.handledAt ? t('delayed') : t('lateDelivery') }}
    </div>

    <div v-else-if="props.shipment.handledTooEarly && isDelivery" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-red-400" />
        {{ t('earlyDelivery') }}
    </div>

    <div v-else-if="isInTransit && !props.shipment.handledAt" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-blue-400" />
        {{ t('inTransit') }}
    </div>
    <div v-else-if="!props.shipment.tour && !shipment.handledAt" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-blue-400" />
        {{ t('noAssignedTour') }}
    </div>

    <div v-else-if="!props.shipment.handledAt" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-gray-400" />
        {{ isDelivery ? t('notDelivered') : t('notPickedUp') }}
    </div>

    <div v-else-if="props.shipment.handledAt && isDelivery" class="flex items-center">
        <div class="h-3 w-3 mr-2 rounded-full bg-green-400" />
        {{ t('delivered') }}
    </div>
</template>

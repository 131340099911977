<script lang="ts" setup>
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { ResourceResponse, uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { printElement } from '@/utils/print-element'
import { Shipment } from '@/types/delivery-management'
import { datetime } from '@/utils/dates'
import { image, localAsset } from '@/utils/assets'

import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const modalContent = ref<HTMLDivElement>()

const shipment = ref<Shipment | null>(null)
const loading = ref(false)

const totalWeight = computed(() => {
    if (!shipment.value) return 0
    return shipment.value.collis.reduce((acc, colli) => acc + colli.weight, 0)
})

const totalColli = computed(() => {
    if (!shipment.value) return 0
    return shipment.value.collis.reduce((acc, colli) => acc + colli.amount, 0)
})

async function fetchShipment(id?: uuid) {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Shipment>>(
            window.route('dm.company.shipments.show', {
                company: authStore.companyId,
                shipment: id ?? props.id!,
            }),
        )
        shipment.value = response.data.data
    } finally {
        loading.value = false
    }
}

function print() {
    printElement(modalContent.value!)
}

function onClose() {
    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dm.shipments', query: route.query })
    }
}

watch(
    () => props.id,
    () => {
        if (props.id) {
            fetchShipment()
        }
    },
)

watch(
    () => route.params.shipmentId,
    () => {
        if (route.params.shipmentId) fetchShipment(route.params.shipmentId as uuid)
    },
)
</script>

<template>
    <MyModal :value="props.open" light :max-width="800" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div v-if="shipment" ref="modalContent" class="space-y-3">
            <div class="mb-6 flex justify-between">
                <ContentHeading>
                    <h1 class="text-xl font-bold text-primary-400">{{ t('shipment') }}</h1>
                    <div class="mt-1 flex space-x-2 text-xs">
                        <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                            {{ t('type') }}:
                        </h4>
                        <h4 class="font-medium dark:text-primary-400">
                            {{ t('notSpecified') }}
                        </h4>
                    </div>
                </ContentHeading>

                <div v-if="authStore.companyId" class="flex space-x-2 print:hidden">
                    <MyButton icon plain scheme="light" size="small" @click="print()">
                        <mdi:printer />
                    </MyButton>
                </div>
            </div>

            <!-- First Section -->
            <div class="flex space-x-3">
                <div class="flex w-full flex-col space-y-3">
                    <div class="flex bg-primary-100 p-3 dark:bg-dark-500">
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                                >{{ t('sender') }}</span
                            >
                            <span>{{ shipment.sendingCompany.name }}</span>
                            <span>{{ shipment.sendingCompany.address }}</span>
                            <div class="flex space-x-2">
                                <span>{{ shipment.sendingCompany.country }},</span>
                                <span>{{ shipment.sendingCompany.zipcode }},</span>
                                <span>{{ shipment.sendingCompany.city }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex bg-primary-100 p-3 dark:bg-dark-500">
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                                >{{ t('receiver') }}</span
                            >
                            <span>{{ shipment.receivingCompany.name }}</span>
                            <span>{{ shipment.receivingCompany.address }}</span>
                            <div class="flex space-x-2">
                                <span>{{ shipment.receivingCompany.country }},</span>
                                <span>{{ shipment.receivingCompany.zipcode }},</span>
                                <span>{{ shipment.receivingCompany.city }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex w-4/12 flex-col items-center bg-primary-100 p-3 dark:bg-dark-500">
                    <h1 class="text-5xl font-bold uppercase text-primary-500 dark:text-primary-400">
                        {{ t('cmr') }}
                    </h1>
                    <h3
                        class="text-lg font-semibold uppercase text-primary-400 dark:text-primary-300"
                    >
                        {{ t('shipment') }}
                    </h3>
                    <span class="h-32 w-32 self-center bg-primary-400"></span>
                </div>
            </div>

            <!-- Second Section -->
            <div class="flex space-x-3">
                <div class="flex w-1/2 flex-col space-y-3">
                    <div class="flex bg-primary-100 p-3 dark:bg-dark-500">
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            >
                                {{ t('placeOfDelivery') }}
                            </span>
                            <span>{{ shipment.receivingLocation.name }}</span>
                            <span>{{ shipment.receivingLocation.address }}</span>
                            <div class="flex space-x-2">
                                <span>{{ shipment.receivingLocation.country }},</span>
                                <span>{{ shipment.receivingLocation.zipcode }},</span>
                                <span>{{ shipment.receivingLocation.city }}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="shipment.shipperCompany"
                        class="flex bg-primary-100 p-3 dark:bg-dark-500"
                    >
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            >
                                {{ t('shipper') }}
                            </span>
                            <span>{{ shipment.shipperCompany?.name }}</span>
                            <span>{{ shipment.shipperCompany?.address }}</span>
                            <div v-if="shipment.shipperCompany" class="flex space-x-2">
                                <span>{{ shipment.shipperCompany?.country }},</span>
                                <span>{{ shipment.shipperCompany?.zipcode }},</span>
                                <span>{{ shipment.shipperCompany?.city }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex w-1/2 flex-col space-y-3 bg-primary-100 p-3 dark:bg-dark-500">
                    <div>
                        <h1 class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                            {{ shipment.handledAt ? t('deliveredAt') : t('plannedAt') }}
                        </h1>
                        <span>
                            {{
                                shipment.handledAt
                                    ? datetime(shipment.handledAt)
                                    : datetime(shipment.plannedAt)
                            }}
                        </span>
                    </div>

                    <div>
                        <h1 class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                            {{ shipment.deliveredByUser ? t('deliveredBy') : '' }}
                        </h1>
                        <span>
                            {{ shipment.deliveredByUser ? shipment.deliveredByUser.name : '' }}
                        </span>
                    </div>

                    <div>
                        <h1 class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                            {{ shipment.deliveryNote ? t('deliveryNote') : '' }}
                        </h1>
                        <span>{{ shipment.deliveryNote }}</span>
                    </div>
                </div>
            </div>

            <!-- Third Section -->
            <div class="flex w-full flex-col space-y-3 bg-primary-100 p-3 dark:bg-dark-500">
                <table>
                    <thead>
                        <tr class="bg-primary-100 text-left text-xs dark:bg-dark-500">
                            <th
                                class="p-2.5 font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('name')"
                            />
                            <th
                                class="p-2.5 font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('description')"
                            />
                            <th
                                class="p-2.5 font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('lxwxh')"
                            />
                            <th
                                class="p-2.5 font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('colli')"
                            />
                            <th
                                class="p-2.5 font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('weight')"
                            />
                            <th
                                class="p-2.5 font-semibold text-primary-500 dark:text-primary-400"
                                v-text="t('volume')"
                            />
                        </tr>
                    </thead>

                    <tbody v-if="shipment?.collis" class="rounded">
                        <tr
                            v-for="colli in shipment.collis"
                            :key="colli.id"
                            class="break-inside-avoid odd:bg-primary-50 even:bg-primary-100 dark:even:bg-dark-400"
                        >
                            <td class="truncate p-2.5 text-sm" v-text="colli.name" />

                            <td
                                class="max-w-[200px] overflow-ellipsis whitespace-pre-line p-2.5 text-sm"
                                v-text="colli.description"
                            />

                            <td class="truncate p-2.5 text-sm">
                                {{ colli.length }}x{{ colli.width }}x{{ colli.height }}
                            </td>

                            <td class="truncate p-2.5 text-sm" v-text="colli.colli" />
                            <td class="truncate p-2.5 text-sm" v-text="colli.weight" />
                            <td class="truncate p-2.5 text-sm" v-text="colli.volume" />
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Fourth Section -->
            <div class="flex w-full space-x-3">
                <div v-if="shipment.receiverSignature" class="flex w-1/2 flex-col space-y-3">
                    <div class="flex bg-primary-100 p-3 dark:bg-dark-500">
                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                            >
                                {{ t('receiverSignature') }}
                            </span>
                            <span>{{ shipment.receiverName }}</span>
                            <img
                                class="mt-2 w-16 rounded-full"
                                :src="image(shipment.receiverSignature)"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex w-1/2 space-x-3">
                    <div class="flex w-1/2 flex-col space-y-3">
                        <div class="flex bg-primary-100 p-3 dark:bg-dark-500">
                            <div class="flex flex-col space-y-3"></div>
                        </div>
                    </div>
                    <div class="flex w-1/2 flex-col space-y-3">
                        <div class="flex bg-primary-100 p-3 dark:bg-dark-500">
                            <div class="flex flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                                >
                                    {{ t('totalKg') }}.
                                </span>
                                <span class="text-lg font-bold">{{ totalWeight }} kg</span>
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                                >
                                    {{ t('totalColli') }}.
                                </span>
                                <span class="text-lg font-bold">{{ totalColli }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img
                :src="localAsset('assets/begreenprintlabel.png')"
                alt="begreen"
                class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
            />
        </div>

        <h1
            v-if="!shipment && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('shipmentNotFound')"
        />
    </MyModal>
</template>

import { ComposerTranslation } from 'vue-i18n'

import { InputVariable } from './../types/inputs'

export function getPackagingVariables(t: ComposerTranslation): InputVariable[] {
    return [
        { value: 'customerName', description: t('customerNameVariable') },
        { value: 'customerContactPerson', description: t('customerContactPersonVariable') },
        { value: 'companyName', description: t('companyNameVariable') },
    ]
}

<script lang="ts">
interface Props {
    modelValue: boolean
    reportId?: uuid
}
</script>

<script setup lang="ts">
import type { ResourceResponse, uuid } from '@/types/general'

import { watch, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { LocationPoint } from '@/types/company'
import { Report } from '@/types/driver-report'
import { emitter } from '@/utils/mitt'
import useGoogleMaps from '@/hooks/use-google-maps'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

interface Form extends Record<string, unknown> {
    forceStop: boolean
    arrivalNote: string | null
    location: LocationPoint | null
    endedAt: Date
    kilometerCount: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'update:modelValue', value: boolean): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const report = ref<Report | null>(null)
const mapWrapper = ref<HTMLElement>()

const location = computed(() =>
    report.value ? report.value.location.location : { latitude: 0, longitude: 0 },
)

useGoogleMaps(mapWrapper, location)

const { data, submit, errors, reset, loading } = useForm<Form>({
    forceStop: true,
    arrivalNote: t('forceStopNote', { name: authStore.user?.name }) ?? null,
    endedAt: new Date(),
    location: null,
    kilometerCount: 0,
})

async function fetchReport() {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Report>>(
            window.route('dr.reports.show', {
                report: props.reportId! ?? '',
            }),
        )
        report.value = response.data.data
        loading.value = false

        const lastEvent = report.value.events[report.value.events.length - 1]

        data.location = report.value.location.location
        data.kilometerCount = lastEvent?.kilometerCount ?? 0
        data.endedAt = lastEvent?.startedAt ? new Date(lastEvent?.startedAt) : new Date()
    } finally {
        loading.value = false
    }
}
async function onSubmit() {
    const response = await submit<ResourceResponse<Report>>(
        'PUT',
        window.route('dr.company.reports.stop', {
            company: authStore.companyId,
            report: report.value!.id ?? '',
        }),
    )

    if (response !== undefined) {
        emit('close')
        emitter.emit('fetchDriverReports')
    }
}

onMounted(fetchReport)

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) {
            reset()
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="550" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ t('stopReport') }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="my-2">
                <span class="whitespace-pre-line" v-text="t('stopReportDescription')" />
            </div>
            <div class="space-y-4">
                <div class="flex w-full space-x-3">
                    <div class="w-1/2 self-end">
                        <MyInputLabel v-text="t('endedAt')" />
                        <Datepicker
                            v-model="data.endedAt"
                            input-class-name="h-10 w-[240px]"
                            :teleport="true"
                            :clearable="false"
                            close-on-scroll
                            format="yyyy-MM-dd HH:mm:ss"
                            :transitions="false"
                        />
                        <MyErrorMessage input-name="endedAt" :label="t('endedAt')" />
                    </div>
                    <div class="w-1/2">
                        <MyInputLabel v-text="t('km')" />
                        <h4
                            class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                            v-text="data.kilometerCount"
                        />
                    </div>
                </div>
                <div class="rounded-t-xl dark:bg-dark-500 bg-primary-50 print:px-0">
                    <div class="flex items-center space-x-1.5">
                        <MyInputLabel class="py-3 pl-4 !cursor-default" v-text="t('endLocation')" />
                        <mdi:information
                            v-tooltip="t('stopReportLocationExplanation')"
                            class="cursor-help h-4 w-4"
                        />
                    </div>

                    <article
                        ref="mapWrapper"
                        class="relative h-[240px] rounded-b-xl shadow-xl print:shadow-none"
                    />
                </div>
            </div>
            <div class="mt-4 flex justify-end gap-2">
                <MyButton type="button" @click="emit('update:modelValue', false)">
                    {{ t('cancel') }}
                </MyButton>
                <MyButton :disabled="loading" scheme="danger" v-text="t('stopReport')" />
            </div>
        </MyForm>
    </MyModal>
</template>

import { Loader } from '@googlemaps/js-api-loader'
import { computed, onBeforeUnmount, ref, Ref, unref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { LocationPoint } from '@/types/company'
import { googleMapsLink } from '@/utils/google-maps'

import LatLngLiteral = google.maps.LatLngLiteral

interface MarkerOptions {
    tooltipContent?: string
    custom: boolean
    markerLabel?: string
    enableAutoPan?: boolean
    href?: string
    fillColor?: string
}

const defaultMarkerOptions: MarkerOptions = {
    tooltipContent: undefined,
    custom: false,
    markerLabel: undefined,
    enableAutoPan: false,
    href: undefined,
}

export const mapsLoader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    version: 'weekly',
    libraries: ['places'],
})

export default function useGoogleMaps(
    wrapper: Ref<HTMLElement | undefined>,
    location?: Ref<LocationPoint> | LocationPoint,
    options?: google.maps.MapOptions,
    onLoaded?: () => void,
) {
    const map = ref<google.maps.Map>()
    const marker = ref<google.maps.Marker>()
    let markers: google.maps.Marker[] = []
    const mapType = useLocalStorage('mapType', 'styled_map')

    async function loadMap() {
        await mapsLoader.importLibrary('maps')
        if (!wrapper.value) return

        map.value = new google.maps.Map(wrapper.value, {
            center: latLng.value,
            zoom: 15,
            controlSize: 20,
            disableDefaultUI: false,
            streetViewControl: false,
            mapTypeControlOptions: {
                mapTypeIds: ['styled_map', 'satellite'],
            },
            mapTypeId: mapType.value,
            ...options,
        })

        const styledMapType = new google.maps.StyledMapType(styleArray, { name: 'Standard' })
        map.value.mapTypes.set('styled_map', styledMapType)

        map.value.addListener('maptypeid_changed', () => {
            mapType.value = map.value?.getMapTypeId()
        })

        if (location) {
            marker.value = new google.maps.Marker({
                position: latLng.value,
                map: map.value,
            })
        }

        if (onLoaded) onLoaded()
    }

    function clearMarkers() {
        for (const marker of markers) {
            marker.setMap(null)
        }
        markers = []
    }

    const latLng = computed<LatLngLiteral>(() => {
        if (!location) return { lat: 0, lng: 0 }

        return { lat: unref(location).latitude, lng: unref(location).longitude }
    })

    watch(
        () => unref(location),
        () => {
            map.value?.setCenter(latLng.value)
            marker.value?.setPosition(latLng.value)
        },
    )

    watch(wrapper, () => {
        if (wrapper.value) loadMap()
    })

    onBeforeUnmount(() => clearMarkers())

    return {
        map,
        marker,
        clearMarkers,
        addMarker(location: LocationPoint, options?: Partial<MarkerOptions>): google.maps.Marker {
            const markerOptions: MarkerOptions = { ...defaultMarkerOptions, ...options }

            const markerIcon: google.maps.Symbol = {
                path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                fillColor: markerOptions.fillColor || '#1A805E',
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 2,
                anchor: new google.maps.Point(10, 20),
            }
            const marker = new google.maps.Marker({
                position: {
                    lat: location.latitude,
                    lng: location.longitude,
                },
                map: map.value,
                icon: markerOptions.custom ? markerIcon : null,
                label: markerOptions.markerLabel,
            })
            if (markerOptions.tooltipContent) {
                const tooltip = new google.maps.InfoWindow({
                    content: `
                        <div class="flex space-x-1 items-center">
                            <span class="text-sm font-semibold text-primary-500 dark:text-primary-300">
                                ${markerOptions.tooltipContent}
                            </span>
                        </div>
                    `,
                    disableAutoPan: !markerOptions.enableAutoPan,
                })

                if (markerOptions.href) {
                    marker.addListener('click', () => {
                        window.open(googleMapsLink(location.latitude, location.longitude), '_blank')
                    })
                }

                marker.addListener('mouseover', () => tooltip.open(map.value, marker))
                marker.addListener('mouseout', () => tooltip.close())
            }

            markers.push(marker)

            return marker
        },
        // Zooms map in to fit on added markers
        fitMarkerBounds() {
            if (markers.length === 1 && markers[0].getPosition()) {
                map.value?.setCenter(markers[0].getPosition()!)
                marker.value?.setPosition(markers[0].getPosition()!)
            } else {
                const bounds = new google.maps.LatLngBounds()
                for (const marker of markers) {
                    if (marker.getPosition()) bounds.extend(marker.getPosition()!)
                }
                map.value?.fitBounds(bounds)
            }
        },
    }
}

const styleArray = [
    { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c9b2a6' }],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#dcd2be' }],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#ae9e90' }],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#93817c' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [{ color: '#a5b076' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#447530' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#f5f1e6' }],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#fdfcf8' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#f8c967' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#e9bc62' }],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ color: '#e98d58' }],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#db8555' }],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#806b63' }],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#8f7d77' }],
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ebe3cd' }],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#b9d3c2' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#92998d' }],
    },
]

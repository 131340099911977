import KyvNotifications from '@kyvg/vue3-notification'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import axios from 'axios'
import FloatingVue, { options as floatingOptions } from 'floating-vue'
import Vapor from 'laravel-vapor'
import { vMaska } from 'maska'
import { createPinia } from 'pinia'
import { stringify } from 'qs'
import { App } from 'vue'
import VirtualScroller from 'vue-virtual-scroller'

import { i18n } from '@/i18n/i18n'
import router from '@/router'
import { truncateTooltip } from '@/utils/v-truncate-tooltip'

import '@css/app.css'
import 'floating-vue/dist/style.css'
import 'viewerjs/dist/viewer.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

window.Vapor = Vapor

/**
 * Installs all necessary libraries and components for the provided app.
 * @param app
 * @param sentry
 */
export function install(app: App<Element>, sentry = false): App<Element> {
    axios.defaults.withCredentials = true
    axios.interceptors.request.use((config) => {
        config.paramsSerializer = {
            serialize: (params) => {
                return stringify(params, {
                    arrayFormat: 'brackets',
                    encode: true,
                })
            },
        }

        return config
    })

    const head = createHead()
    const pinia = createPinia()

    if (sentry) installSentry(app)

    // Fixes an issue where tooltip would not close after being hovered with the mouse
    // This is fixed in a later release of floating-vue but we're unable to update due to a release hijacking focus
    // See https://github.com/Akryum/floating-vue/issues/851#issuecomment-1091441639
    floatingOptions.themes.tooltip.popperHideTriggers = ['hover']

    app.use(KyvNotifications)
        .use(router)
        .use(i18n)
        .use(VirtualScroller)
        .use(head)
        .use(pinia)
        .use(FloatingVue, { overflowPadding: 5, distance: 10 })
        .directive('truncate-tooltip', truncateTooltip)
        .directive('maska', vMaska)
        .mixin({
            methods: {
                asset: (path: string) => window.assetUrl + path,
            },
        })

    return app
}

function installSentry(app: App<Element>) {
    if (!import.meta.env.VITE_SENTRY_DSN) return

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration({ router })],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,
        logErrors: true,
        // Defaults from https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
        ignoreErrors: [
            'TypeError: NetworkError when attempting to fetch resource.',
            'TypeError: cancelled',
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            'Request failed with status code 422',
            'Request failed with status code 419',
            'Request failed with status code 404',
            'Request failed with status code 403',
            'Request failed with status code 401',
            'Request aborted',
            'Network Error',
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local',
    })
    Sentry.setUser({ id: window.user?.id, email: window.user?.email })
}

<script lang="ts">
interface Props {
    label?: string
    modelValue?: string
    id?: string
    name?: string
    error?: string
    countryIsoCode?: LimitedCountryCode
}
</script>

<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'

import { mapsLoader } from '@/hooks/use-google-maps'
import { Address } from '@/types/form'
import { MyInputRef } from '@/types/inputs'
import { LimitedCountryCode } from '@/utils/country-codes'

import MyInput from '@/components/my-components/form/MyInput.vue'

interface AddressObject {
    street_number: string
    route: string
    locality: string
    administrative_area_level_1: string
    administrative_area_level_2: string
    administrative_area_level_3: string
    neighborhood: string
    postal_town: string
    country: string
    postal_code: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
    (e: 'change', event: Address): void
}>()

let autocomplete: google.maps.places.Autocomplete
const inputRef = ref<MyInputRef>()
const input = ref(props.modelValue || '')

function getAddressObject(place: google.maps.places.PlaceResult): Address {
    const addressNameFormat = {
        street_number: 'short_name',
        route: 'long_name',
        country: 'long_name',
        postal_code: 'short_name',

        // Different City Properties
        locality: 'long_name',
        neighborhood: 'long_name',
        postal_town: 'short_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        administrative_area_level_3: 'short_name',
    }
    const address: Partial<AddressObject> = {}

    for (const component of place.address_components!) {
        const type = component.types[0] as keyof AddressObject
        if (Object.keys(addressNameFormat).includes(type)) {
            address[type] = component[
                addressNameFormat[type] as keyof google.maps.GeocoderAddressComponent
            ] as string
        }
    }

    return {
        address: [address.route, address.street_number].filter(Boolean).join(' '),
        zipcode: address.postal_code!,
        city:
            address.locality ||
            address.administrative_area_level_3 ||
            address.postal_town ||
            address.administrative_area_level_2 ||
            address.neighborhood!,
        country: address.country!,
        latitude: place.geometry!.location!.lat(),
        longitude: place.geometry!.location!.lng(),
    }
}

// Prevent submit when user is selecting an address
// and closing modals when clicking escape while autocomplete is open
function handleModifierKey(e: KeyboardEvent) {
    const activeContainers = Array.from(document.getElementsByClassName('pac-container')).filter(
        (element) => (element as HTMLElement).style.display !== 'none',
    )
    if (activeContainers.length > 0) {
        e.preventDefault()
        e.stopPropagation()
    }
}

watch(inputRef, async () => {
    await mapsLoader.load()

    autocomplete = new google.maps.places.Autocomplete(inputRef.value!.input, {
        fields: ['address_components', 'geometry', 'formatted_address'],
        types: ['address'],
        componentRestrictions: {
            country: props.countryIsoCode ? props.countryIsoCode.toLowerCase() : [],
        },
    })
    autocomplete.addListener('place_changed', () => {
        const place = autocomplete!.getPlace()
        if (!place.geometry) {
            return
        }
        emit('change', getAddressObject(place))
    })
})
watch(input, () => {
    if (input.value != props.modelValue) emit('update:modelValue', input.value)
})
watch(
    () => props.modelValue,
    () => {
        if (input.value != props.modelValue) {
            input.value = props.modelValue || ''
        }
    },
    { deep: true },
)

// We'll start loading right away
onBeforeMount(() => mapsLoader.load())
onBeforeUnmount(() => {
    autocomplete?.unbindAll()
})
</script>

<template>
    <MyInput
        :id="props.id"
        ref="inputRef"
        v-model="input"
        :label="props.label"
        :name="props.name"
        :error="props.error"
        @keydown.enter="handleModifierKey"
        @keydown.esc="handleModifierKey"
    />
</template>

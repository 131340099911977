import type { IncludedCompany, Location, LocationPoint, MinimalCompany } from '@/types/company'
import type { Employee } from '@/types/user'

import { Model, Dispatch, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'

export type TransactionFilter = 'all' | 'inTransit' | 'confirmed' | 'declined'

export enum TransactionImageType {
    Received,
    Delivered,
}

export enum TransactionStep {
    Company = 'company',
    Products = 'products',
    Details = 'details',
    Confirm = 'confirm',
}

export enum LocationType {
    Default,
    Warehouse,
}

export enum ProductType {
    Received,
    Delivered,
}

export enum TransactionState {
    NotConfirmed,
    Confirmed,
    Declined,
    Draft,
    IncomingDraft,
}

export enum ShipmentStatus {
    Completed,
    Awaiting,
    Mismatch,
}

export enum TransactionType {
    Default,
    Reset,
    PalletBank,
    Backend,
    Location,
    Load,
    Unload,
    StockAdjustment,
    Transit,
}

export interface TransactionImage {
    id?: string
    type: TransactionImageType
    file?: File
    path?: string
    note: string
}

export interface ConsignmentNote {
    id?: string
    path?: string
    name?: string
    isImage?: boolean
    file?: File
}

export interface TransactionDetails {
    id: string | null
    receiverSignature: string | null
    receiverName: string | null
    note: string | null
    trailerNumber: string | null
    truckNumber: string | null
    palletReceiptNumber: string | null
    shippingCode: string | null
}

export interface TransactionProduct {
    id: string
    image: string
    name: string
    received: number
    delivered: number
    deliveredFilled: boolean
    receivedFilled: boolean
}

interface BaseTransaction extends Model {
    id?: uuid
    type: TransactionType
    state: TransactionState
    shipmentStatus: ShipmentStatus
    shipperLocation?: Location

    confirmedAt?: string
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
    transpiredAt?: string

    details: TransactionDetails | null
    companyDetails: { economy: string | null }
    images: TransactionImage[]
}

export interface Transaction extends BaseTransaction {
    id: uuid
    location: LocationPoint

    sendingUser: MinimalUser
    sendingLocation: Location
    sendingCompany: IncludedCompany

    receivingUser?: MinimalUser
    receivingLocation: Location
    receivingCompany: IncludedCompany

    createdByUser?: MinimalUser

    shipperLocation?: Location
    shipperCompany?: IncludedCompany

    haulierLocation?: Location
    haulierCompany?: IncludedCompany

    destinationLocation?: Location
    destinationCompany?: IncludedCompany

    transitTransaction?: Transaction
    destinationTransaction?: Transaction

    deletingUser?: MinimalUser

    details: TransactionDetails
    products: TransactionProduct[]
    consignmentNotes: ConsignmentNote[]
    podReceiptId: uuid | null

    revisions: { id: uuid; createdAt: string; createdByUser: MinimalUser }[]
    dispatches: Dispatch[]
}

export interface MinimalTransaction extends BaseTransaction {
    id: string
    location: LocationPoint

    sendingUser: Employee
    sendingLocation: Location
    sendingCompany: Omit<MinimalCompany, 'vat'>

    receivingUser?: Employee
    receivingLocation: Location
    receivingCompany: { id: uuid; name: string }

    haulierCompany: { id: uuid; name: string }

    destinationLocation?: Location

    revisionTransactionId: string
    originalTransactionId: string

    products: TransactionProduct[]

    productsTotal: number
    productsReceived: number
    productsDelivered: number

    imageCount: number
    consignmentNoteCount: number

    createdAt: string
    updatedAt: string
    transpiredAt: string

    podReceiptId: uuid | null
}

export interface ManageTransaction extends BaseTransaction {
    sendingLocationId: string | null

    receivingLocationId: string | null
    receivingCompanyId: string | null
    receivingUserId: string | null

    haulierCompanyId: string | null
    haulierLocationId: string | null
    shipperLocationId: string | null
    destinationLocationId: string | null

    location?: LocationPoint | null

    products: TransactionProduct[]
    consignmentNotes: ConsignmentNote[]
}

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { datetime, localeTime } from '@/utils/dates'
import { IncludedCompany, LicenseType, Location } from '@/types/company'
import { uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import {
    ShipmentType,
    EventType,
    DeviationState,
    IncludedTicket,
    IncludedDeviation,
    MinimalShipment,
    TourEvent,
} from '@/types/delivery-management'
import { DropdownOption } from '@/types/inputs'
import { image } from '@/utils/assets'
import { addressString } from '@/utils/string'

import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import ShipmentModal from '@/components/delivery-management/ShipmentModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    tourEvents: TourEvent[]
    tickets?: IncludedTicket[]
    deviations?: IncludedDeviation[]
    isShipperCompany?: boolean
    isHubHaulier?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isShipperCompany: false,
    isHubHauler: false,
    deviations: () => [],
    tickets: () => [],
})

const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const shipmentModalOpen = ref(false)
const selectedShipmentId = ref<string>()

const shipmentTypes = computed<DropdownOption[]>(() => {
    return [
        { label: t('delivery'), value: ShipmentType.Delivery.toString() },
        { label: t('return'), value: ShipmentType.Return.toString() },
        { label: t('pickup'), value: ShipmentType.Pickup.toString() },
    ]
})

const isReturnOrPickup = (event: TourEvent) => {
    if (!event.shipment) return false
    if (event.shipment.type === ShipmentType.Pickup) return true
    if (event.shipment.type === ShipmentType.Return) return true
}

function getShipmentCompany(shipment: MinimalShipment): IncludedCompany {
    if (shipment.type === ShipmentType.Pickup) return shipment.sendingCompany
    return shipment.receivingCompany
}

function getShipmentLocation(shipment: MinimalShipment): Location {
    if (shipment.type === ShipmentType.Pickup) return shipment.sendingLocation
    return shipment.receivingLocation
}

const ticketsByShipment = computed(() => {
    if (props.isHubHaulier) return {}
    const result: Record<string, IncludedTicket[]> = {}
    for (const ticket of props.tickets ?? []) {
        if (!ticket.shipmentId) continue
        result[ticket.shipmentId!] ??= []
        result[ticket.shipmentId].push(ticket)
    }
    return result
})

const deviationsByShipment = computed(() => {
    if (props.isHubHaulier) return {}
    const result: Record<string, IncludedDeviation[]> = {}
    for (const deviation of props.deviations ?? []) {
        if (!deviation.shipmentId) continue
        result[deviation.shipmentId!] ??= []
        result[deviation.shipmentId].push(deviation)
    }
    return result
})

const shipmentContainsShipmentInformation = (shipment: MinimalShipment) => {
    if (shipment.shipmentNumber || shipment.references || shipment.name) return true
}

function shipmentDeviations(shipmentId: uuid) {
    return deviationsByShipment.value[shipmentId] ?? []
}

function shipmentTickets(shipmentId: uuid) {
    return ticketsByShipment.value[shipmentId] ?? []
}

function showShipment(shipmentId: string) {
    selectedShipmentId.value = shipmentId
    shipmentModalOpen.value = true
}
</script>

<template>
    <table class="mx-auto max-w-[1200px]">
        <tr v-for="(event, index) in tourEvents" :key="event.id">
            <td class="relative whitespace-nowrap">
                <div class="flex h-full justify-end">
                    <div class="mr-10 flex flex-col justify-start">
                        <div class="flex flex-col">
                            <span class="text-right mr-3.5 font-semibold">{{ index + 1 }}</span>

                            <mdi:package-down
                                v-if="event.type === EventType.Shipment && !isReturnOrPickup(event)"
                                class="h-10 w-10 self-end text-primary-300"
                            />
                            <mdi:package-up
                                v-if="event.type === EventType.Shipment && isReturnOrPickup(event)"
                                class="h-10 w-10 self-end text-primary-300"
                            />
                            <mdi:ferry
                                v-if="event.type === EventType.Ferry"
                                class="h-10 w-10 self-end text-primary-300"
                            />
                            <mdi:bridge
                                v-if="event.type === EventType.Bridge"
                                class="h-10 w-10 self-end text-primary-300"
                            />
                        </div>

                        <div class="flex flex-col justify-end text-right">
                            <div
                                v-if="event.shipment && event.type === EventType.Shipment"
                                class="flex space-x-1 justify-end"
                            >
                                <span
                                    v-if="event.shipment.unsuccessful"
                                    class="text-sm font-semibold"
                                    v-text="t('unsuccessful')"
                                />
                                <span class="text-sm font-semibold">
                                    {{ shipmentTypes[event.shipment?.type].label }}
                                </span>
                            </div>

                            <span v-if="event.handledAt"> {{ datetime(event.handledAt) }}</span>
                            <span
                                v-if="
                                    !event.handledAt &&
                                    !event.estimatedAt &&
                                    !event.shipment?.plannedAt
                                "
                            >
                                {{ t('notDeliveredYet') }}
                            </span>
                            <div
                                v-if="
                                    (event.estimatedAt || event.shipment?.plannedAt) &&
                                    !event.handledAt
                                "
                                class="flex items-center justify-end"
                            >
                                <span class="mr-2 text-sm font-semibold text-blue-400">ETA</span>
                                <span
                                    v-if="event.estimatedAt"
                                    v-text="datetime(event.estimatedAt)"
                                />
                                <span
                                    v-else-if="event.shipment?.plannedAt"
                                    v-text="datetime(event.shipment?.plannedAt)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="absolute top-0 mr-2 flex h-full flex-col items-center">
                        <div
                            v-if="event.shipment?.unsuccessful"
                            class="h-6 w-6 flex-shrink-0 rounded-full bg-red-400"
                        />

                        <div
                            v-else-if="
                                event.shipment &&
                                (deviationsByShipment[event.shipment.id] ||
                                    ticketsByShipment[event.shipment.id])
                            "
                            class="h-6 w-6 flex-shrink-0 rounded-full"
                            :class="{
                                'border-[3px] border-dashed border-yellow-400': !event.handledAt,
                                'bg-yellow-400': event.handledAt,
                            }"
                        />

                        <div
                            v-else
                            class="h-6 w-6 flex-shrink-0 rounded-full"
                            :class="{
                                'border-[3px] border-dashed border-primary-400': !event.handledAt,
                                'bg-primary-400': event.handledAt,
                            }"
                        />

                        <div class="my-2 h-full w-0.5 bg-gray-500" />
                    </div>
                </div>
            </td>
            <td
                :data-id="'eventEntry-' + event.id"
                class="w-full rounded-xl p-0 transition-shadow duration-1000 ease-in-out scroll-mt-5"
            >
                <MyPanel bordered panel-class="mt-4" class="grid grid-cols-11 gap-2">
                    <div v-if="event.shipment" class="col-span-4 col-end-5 ml-6">
                        <h3
                            class="relative text-left pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                        >
                            {{ t('company') }}
                        </h3>
                        <div class="flex flex-col justify-center py-2">
                            <div class="grid grid-cols-[100px_1fr]">
                                <span
                                    class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                                    v-text="t('company')"
                                />
                                <div>
                                    <RouterLink
                                        v-if="event.shipment.receivingCustomerId"
                                        :to="{
                                            name: 'dm.customers.show',
                                            params: {
                                                id: event.shipment.receivingCustomerId as string,
                                            },
                                        }"
                                    >
                                        <span
                                            class="font-semibold"
                                            v-text="getShipmentCompany(event.shipment).name"
                                        />
                                    </RouterLink>
                                    <span v-else>
                                        {{ getShipmentCompany(event.shipment).name }}
                                    </span>
                                    <span
                                        v-if="event.shipment.soldTo"
                                        v-text="' - ' + event.shipment.soldTo"
                                    />
                                </div>
                            </div>

                            <div class="grid grid-cols-[100px_1fr]">
                                <span
                                    class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                                    v-text="t('location')"
                                />

                                <div>
                                    <span>
                                        {{ getShipmentLocation(event.shipment).name }}
                                        <template
                                            v-if="
                                                authStore.hasLicense(
                                                    LicenseType.AgentLocationFiltering,
                                                ) && getShipmentLocation(event.shipment).agentNumber
                                            "
                                        >
                                            ({{ getShipmentLocation(event.shipment).agentNumber }})
                                        </template>
                                    </span>
                                    <span
                                        v-if="event.shipment.shipTo"
                                        v-text="'- ' + event.shipment.shipTo"
                                    />
                                </div>

                                <span
                                    class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                                    v-text="t('address')"
                                />
                                <span
                                    v-text="
                                        event.shipment.address ??
                                        addressString(getShipmentLocation(event.shipment))
                                    "
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="event.shipment && shipmentContainsShipmentInformation(event.shipment)"
                        class="col-span-3 col-start-5 col-end-8"
                    >
                        <h3
                            class="relative pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                        >
                            {{ t('shipment') }}
                        </h3>
                        <div class="flex flex-col justify-center py-2">
                            <div
                                v-if="
                                    event.shipment.handleableAfter &&
                                    event.shipment.handleableBefore
                                "
                                class="flex space-x-3"
                            >
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('timeslot') }}
                                </span>
                                <div>
                                    <span
                                        class="text-md font-semibold dark:text-primary-100"
                                        v-text="
                                            localeTime(event.shipment.handleableAfter) ??
                                            t('notSpecified')
                                        "
                                    />
                                    <span class="font-semibold mx-1">-</span>
                                    <span
                                        class="text-md font-semibold dark:text-primary-100"
                                        v-text="
                                            localeTime(event.shipment.handleableBefore) ??
                                            t('notSpecified')
                                        "
                                    />
                                </div>
                            </div>
                            <div v-if="event.shipment.category" class="flex space-x-3">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('category') }}
                                </span>
                                <span>
                                    {{ event.shipment?.category.name ?? t('notSpecified') }}
                                </span>
                            </div>
                            <div
                                v-if="event.shipment.shipmentNumber"
                                class="flex flex-col space-y-1"
                            >
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('shipmentNumber') + ':' }}
                                </span>
                                <span>
                                    {{ event.shipment?.shipmentNumber ?? t('notSpecified') }}
                                </span>
                            </div>

                            <div v-if="event.shipment.name" class="flex space-x-3">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('name') }}
                                </span>
                                <span>
                                    {{ event.shipment.name ?? t('notSpecified') }}
                                </span>
                            </div>

                            <div v-if="event.shipment.references" class="flex flex-col space-y-1">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('references') + ':' }}
                                </span>
                                <span>
                                    {{ event.shipment.references ?? t('notSpecified') }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-if="event.shipment?.handledAt" class="col-span-3 col-start-8 col-end-11">
                        <h3
                            class="relative pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                        >
                            {{ t('deliveryInformation') }}
                        </h3>
                        <div class="flex flex-col justify-center py-2">
                            <div class="flex space-x-4">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('receiverName') }}
                                </span>
                                <span>
                                    {{ event.shipment?.receiverName ?? t('notSpecified') }}
                                </span>
                            </div>

                            <div v-if="event.shipment?.receiverSignature" class="flex space-x-3">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('receiverSignature') }}
                                </span>
                                <img
                                    class="mt-2 w-16 rounded-full"
                                    :src="image(event?.shipment.receiverSignature)"
                                />
                            </div>

                            <div class="flex flex-col space-y-1">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                >
                                    {{ t('deliveryNote') + ':' }}
                                </span>
                                <span>
                                    {{ event.shipment?.deliveryNote ?? t('notSpecified') }}
                                </span>
                            </div>
                            <div v-if="event.shipment.images.length > 0">
                                <span
                                    class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                                    v-text="t('images')"
                                />
                                <MyImageViewer class="grid grid-cols-3 gap-3 mt-2">
                                    <MyFileAttachment
                                        v-for="file in event.shipment.images"
                                        :key="file.id"
                                        class="cursor-pointer !min-h-[5rem] !max-h-[5rem]"
                                        :file="file"
                                    />
                                </MyImageViewer>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="event.shipment && !props.isHubHaulier"
                        class="flex flex-col justify-center col-span-1 col-start-11 space-y-2 p-4"
                    >
                        <MyButton
                            v-if="event.shipment"
                            v-tooltip="t('showShipment')"
                            class="self-center"
                            icon
                            plain
                            scheme="primary"
                            size="small"
                            @click="showShipment(event.shipment.id)"
                        >
                            <mdi:eye />
                        </MyButton>

                        <div
                            v-if="props.isShipperCompany"
                            class="flex flex-col self-center space-y-2"
                        >
                            <MyButton
                                v-for="deviation in shipmentDeviations(event.shipment.id)"
                                :key="deviation.id"
                                v-tooltip="t('showDeviation')"
                                class="relative"
                                icon
                                plain
                                scheme="primary"
                                size="small"
                                @click="
                                    router.push({
                                        name: 'dm.deviations.show',
                                        params: { deviationId: deviation.id },
                                    })
                                "
                            >
                                <mdi:alert-outline />
                                <span
                                    v-if="deviation.state === DeviationState.Closed"
                                    class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-gray-400"
                                />
                                <span
                                    v-if="deviation.state === DeviationState.Open"
                                    class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-yellow-500"
                                />

                                <span
                                    v-if="deviation.state === DeviationState.Solved"
                                    class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-green-500"
                                />
                            </MyButton>
                        </div>

                        <div v-else class="flex flex-col self-center space-y-2">
                            <MyButton
                                v-for="ticket in shipmentTickets(event.shipment.id)"
                                :key="ticket.id"
                                v-tooltip="t('showTicket')"
                                class="relative"
                                icon
                                plain
                                scheme="primary"
                                size="small"
                                @click="
                                    router.push({
                                        name: 'dm.tickets.show',
                                        params: { ticketId: ticket.id },
                                    })
                                "
                            >
                                <mdi:alert-outline />
                                <span
                                    v-if="ticket.state === DeviationState.Closed"
                                    class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-gray-400"
                                />
                                <span
                                    v-if="ticket.state === DeviationState.Open"
                                    class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-yellow-500"
                                />

                                <span
                                    v-if="ticket.state === DeviationState.Solved"
                                    class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-green-500"
                                />
                            </MyButton>
                        </div>

                        <hr v-if="event.shipment?.palletExchange" class="my-1" />

                        <div v-if="event.shipment?.palletExchange" class="self-center">
                            <mdi:swap-horizontal
                                v-tooltip="t('palletExchange')"
                                class="h-6 w-6 text-green-400"
                            />
                        </div>
                    </div>
                </MyPanel>
            </td>
        </tr>
    </table>

    <ShipmentModal
        v-if="selectedShipmentId"
        :id="selectedShipmentId"
        v-model:open="shipmentModalOpen"
        @update:open="selectedShipmentId = undefined"
    />
</template>
